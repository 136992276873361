<template>
  <form
    @submit.prevent="submitTaskForm"
    :id="formId"
    class="grid grid-cols-1 gap-x-4 gap-y-8 sm:col-span-6 sm:grid-cols-6"
  >
    <select-dropdown
      :wideItem="true"
      inputTitle="Category"
      v-model="currentTask.category"
      :selectOptions="taskTypes"
      :noSort="true"
      :required="true"
      :display-only="prepopulatedCategory != null"
      :display-only-value="prepopulatedCategory"
      @on-blur="setCategory"
    />

    <text-area
      inputTitle="Instructions"
      v-model="currentTask.instructions"
      :wide-item="true"
    />

    <div
      class="grid grid-cols-6 sm:col-span-6"
      v-if="currentTask.details?.category"
    >
      <record-form-layout
        @update:record="this.currentTask.details = $event"
        :recordProp="this.currentTask.details"
        :displayOnly="false"
        :recordType="this.currentTask.details.category"
        :is-planned="true"
      />
    </div>
    <div class="sm:col-span-3">
      <date-input
        inputTitle="Complete By"
        v-model="currentTask.deadline"
        :required="true"
        :dateOnly="true"
      />
    </div>
    <div class="mt-8 sm:col-span-3">
      <submit-button buttonText="Save" :formId="formId" />
      <base-button
        buttonText="Cancel"
        :redButton="true"
        @buttonClick="cancelSave"
      />
    </div>
  </form>
</template>

<script>
import SelectDropdown from "@/components/form/SelectDropdown.vue";
import DateInput from "@/components/form/DateInput.vue";
import TextArea from "@/components/form/TextArea.vue";
// import SelectSearch from "@/components/form/SelectSearch.vue";
import BaseButton from "@/components/buttons/BaseButton.vue";
import SubmitButton from "@/components/buttons/SubmitButton.vue";
const RecordTypeSchemas = require("@/layouts/grower-portal/records/RecordTypes.json");
import RecordFormLayout from "@/layouts/grower-portal/records/RecordFormLayout.vue";

export default {
  components: {
    SelectDropdown,
    DateInput,
    TextArea,
    // SelectSearch,
    BaseButton,
    SubmitButton,
    RecordFormLayout,
  },
  props: {
    taskId: {
      type: Number,
      default: null,
    },
    fieldId: {
      type: Number,
      required: true,
    },
    prepopulatedStatus: {
      type: String,
      default: "Ready",
    },
    prepopulatedCategory: {
      type: String,
      default: null,
    },
  },
  events: ["closeModal"],
  data() {
    const statusOptions = [
      "Recommended",
      "Requested",
      "Ready",
      "Assigned",
      "InProcess",
      "Complete",
      "Canceled",
    ];
    // let taskTypes = [
    //   "Planting",
    //   "Tilling",
    //   "Irrigation",
    //   "Input Application",
    //   "Fertilzer Application",
    //   "Manual / Hand labor",
    //   "Hauling",
    //   "Scouting",
    //   "Observation",
    //   "Other field task",
    //   "Harvesting",
    //   //   // equipment tasks
    //   //   "Maintenance",
    //   //   "Repair",
    //   //   "Other equipment task",
    // ];
    let basicTaskTypes = [
      // { label: "Planting", value: "Planting" },
      // { label: "Tilling", value: "Tilling" },
      // { label: "Irrigation", value: "Irrigation" },
      // { label: "Input Application", value: "Input Application" },
      // { label: "Fertilzer Application", value: "Fertilzer Application" },
      { label: "Manual / Hand labor", value: "Manual / Hand labor" },
      { label: "Hauling", value: "Hauling" },
      { label: "Scouting", value: "Scouting" },
      // { label: "Observation", value: "Observation" },
      { label: "Other field task", value: "Other field task" },
      // { label: "Harvesting", value: "Harvesting" },
      // { label: "Maintenance", value: "Maintenance" },
      { label: "Equipment Repair", value: "Equipment Repair" },
      { label: "Other equipment task", value: "Other equipment task" },
    ];
    return {
      statusOptions,
      basicTaskTypes,
      currentTask: {},
      allFields: this.$store.state.fields.fields,
      allUsers: this.$store.state.users.users,
      allEquipment: this.$store.state.equipment.equipment,
      formId: "newFieldForm",
    };
  },
  computed: {
    taskTypes() {
      console.log("computing taskTypes");
      let recordTypeSchemas = RecordTypeSchemas.filter(
        (recordTypeSchema) => !recordTypeSchema.readonly,
      );
      let filteredRecordTypeSchemas = recordTypeSchemas.filter(
        (recordTypeSchema) => recordTypeSchema.activity === "true",
      );
      recordTypeSchemas = filteredRecordTypeSchemas.map((recordTypeSchema) => {
        return {
          label: recordTypeSchema.category,
          value: recordTypeSchema.category,
        };
      });
      recordTypeSchemas.sort((a, b) => a.label.localeCompare(b.label));

      return recordTypeSchemas.concat(this.basicTaskTypes).sort((a, b) => {
        return a.label.localeCompare(b.label);
      });
    },
    fieldList() {
      console.log("fieldList");
      return this.allFields.map((field) => {
        return { label: field.properties.name, value: field.id };
      });
    },
    equipmentList() {
      console.log("equipmentList");
      return this.allEquipment.map((asset) => {
        return { label: asset.alias, value: asset.id };
      });
    },
    allPartners() {
      console.log("allPartners");
      let partners = [];
      this.$store.state.orgs.orgs.forEach((partner) => {
        if (partner.feature_flags.find((ff) => ff === "vp-work-orders")) {
          partners.push(partner);
        }
      });
      return partners;
    },
    assignedUserId() {
      console.log("assignedUserId");
      return this.currentTask.user;
    },
  },
  methods: {
    setCategory() {
      console.log("setCategory");
      if (this.currentTask.details == null) {
        this.currentTask.details = {};
      }
      if (
        // task only, no associated record type
        this.currentTask.category == "Manual / Hand labor" ||
        this.currentTask.category == "Hauling" ||
        this.currentTask.category == "Scouting" ||
        this.currentTask.category == "Other field task" ||
        this.currentTask.category == "Equipment Repair" ||
        this.currentTask.category == "Other equipment task"
      ) {
        this.currentTask.details.category == "";
      } else {
        this.currentTask.details.category = this.currentTask.category;
      }
    },
    submitTaskForm() {
      if (this.taskId) {
        this.updateTask();
      } else {
        this.createTask();
      }
      this.$emit("closeModal");
    },
    cancelSave() {
      this.$emit("closeModal");
    },
    // Form Methods
    readTask() {
      this.currentTask = this.$store.getters.getTaskById(this.taskId);
      this.currentTask.deadline = this.currentTask.deadline?.substring(0, 10);
      // check if it is assigned to a partner and then set the user to the partner org id
      if (this.currentTask.assigned_org) {
        this.currentTask.user = "p" + this.currentTask.assigned_org;
      }
    },
    createTask() {
      this.currentTask.source = this.$store.getters.getCurrentUser.id;
      this.currentTask.status = "Ready";
      this.currentTask.field = this.fieldId;
      this.currentTask.deadline = this.currentTask.deadline + "T12:00:0.000Z";
      console.log("deadline", this.currentTask.deadline);
      if (this.currentTask.user === -1) {
        this.currentTask.user = null;
      }
      if (this.currentTask.user && this.currentTask.user[0] === "p") {
        this.currentTask.assigned_org = this.currentTask.user.substring(1);
        this.currentTask.user = null;
        this.$store.dispatch("createSharedTask", this.currentTask);
      } else {
        this.$store.dispatch("createTask", this.currentTask);
      }
    },
    updateTask() {
      this.currentTask.deadline = this.currentTask.deadline + "T12:00:0.000Z";
      console.log("deadline", this.currentTask.deadline);
      if (this.currentTask.user === -1) {
        this.currentTask.user = null;
      }
      if (this.currentTask.user && this.currentTask.user[0] === "p") {
        this.currentTask.assigned_org = parseInt(
          this.currentTask.user.substring(1),
        );
        // console.log("assigned org", this.currentTask.assigned_org);
        this.currentTask.user = null;
        this.$store.dispatch("updateSharedTask", {
          taskId: this.taskId,
          task: this.currentTask,
        });
      } else {
        this.currentTask.assigned_org = null;
        this.$store.dispatch("updateTask", {
          taskId: this.taskId,
          task: this.currentTask,
        });
      }
    },
    clearForm() {
      console.log("clearForm");
      this.currentTask = {
        category: "", // string
        instructions: "", // string
        status: null, // string - enum
        user: null, // int
        source: null, // int
        field: null, // int
        deadline: null, // string - datetime
      };
      this.currentTask.status = this.prepopulatedStatus;
      if (this.prepopulatedCategory) {
        this.currentTask.category = this.prepopulatedCategory;
        this.setCategory();
      }
    },
  },
  watch: {
    prepopulatedCategory: {
      handler(value) {
        console.log("the prepopulatedCategory param changed to ", value);
        if (value) {
          this.currentTask.category = value;
          this.setCategory();
        }
      },
      immediate: true,
    },
    taskId: {
      // handler(value) or handler(newValue, oldValue)
      handler(value) {
        console.log("the fieldId param changed to ", value); //, " from ", oldValue);
        if (value) {
          this.readTask();
        } else {
          this.clearForm();
        }
      },
      // this will cause the handler to be run immediately on component creation
      immediate: true,
    },
    assignedUserId: {
      handler(value) {
        console.log("assignedUserId changed", value);
        if (
          (value != null && this.currentTask.status == "Ready") ||
          this.currentTask.status == "Recommended" ||
          this.currentTask.status == "Requested" ||
          this.currentTask.status == "Assigned"
        ) {
          if (value && value[0] == "p") {
            this.currentTask.status = "Requested";
          } else {
            this.currentTask.status = "Assigned";
          }
        }
      },
    },
  },
};
</script>
