<template>
  <core-inputs
    :scenario-initial="scenario"
    :readOnly="readOnly"
    @updateScenarioInputs="updateScenarioInputs"
  />

  <energy-inputs
    :scenario-initial="scenario"
    :readOnly="readOnly"
    @updateScenarioInputs="updateScenarioInputs"
  />

  <fertilizer-inputs
    :scenario-initial="scenario"
    :readOnly="readOnly"
    @updateScenarioInputs="updateScenarioInputs"
  />
  <cover-crop-inputs
    :scenario-initial="scenario"
    :readOnly="readOnly"
    @updateScenarioInputs="updateScenarioInputs"
  />
  <manure-inputs
    :scenario-initial="scenario"
    :readOnly="readOnly"
    @updateScenarioInputs="updateScenarioInputs"
  />
  <other-inputs
    :scenario-initial="scenario"
    :readOnly="readOnly"
    @updateScenarioInputs="updateScenarioInputs"
  />
</template>

<script>
import CoreInputs from "@/layouts/common/carbon/scenarioSectionInputs/CoreInputs.vue";
import EnergyInputs from "@/layouts/common/carbon/scenarioSectionInputs/EnergyInputs.vue";
import FertilizerInputs from "@/layouts/common/carbon/scenarioSectionInputs/FertilizerInputs.vue";
import CoverCropInputs from "@/layouts/common/carbon/scenarioSectionInputs/CoverCropInputs.vue";
import ManureInputs from "@/layouts/common/carbon/scenarioSectionInputs/ManureInputs.vue";
import OtherInputs from "@/layouts/common/carbon/scenarioSectionInputs/OtherInputs.vue";
// import NumberInput from "@/components/form/NumberInput.vue";
// import SelectDropdown from "@/components/form/SelectDropdown.vue";
// import SelectSearch from "@/components/form/SelectSearch.vue";

export default {
  emits: ["updateScenarioInputs"],
  props: {
    scenarioInitial: {
      type: Object,
      default: null,
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    // TextInput,
    // NumberInput,
    // SelectDropdown,
    // SelectSearch,
    CoreInputs,
    EnergyInputs,
    FertilizerInputs,
    CoverCropInputs,
    ManureInputs,
    OtherInputs,
  },
  data() {
    return {
      currentInputs: this.scenarioInitial?.inputs
        ? this.scenarioInitial.inputs
        : {
            farm_size: null,
            crop_yield: null,
            diesel: null,
            gasoline: null,
            natural_gas: null,
            liquefied_petroleum_gas: null,
            electricity: null,
            ammonia: null,
            urea: null,
            ammonium_nitrate: null,
            ammonium_sulfate: null,
            urea_ammonium_nitrate_solution: null,
            n_monoammonium_phosphate: null,
            n_diammonium_phosphate: null,
            p_monoammonium_phosphate: null,
            p_diammonium_phosphate: null,
            potash_fertilizer: null,
            lime: null,
            herbicide: null,
            insecticide: null,
            source_of_ammonia: "Conventional",
          },
    };
  },
  computed: {
    scenario() {
      return this.scenarioInitial;
    },
  },
  methods: {
    updateScenarioInputs(e) {
      this.currentInputs = e;
      this.$emit("updateScenarioInputs", this.currentInputs);
    },
    resetScenarioInputs() {
      this.currentInputs = {
        farm_size: null,
        crop_yield: null,
        diesel: null,
        gasoline: null,
        natural_gas: null,
        liquefied_petroleum_gas: null,
        electricity: null,
        ammonia: null,
        urea: null,
        ammonium_nitrate: null,
        ammonium_sulfate: null,
        urea_ammonium_nitrate_solution: null,
        n_monoammonium_phosphate: null,
        n_diammonium_phosphate: null,
        p_monoammonium_phosphate: null,
        p_diammonium_phosphate: null,
        potash_fertilizer: null,
        lime: null,
        herbicide: null,
        insecticide: null,
        source_of_ammonia: "Conventional",
      };
    },
    emitUpdateScenarioInputs() {
      // console.log("emittting UpdatedScenarioInputs");
      this.$emit("updateScenarioInputs", this.currentInputs);
    },
  },
  watch: {
    // currentInputs: {
    //   // handler(value) or handler(newValue, oldValue)
    //   handler(value) {
    //     this.$emit("updateScenarioInputs", value);
    //   },
    //   // this will cause the handler to be run immediately on component creation
    //   immediate: true,
    //   deep: true,
    // },
    scenarioInitial: {
      // handler(value) or handler(newValue, oldValue)
      handler(value) {
        this.currentInputs = value.inputs;
      },
      // this will cause the handler to be run immediately on component creation
      immediate: true,
      deep: true,
    },
  },
};
</script>
