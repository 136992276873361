<template>
  <div
    class="mt-2 flex text-left text-lg font-medium uppercase tracking-wider sm:col-span-6"
  >
    Energy Used
    <thumb-up-icon
      class="ml-2 h-6 w-6 text-green-500"
      v-if="totalEmissions < defaultEmissions"
    />
    <thumb-down-icon
      v-if="totalEmissions > defaultEmissions + 10"
      class="ml-2 h-6 w-6 text-red-500"
    />
    <div v-if="totalEmissions && defaultEmissions">
      <span class="ml-2 text-xs normal-case text-gray-500">
        ({{ Math.round(totalEmissions)?.toLocaleString() }} kg CO2e / acre vs
        {{ Math.round(defaultEmissions)?.toLocaleString() }} kg CO2e / acre by a
        typical grower)</span
      >
    </div>
  </div>
  <number-input
    :wideItem="true"
    inputTitle="Diesel (gallons/acre)"
    inputHtmlType="text"
    v-model="currentInputs.diesel"
    :placeholder="scenario.defaults.diesel?.toFixed(2)"
    @blur="emitUpdateScenarioInputs"
    :display-only="readOnly"
    :display-only-value="currentInputs.diesel?.toFixed(2)"
    :nullIfEmpty="true"
  />
  <number-input
    :wideItem="true"
    inputTitle="Electricity (kWh/acre)"
    inputHtmlType="text"
    v-model="currentInputs.electricity"
    :placeholder="scenario.defaults.electricity?.toFixed(2)"
    @blur="emitUpdateScenarioInputs"
    :display-only="readOnly"
    :display-only-value="currentInputs.electricity?.toFixed(2)"
    :nullIfEmpty="true"
  />
  <number-input
    :wideItem="true"
    inputTitle="Gasoline (gallons/acre)"
    inputHtmlType="text"
    v-model="currentInputs.gasoline"
    :placeholder="scenario.defaults.gasoline?.toFixed(2)"
    @blur="emitUpdateScenarioInputs"
    :display-only="readOnly"
    :display-only-value="currentInputs.gasoline?.toFixed(2)"
    :nullIfEmpty="true"
  />
  <number-input
    :wideItem="true"
    inputTitle="Liquefied Petroleum Gas (gallons/acre)"
    inputHtmlType="text"
    v-model="currentInputs.liquefied_petroleum_gas"
    :placeholder="scenario.defaults.liquefied_petroleum_gas?.toFixed(2)"
    @blur="emitUpdateScenarioInputs"
    :display-only="readOnly"
    :display-only-value="currentInputs.liquefied_petroleum_gas?.toFixed(2)"
    :nullIfEmpty="true"
  />
  <number-input
    :wideItem="true"
    inputTitle="Natural Gas (ft3/acre)"
    inputHtmlType="text"
    v-model="currentInputs.natural_gas"
    :placeholder="scenario.defaults.natural_gas?.toFixed(2)"
    @blur="emitUpdateScenarioInputs"
    :display-only="readOnly"
    :display-only-value="currentInputs.natural_gas?.toFixed(2)"
    :nullIfEmpty="true"
  />
</template>
<script>
import NumberInput from "@/components/form/NumberInput";
import { ThumbUpIcon, ThumbDownIcon } from "@heroicons/vue/outline";
import { gramsEmittedByInputPerAcre } from "@/components/composables/convertUnits";
export default {
  components: { NumberInput, ThumbUpIcon, ThumbDownIcon },
  props: {
    scenarioInitial: {
      type: Object,
      required: true,
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["updateScenarioInputs"],
  data() {
    return {
      currentInputs: this.scenarioInitial?.inputs
        ? this.scenarioInitial.inputs
        : {
            diesel: null,
            gasoline: null,
            natural_gas: null,
            liquefied_petroleum_gas: null,
            electricity: null,
          },
    };
  },
  computed: {
    scenario() {
      return this.scenarioInitial;
    },
    totalEmissions() {
      let total = 0;
      let energy = 0;
      this.currentInputs.diesel !== null &&
      this.currentInputs.diesel !== undefined
        ? (energy = this.currentInputs.diesel)
        : (energy = this.scenario.defaults.diesel);
      total += gramsEmittedByInputPerAcre("diesel") * energy;

      energy = 0;
      this.currentInputs.gasoline !== null &&
      this.currentInputs.gasoline !== undefined
        ? (energy = this.currentInputs.gasoline)
        : (energy = this.scenario.defaults.gasoline);
      total += gramsEmittedByInputPerAcre("gasoline") * energy;

      energy = 0;
      this.currentInputs.liquefied_petroleum_gas !== null &&
      this.currentInputs.liquefied_petroleum_gas !== undefined
        ? (energy = this.currentInputs.liquefied_petroleum_gas)
        : (energy = this.scenario.defaults.liquefied_petroleum_gas);
      total += gramsEmittedByInputPerAcre("propane") * energy;

      energy = 0;
      this.currentInputs.natural_gas !== null &&
      this.currentInputs.natural_gas !== undefined
        ? (energy = this.currentInputs.natural_gas)
        : (energy = this.scenario.defaults.natural_gas);
      total += gramsEmittedByInputPerAcre("natural_gas") * energy;

      energy = 0;
      this.currentInputs.electricity !== null &&
      this.currentInputs.electricity !== undefined
        ? (energy = this.currentInputs.electricity)
        : (energy = this.scenario.defaults.electricity);
      total += gramsEmittedByInputPerAcre("electricity") * energy;

      total = total / 1000; // convert grams to kg
      return total;
    },
    defaultEmissions() {
      let total =
        gramsEmittedByInputPerAcre("diesel") * this.scenario.defaults.diesel +
        gramsEmittedByInputPerAcre("gasoline") *
          this.scenario.defaults.gasoline +
        gramsEmittedByInputPerAcre("propane") *
          this.scenario.defaults.liquefied_petroleum_gas +
        gramsEmittedByInputPerAcre("natural_gas") *
          this.scenario.defaults.natural_gas +
        gramsEmittedByInputPerAcre("electricity") *
          this.scenario.defaults.electricity;
      total = total / 1000; // convert grams to kg
      return total;
    },
  },
  methods: {
    emitUpdateScenarioInputs() {
      this.$emit("updateScenarioInputs", this.currentInputs);
    },
  },
  watch: {
    scenarioInitial: {
      // handler(value) or handler(newValue, oldValue)
      handler(value) {
        this.currentInputs = value.inputs;
      },
      // this will cause the handler to be run immediately on component creation
      immediate: true,
      deep: true,
    },
  },
};
</script>
