<template>
  <variable-string-type-input
    :wideItem="true"
    :displayOnly="false"
    inputTitle="Variety"
    inputHtmlType="text"
    v-model="variety"
  />
  <number-input
    :wideItem="true"
    :displayOnly="false"
    inputTitle="Bushels per Acre"
    v-model="bushels"
    :required="true"
  />
  <number-input
    :wideItem="true"
    :displayOnly="false"
    inputTitle="Row Spacing"
    v-model="rowspacing"
  />
  <number-input
    :wideItem="true"
    :displayOnly="false"
    inputTitle="Plants per Acre"
    v-model="plantspacing"
  />
</template>

<script>
import {
  bushelsToTons,
  tonsToBushels,
} from "@/components/composables/convertUnits";
import VariableStringTypeInput from "@/components/form/VariableStringTypeInput.vue";
import NumberInput from "@/components/form/NumberInput.vue";

export default {
  components: {
    VariableStringTypeInput,
    NumberInput,
  },
  emits: ["updateFieldDetails"],
  props: {
    crop: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    let details = this.crop?.details;
    let parent = null;
    if (!details || details == null) {
      details = {};
    }
    return {
      bushels: this.getBushels(details.tpa),
      variety: details.variety,
      planted: details.planted,
      rowspacing: details.rowspacing,
      plantspacing: details.plantspacing,
      parent,
    };
  },
  methods: {
    getBushels(tpa) {
      let bushels = null;
      let tons = tpa;
      if (tons) bushels = tonsToBushels("Corn", tons).toFixed(1);
      return bushels;
    },
  },
  computed: {
    detailsToReturn() {
      return {
        tpa: this.tpa,
        rows: this.rows,
        variety: this.variety,
        planted: this.planted,
        rowspacing: this.rowspacing,
        plantspacing: this.plantspacing,
      };
    },
    tpa() {
      let tons = bushelsToTons("Corn", parseFloat(this.bushels));
      // console.log("tpa", tons);
      return tons;
    },
  },
  watch: {
    crop: {
      handler: function (newVal) {
        if (newVal.details == this.details) return;
        this.details = newVal.details;
        this.bushels = tonsToBushels("Corn", this.details.tpa);
        this.rows = this.details.rows;
        this.variety = this.details.variety;
        this.planted = this.details.planted;
        this.rowspacing = this.details.rowspacing;
        this.plantspacing = this.details.plantspacing;
      },
      deep: true,
    },
    detailsToReturn(value) {
      return this.$emit("updateFieldDetails", value);
    },
  },
};
</script>
