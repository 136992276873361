<template>
  <div>
    <modal
      :show="showRecordModal"
      dialogPanelClass="max-w-screen-lg"
      :title="null"
    >
      <record-form
        @saveRecord="storeRecord"
        @closeRecord="showRecordModal = false"
        @cancelRecord="popRecord"
        :recordProp="activeRecord"
        :displayOnly="displayOnly"
        :recordType="activeRecord.category"
      />
    </modal>
    <table-card>
      <template #header>
        <table-head
          :columns="[
            { name: 'result', label: 'Result', filtered: false },
            { name: 'user.name', label: 'By' },
            { name: 'completed_at', label: 'Completed', filtered: false },
            { name: 'category', label: 'Type' },
            { name: 'actions', label: '', filtered: false, sorted: false },
            { name: 'actions', label: '', filtered: false, sorted: false },
          ]"
          :collection="derivedRecords"
          @update:derived-collection="tableDerivedRecords = $event"
          :defaultSort="defaultSort"
        >
          <template #toolbarButtons>
            <dropdown-button
              buttonText="Add"
              :actions="recordTypes"
              class="flex w-full justify-end"
              @action="addNewRecord"
            />
          </template>
        </table-head>
      </template>

      <tbody>
        <tr v-for="record in tableDerivedRecords" :key="record.rowid">
          <table-data td-label="Result">
            <text-area
              v-model="record.result"
              :displayOnly="record.id != null"
              :display-only-value="record.result"
              dataTestid="record-result"
            />
          </table-data>
          <table-data td-label="By">
            {{ record.user?.name }}
          </table-data>
          <table-data td-label="Completed At">
            {{
              record.completed_at
                ? new Date(record.completed_at).toISOString().substring(0, 10)
                : ""
            }}
          </table-data>
          <table-data td-label="Type">
            {{ record.category }}
          </table-data>
          <table-data class="text-center">
            <button
              @click="showRecord(record.rowid)"
              :disabled="false"
              v-if="record.category != 'Note'"
            >
              View
            </button>
          </table-data>
          <table-data class="text-center">
            <button @click="deleteRecord(record.rowid)" :disabled="false">
              Delete
            </button>
          </table-data>
        </tr>
      </tbody>
    </table-card>
  </div>
</template>

<script>
import TableCard from "@/components/table/TableCard.vue";
import TableHead from "@/components/table/TableHead.vue";
import TableData from "@/components/table/TableData.vue";

import TextArea from "@/components/form/TextArea.vue";
import DropdownButton from "@/components/buttons/DropdownButton.vue";
import RecordForm from "@/layouts/grower-portal/records/RecordForm.vue";
import Modal from "@/components/modals/PopupModal.vue";
const RecordTypeDefintions = require("@/layouts/grower-portal/records/RecordTypes.json");
export default {
  components: {
    TableCard,
    TableHead,
    TableData,
    TextArea,
    DropdownButton,
    RecordForm,
    Modal,
  },
  props: {
    currentFieldId: { type: Number, default: null },
    currentCropId: { type: Number, required: null },
    currentEquipmentId: { type: Number, default: null },
    currentTaskId: { type: Number, default: null },
  },
  data() {
    return {
      newRecords: [],
      nextRecordId: 0,
      activeRecord: null,
      showRecordModal: false,
      displayOnly: false,
      tableDerivedRecords: [],
      defaultSort: { completed_at: "desc" },
    };
  },
  computed: {
    recordTypes() {
      let recordTypes = RecordTypeDefintions.filter(
        (recordType) => !recordType.readonly,
      );
      // console.log("recordTypes", recordTypes);
      recordTypes = recordTypes.map((recordType) => {
        return {
          label: recordType.category,
          event: recordType.category,
        };
      });
      return recordTypes;
    },
    derivedRecords() {
      let derivedRecords = this.$store.getters.getDerivedRecords({});
      if (this.currentCropId) {
        derivedRecords = derivedRecords.filter(
          (record) =>
            record.crop == this.currentCropId ||
            record.field.id == this.currentFieldId,
        );
      } else if (this.currentFieldId) {
        let cropId = this.$store.getters.getFieldById(this.currentFieldId)
          ?.properties?.current_crop?.id;
        // console.log("currentFieldId", this.currentFieldId, derivedRecords);
        derivedRecords = derivedRecords.filter(
          (record) =>
            record.field.id == this.currentFieldId || record.crop == cropId,
        );
      } else if (this.currentEquipmentId) {
        derivedRecords = derivedRecords.filter(
          (record) => record.equipment.id == this.currentEquipmentId,
        );
      } else {
        derivedRecords = derivedRecords.filter(
          (record) => record.task == this.currentTaskId,
        );
        console.log(
          "derivedRecords for task",
          derivedRecords,
          this.currentTaskId,
        );
      }
      derivedRecords = derivedRecords.concat(this.newRecords);
      for (let i = 0; i < derivedRecords.length; i++) {
        derivedRecords[i].rowid = i;
      }
      return derivedRecords;
    },
  },
  methods: {
    updateAcres(value) {
      // console.log("updating acres", value);
      let allocation = this.$store.getters.getWorkingAllocationByCropId(
        value.id,
      );
      allocation.acres = value.acres;
      this.$store.dispatch("updateWorkingAllocation", allocation);
      //this.allocations[this.allocations.findIndex(a => a.crop == value.id)].acres = value.acres
    },
    showRecord(id) {
      this.activeRecord = this.derivedRecords.find(
        (record) => record.rowid == id,
      );
      // console.log("show record", id, this.activeRecord);

      this.displayOnly = true;
      this.showRecordModal = true;
    },
    popRecord() {
      this.newRecords.pop();
      this.showRecordModal = false;
    },
    addNewRecord(category) {
      // console.log("add new record", e);
      let newRecord = {
        result: " ",
        completed_at: new Date(),
        completed_by: this.$store.getters.getCurrentUser.id,
        category: category,
        user: { name: this.$store.getters.getCurrentUser.name },
        newRowId: this.nextRecordId,
      };
      this.newRecords.push(newRecord);
      this.nextRecordId++;
      // console.log("show dialog?", e);
      this.activeRecord = newRecord;

      this.displayOnly = false;
      this.showRecordModal = true;
    },
    deleteRecord(id) {
      let record = this.derivedRecords.find((row) => row.rowid == id);
      if (record.id == null) {
        // pop from newRecords
        let newRowId = record.newRowId;
        //            console.log("deleting record", id, this.newRecords, newRowId);
        this.newRecords = this.newRecords.filter(function (row) {
          return row.newRowId !== newRowId;
        });
      } else {
        //remove from the store
        // console.log("deleting record from store", this.derivedRecords[id]);
        this.$store.dispatch("deleteRecord", this.derivedRecords[id]);
      }
    },
    storeRecord(record) {
      // console.log("saving data from modal", record);
      let recordIdx = this.derivedRecords.findIndex(
        (row) => row.rowid == record.rowid,
      );
      if (recordIdx != -1) {
        this.derivedRecords[recordIdx] = record;
      }
      this.showRecordModal = false;
      this.saveRecords();
    },
    async saveRecords() {
      console.log("saving records", this.derivedRecords, this.newRecords);
      for (let record of this.derivedRecords) {
        let submitRecord = {
          task: this.currentTaskId ? this.currentTaskId : null,
          field:
            this.currentCropId == null
              ? this.currentFieldId
                ? this.currentFieldId
                : null
              : null, // if crop is set, field is null
          crop: this.currentCropId ? this.currentCropId : null,
          equipment: this.currentEquipmentId ? this.currentEquipmentId : null,
          result: record.result,
          category: record.category,
          payload: record.payload,
          completed_at: record.completed_at,
          completed_by: record.completed_by,
        };
        // let response = null;
        // console.log("saving record", submitRecord);
        if (record.newRowId != null) {
          // console.log("saving new record", submitRecord);
          submitRecord = await this.$store.dispatch(
            "createRecord",
            submitRecord,
          );

          if (record.attachment != null) {
            // console.log(
            //   "uploading file",
            //   record.attachment,
            //   submitRecord,
            //   response,
            // );
            await this.$store.dispatch("uploadRecordAttachment", {
              record: submitRecord,
              attachment: record.attachment,
            });
            if (!this.$store.getters.getApiError) {
              console.log("file uploaded");
            }
            // this.submitRecord.payload[this.record?.attachment?.attribute] =
            //   response.file;
            // let id = record.id;
            // this.$store.dispatch("updateRecord", {
            //   recordId: id,
            //   record: submitRecord,
            // });
          }
        }
        this.newRecords = [];
        //  else { // don't update records
        //   // console.log("updating record", submitRecord);
        //   let id = record.id;
        //   this.$store.dispatch("updateRecord", {
        //     recordId: id,
        //     record: submitRecord,
        //   });
        // }
      }
    },
  },
};
</script>

<style></style>
