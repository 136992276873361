<template>
  <div
    class="mt-2 text-left text-lg font-medium uppercase tracking-wider text-gray-500 sm:col-span-6"
  >
    Other
  </div>
  <number-input
    :wideItem="true"
    inputTitle="Herbicide (g/acre)"
    inputHtmlType="text"
    v-model="currentInputs.herbicide"
    :placeholder="scenario.defaults.herbicide?.toFixed(2)"
    @blur="emitUpdateScenarioInputs"
    :display-only="readOnly"
    :display-only-value="currentInputs.herbicide?.toFixed(2)"
    :nullIfEmpty="true"
  />
  <number-input
    :wideItem="true"
    inputTitle="Insecticide (g/acre)"
    inputHtmlType="text"
    v-model="currentInputs.insecticide"
    :placeholder="scenario.defaults.insecticide?.toFixed(2)"
    @blur="emitUpdateScenarioInputs"
    :display-only="readOnly"
    :display-only-value="currentInputs.insecticide?.toFixed(2)"
    :nullIfEmpty="true"
  />
  <number-input
    :wideItem="true"
    inputTitle="Lime (lbs/acre)"
    inputHtmlType="text"
    v-model="currentInputs.lime"
    :placeholder="scenario.defaults.lime?.toFixed(2)"
    @blur="emitUpdateScenarioInputs"
    :display-only="readOnly"
    :display-only-value="currentInputs.lime?.toFixed(2)"
    :nullIfEmpty="true"
  />

  <select-search
    :wideItem="true"
    inputTitle="Tillage"
    :selectOptions="[
      {
        label: 'Conventional tillage',
        value: 'Conventional tillage',
      },
      { label: 'Reduced tillage', value: 'Reduced tillage' },
      { label: 'No till', value: 'No till' },
    ]"
    v-model="currentInputs.tillage"
    :placeholder="scenario.defaults.tillage"
    @on-blur="emitUpdateScenarioInputs"
    :display-only="readOnly"
    :display-only-value="currentInputs.tillage"
  />
</template>
<script>
import NumberInput from "@/components/form/NumberInput";
import SelectSearch from "@/components/form/SelectSearch";
export default {
  components: { NumberInput, SelectSearch },
  props: {
    scenarioInitial: {
      type: Object,
      required: true,
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["updateScenarioInputs"],
  data() {
    return {
      currentInputs: this.scenarioInitial?.inputs
        ? this.scenarioInitial.inputs
        : {
            farm_size: null,
            crop_yield: null,
          },
    };
  },
  computed: {
    scenario() {
      return this.scenarioInitial;
    },
  },
  methods: {
    emitUpdateScenarioInputs() {
      this.$emit("updateScenarioInputs", this.currentInputs);
    },
  },
  watch: {
    scenarioInitial: {
      // handler(value) or handler(newValue, oldValue)
      handler(value) {
        this.currentInputs = value.inputs;
      },
      // this will cause the handler to be run immediately on component creation
      immediate: true,
      deep: true,
    },
  },
};
</script>
