<template>
  <div class="block">
    <div class="w-full">
      <div class="text-xl font-bold">Crop Practice Premiums</div>
      <div class="text-xs text-gray-500">
        Secure more revenue by working with buyers willing to pay a premium.
      </div>

      <div class="w-full text-center">Current Offers</div>
    </div>
    <div class="w-full">
      <ul
        role="list"
        class="grid grid-cols-1 gap-6 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4"
      >
        <li
          v-for="offer in offers"
          :key="offer.id"
          class="col-span-1 flex flex-col divide-y divide-gray-200 rounded-lg bg-white text-center shadow"
        >
          <router-link
            :to="{
              name: 'grower-portal-market-offer-single',
              params: { offerId: offer.id },
            }"
            class="pl-3 font-medium text-violet-500 hover:text-twilight-900 sm:pr-6"
          >
            <div class="flex flex-1 flex-col p-8">
              <h3 class="text-l font-bold text-gray-900">
                {{ offer.contract_name }}
              </h3>
              <img
                class="mx-auto mt-2 h-16 w-16 flex-shrink-0 rounded-sm"
                src="/img/icons/offer.png"
                alt=""
              />
              <h3 class="mt-2 min-h-10 text-sm font-medium text-gray-900">
                Looking to Purchase: {{ offer.quantity }}
                {{ offer.quantity_units }} of {{ offer.crop }}
              </h3>
              <h3 class="mt-2 min-h-10 text-sm font-medium text-gray-900">
                Offering to pay:{{ offer.premiumSummary }}
              </h3>
              <h3
                class="mt-2 text-sm font-bold text-parcelGreen-600"
                v-if="offer.potentialValue > 0"
              >
                You could earn:
                {{
                  offer.potentialValue.toLocaleString("en-US", {
                    style: "currency",
                    currency: "USD",
                  })
                }}
              </h3>
              <div v-else class="min-h-6"></div>

              <!-- pill format-->
              <dl class="mt-1 flex flex-grow flex-col justify-between">
                <dd class="mt-3">
                  <span
                    class="inline-flex items-center rounded-full px-2 py-1 text-xs font-medium ring-1 ring-inset"
                    :class="{
                      'bg-green-100 text-green-800 ring-green-600/20':
                        offer.matchScore >= 3,
                      'text-amber-700ring-amber-600/20 bg-amber-100':
                        offer.matchScore == 2,
                      'bg-parcelOrange-600 text-white ring-parcelOrange-600/20':
                        offer.matchScore == 1,
                    }"
                  >
                    {{ offer.matchScore == 4 ? "prequalified" : "" }}
                    {{ offer.matchScore == 3 ? "< 1 hour to complete" : "" }}
                    {{ offer.matchScore == 2 ? "30 minutes to complete" : "" }}
                    {{ offer.matchScore == 1 ? "~ 1 hour to complete" : "" }}
                  </span>
                </dd>
              </dl>
            </div>
          </router-link>
        </li>
        <!-- <usda-eqip-cta></usda-eqip-cta> -->
      </ul>
    </div>
    <div class="w-full">
      <div class="my-4 w-full text-center">
        click an offer to see details and apply
        <RouterLink
          class="ml-4 text-blue-500"
          :to="{
            name: 'grower-portal-market-offers',
          }"
          >(View All Offers)</RouterLink
        >
      </div>
    </div>
  </div>
</template>

<script>
import { summarizePremium } from "@/components/composables/scriptUtils";
import { tonsToBushels } from "@/components/composables/convertUnits";
// import UsdaEqipCta from "@/layouts/grower-portal/sell/offers/UsdaEqipCta.vue";
export default {
  props: {
    maxOffers: {
      type: Number,
      default: 4,
    },
  },
  components: {
    // UsdaEqipCta,
  },
  data() {
    return {};
  },
  computed: {
    offers() {
      let offers = structuredClone(
        this.$store.getters.getGrowerMarketOffers({}),
      );
      for (let offer of offers) {
        if (offer.premiums?.length == 0) {
          offer.premiumSummary = "None";
        } else {
          offer.premiumSummary = offer.premiums
            .map((p) => {
              return summarizePremium(p);
            })

            .join(", ");
          offer.potentialValue = this.potentialValue(offer);
          offer.matchScore = this.matchScore(offer);
        }
      }
      offers = offers.filter((o) => o.premiums?.length > 0);
      let sortedOffers = offers.sort((a, b) => {
        if (!a.potentialValue) return 1;
        if (!b.potentialValue) return -1;
        if (a.potentialValue > b.potentialValue) return -1;
        else if (a.potentialValue < b.potentialValue) return 1;
        else return 0;
      });
      // console.log("SORTED OFFERS", sortedOffers);
      if (sortedOffers.length > this.maxOffers) {
        offers = sortedOffers.slice(0, this.maxOffers);
      } else {
        offers = sortedOffers;
      }
      return offers;
    },
    crops() {
      return this.$store.getters.getExpandedCrops({});
    },
  },
  methods: {
    potentialValue(offer) {
      // how many acres of this crop exist, that are not allocated
      // if offer is in yield, then we can calculate acres * yield * price
      let applicableCrops = this.crops.filter(
        (crop) => crop.crop_type === offer.crop || offer.crop === "Any Crop",
      );
      let value = 0;
      for (let premium of offer.premiums) {
        if (premium.type === "fixed") {
          // must be fixed to compute a potential value
          // console.log("PREMIUM", premium);
          if (premium.total_or_unit == "unit") {
            if (premium.harvest_units === "acres") {
              let totalAcres = 0;
              for (let crop of applicableCrops) {
                totalAcres += crop.acres;
              }
              if (totalAcres > offer.quantity) {
                totalAcres = offer.quantity;
              }
              value += totalAcres * premium.base_premium;
            } else {
              let totalYield = 0;
              for (let crop of applicableCrops) {
                // assume it is a yield based premium
                if (crop.details?.tpa) {
                  totalYield +=
                    crop.acres *
                    tonsToBushels(crop.crop_type, crop.details.tpa);
                }
                if (totalYield > offer.quantity) {
                  totalYield = offer.quantity;
                }
                value += totalYield * premium.base_premium;
              }
            }
          } else if (premium.total_or_unit == "total") {
            value = premium.value;
          }
          // console.log("VALUE", value);
        }
      }
      // console.log("POTENTIAL VALUE", offer);
      return value;
    },
    matchScore(offer) {
      let matchScore = 0;
      let applicableCrops = this.crops.filter(
        (crop) => crop.crop_type === offer.crop || offer.crop === "Any Crop",
      );
      if (applicableCrops.length > 0) {
        matchScore += 1;
      }
      let hasYield = true;
      for (let crop of applicableCrops) {
        if (!crop.details?.tpa) {
          hasYield = false;
        }
      }
      if (hasYield) {
        matchScore += 1;
      }
      let hasRecords = true;
      for (let crop of applicableCrops) {
        if (!this.$store.getters.getRecordsByCrop(crop.id)?.length > 0) {
          hasRecords = false;
        }
      }
      if (hasRecords) {
        matchScore += 1;
      }
      return matchScore;
    },
    //TODO: this is not working until we can get back how many units are ordered for a given offer
    // orderedQty(offer) {
    //   let orders = this.$store.getters
    //     .getBuyerCropOrdersByMarketOffer(offer.id)
    //     ?.filter((order) => order.status == "In Progress");
    //   let totalQty = 0;
    //   for (let order of orders) {
    //     totalQty += order.quantity;
    //   }
    //   return totalQty;
    // },
  },
};
</script>

<style></style>
