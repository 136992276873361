<template>
  <div class="grid grid-cols-1 gap-x-4 gap-y-8 sm:col-span-6 sm:grid-cols-6">
    <div
      class="mt-2 text-left text-lg font-medium uppercase tracking-wider text-gray-500 sm:col-span-6"
    >
      Contract Sourcing Area
    </div>
    <div class="col-span-6">
      <map-editor
        v-if="localTerritory"
        featureLabel="Territory"
        :initialFeature="{
          type: 'Feature',
          geometry: localTerritory,
          properties: {},
        }"
        @edited="updateShape"
      />
    </div>
  </div>
</template>

<script>
import debounce from "lodash/debounce";
import MapEditor from "@/components/map/MapEditor.vue";

export default {
  props: {
    modelValue: {
      type: Object,
      default: null,
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["update:modelValue"],
  components: {
    MapEditor,
  },
  data() {
    return {
      localTerritory: { ...this.modelValue },
    };
  },
  computed: {},
  methods: {
    updateShape(feature) {
      if (this.readOnly) {
        return;
      }
      this.localTerritory = feature.geometry;
    },
  },
  watch: {
    modelValue: {
      handler(newValue) {
        this.localContract = { ...newValue };
      },
      deep: true,
      immediate: true,
    },
    localTerritory: {
      handler: debounce(function (newValue) {
        this.$emit("update:modelValue", newValue);
      }, 300),
      deep: true,
    },
  },
};
</script>
