<template>
  <page-card headingTitle="New Delivery">
    <template #buttonArea>
      <base-button
        buttonText="Back to Order"
        :marginLeft="false"
        @ButtonClick="$router.push(this.orderPageNavigation(this.currentOrder))"
      />
      <base-button
        buttonText="Save"
        :marginLeft="true"
        @ButtonClick="saveDelivery"
      />
    </template>
    <template #cardDetails>
      <form
        class="grid grid-cols-1 gap-x-4 gap-y-8 sm:col-span-6 sm:grid-cols-6"
      >
        <number-input
          :wideItem="true"
          inputTitle="Crop Order"
          :displayOnly="true"
          :displayOnlyValue="currentDelivery.crop_order"
        />
        <text-input
          :wideItem="true"
          inputTitle="Delivery Location"
          :required="true"
          v-model="currentDelivery.delivery_location"
        />
        <number-input
          :wideItem="true"
          inputTitle="Quantity"
          :required="true"
          v-model="currentDelivery.quantity_delivered"
        />
        <select-dropdown
          :wideItem="true"
          inputTitle="Units"
          :required="true"
          :selectOptions="unitsOptions"
          v-model="currentDelivery.quantity_units"
        />
        <select-search
          :wide-item="true"
          input-title="Field"
          :select-options="fieldCrops"
          v-model="currentDelivery.crop"
        />
      </form>
    </template>
  </page-card>
  <div class="mt-2"></div>
  <table-toolbar>
    <template #toolbarComponents>
      <div></div>
      <FileSelect
        buttonText="Add Attachment"
        @input="uploadAttachment"
        accept="*"
      />
    </template>
  </table-toolbar>
  <div v-if="attachments.length === 0" class="text-center">
    <h3 class="mt-2 text-sm font-semibold text-gray-900">
      There's Nothing Here
    </h3>
    <p class="mt-1 text-sm text-gray-500">No weight tags have been added.</p>
  </div>
  <base-table v-else>
    <template #headerCells>
      <table-header-cell :isLeadingCell="true" headerText="Attachment" />
      <table-header-cell :isEmptyHeader="true" headerText="Delete Attachment" />
    </template>
    <template #bodyRows>
      <tr v-for="attachment in attachments" :key="attachment.name">
        <table-body-cell :cellData="attachment.name" :isLeadingCell="true" />
        <table-body-cell
          :isButtonCell="true"
          buttonDisplayText="Delete"
          @buttonCellClick="removeAttachment(attachment.name)"
        />
      </tr>
    </template>
  </base-table>
</template>

<script>
import PageCard from "@/components/cards/PageCard.vue";
import BaseButton from "@/components/buttons/BaseButton.vue";
import TextInput from "@/components/form/TextInput.vue";
import NumberInput from "@/components/form/NumberInput.vue";
import SelectSearch from "@/components/form/SelectSearch.vue";
import SelectDropdown from "@/components/form/SelectDropdown.vue";
import BaseTable from "@/components/table/BaseTable.vue";
import TableHeaderCell from "@/components/table/TableHeaderCell.vue";
import TableBodyCell from "@/components/table/TableBodyCell.vue";
import TableToolbar from "@/components/table/TableToolbar.vue";
import FileSelect from "@/components/form/FileSelect.vue";

export default {
  components: {
    PageCard,
    BaseButton,
    TextInput,
    NumberInput,
    SelectSearch,
    SelectDropdown,
    BaseTable,
    TableHeaderCell,
    TableBodyCell,
    TableToolbar,
    FileSelect,
  },
  data() {
    return {
      unitsOptions: [
        { label: "Bushels", value: "bu" },
        { label: "Tons", value: "tons" },
        { label: "Pounds", value: "lbs" },
      ],
      currentDelivery: {
        crop_order: this.$route.params.orderId,
        crop: null,
        quantity_units: this.$route.query.units || "bu",
        delivery_location: this.$route.query.deliveryLocation || null,
        attachments: [],
      },
      attachments: [],
    };
  },
  computed: {
    currentOrder() {
      return this.$store.getters.getGrowerCropOrderById(
        this.$route.params.orderId,
      );
    },
    allocationCropIds() {
      let allos = this.$store.getters.getGrowerAllocationsByOrderId(
        this.$route.params.orderId,
      );
      let alloCrops = [];
      for (let allo of allos) {
        if (!alloCrops.includes(allo.crop)) {
          alloCrops.push(allo.crop);
        }
      }
      return alloCrops;
    },
    fieldCrops() {
      const results = [];
      for (let field of this.$store.state.fields.fields) {
        // if the field's current crop id is in the list of allocationCropIds then add it to the results
        if (this.allocationCropIds.includes(field.properties.current_crop.id)) {
          results.push({
            label: field.properties.name,
            value: field.properties.current_crop.id,
          });
        }
      }
      return results;
    },
  },
  methods: {
    async saveDelivery() {
      console.log("save delivery");
      let delivery = await this.$store.dispatch(
        "createGrowerDelivery",
        this.currentDelivery,
      );
      for (let weight_tag of this.attachments) {
        console.log("weight_tag", delivery, weight_tag, weight_tag.name);
        await this.saveAttachment(delivery, weight_tag);
      }
      if (!this.$store.getters.getApiError) {
        this.$router.push(this.orderPageNavigation(this.currentOrder));
      }
    },
    async uploadAttachment(file) {
      this.attachments.push(file);
    },
    async saveAttachment(delivery, file) {
      if (!delivery || !file) return;
      const attachment = await this.$store.dispatch(
        "uploadGrowerCropDeliveryAttachment",
        {
          deliveryId: delivery.id,
          file,
        },
      );
      this.currentDelivery.attachments.push(attachment);
    },
    async removeAttachment(name) {
      this.attachments = this.attachments.filter((a) => a.name !== name);
    },
    orderPageNavigation(order) {
      if (order.offer) {
        return {
          name: "grower-portal-offer-order",
          params: { orderId: order.id },
        };
      } else {
        return {
          name: "grower-portal-produce-order",
          params: { orderId: order.id },
        };
      }
    },
    // openFileTab(url) {
    //   window.open(url, "_blank");
    // },
  },
  mounted() {},
};
</script>
