<template>
  <page-card
    headingTitle="Offers Participation"
    headingDescription="These organizations can make offers to pay you for following cropping practices or purchase your crops"
  >
    <template #buttonArea> </template>
    <template #cardDetails>
      <div class="sm:col-span-6">
        Which Organizations Can Provide You Offers?
        <table-card>
          <template #header>
            <table-head
              :columns="[{ name: 'name', label: 'Affiliate', filtered: false }]"
              :collection="affiliateOrgs"
              @update:derived-collection="derivedAffiliateOrgs = $event"
            >
              <template #toolbarButtons>
                <div class="flex">
                  <table-toolbar-button
                    buttonText="Manage"
                    :usePlusIcon="true"
                    @buttonClick="goToNewAffiliateOrgPage"
                  />
                </div>
              </template>
            </table-head>
          </template>
          <tbody>
            <tr v-for="user in derivedAffiliateOrgs" :key="user.id">
              <table-data td-label="User">
                <div class="flex">
                  <Avatar :username="user.name" :src="user.image" />
                  {{ user.name }}
                </div>
              </table-data>
            </tr>
          </tbody>
        </table-card>
        <div class="mt-4 grid grid-cols-1 sm:grid-cols-6">
          <text-input
            :wideItem="true"
            inputTitle="Can These Organizations Search Your Crops to Make Offers?"
            inputHtmlType="date"
            :displayOnly="true"
            :displayOnlyValue="cropSupplyParticipation ? 'Yes' : 'No'"
          />
          <div class="sm:col-span-3">
            <base-button
              class="mt-6 h-9"
              :buttonText="cropSupplyParticipation ? 'Opt Out' : 'Opt In'"
              :marginLeft="true"
              @buttonClick="toggleCropSupplyParticipation"
            />
          </div>
        </div>
      </div>
    </template>
  </page-card>
</template>

<script>
import PageCard from "@/components/cards/PageCard.vue";
import BaseButton from "@/components/buttons/BaseButton.vue";
import TextInput from "@/components/form/TextInput.vue";
import TableCard from "@/components/table/TableCard.vue";
import TableHead from "@/components/table/TableHead.vue";
import TableData from "@/components/table/TableData.vue";
import TableToolbarButton from "@/components/table/TableToolbarButton.vue";
import Avatar from "@/components/identity/UserAvatar.vue";

export default {
  components: {
    PageCard,
    BaseButton,
    TextInput,
    TableCard,
    TableHead,
    TableData,
    TableToolbarButton,
    Avatar,
  },
  data() {
    return {
      derivedAffiliateOrgs: [],
    };
  },
  computed: {
    currentOrg() {
      return this.$store.getters.getCurrentOrg;
    },
    cropSupplyParticipation() {
      return this.currentOrg.share_crop_supply;
    },
    affiliateOrgs() {
      return this.$store.state.affiliateOrgs.affiliateOrgs;
    },
  },
  methods: {
    goToNewAffiliateOrgPage() {
      this.$router.push({ name: "settings-new-affiliate-org" });
      // Form Methods
    },
    toggleCropSupplyParticipation() {
      // unroll the current Org and then replace the share_crop_supply with the opposite of what it is
      this.$store.dispatch("updateOrg", {
        ...this.currentOrg,
        share_crop_supply: !this.cropSupplyParticipation,
      });
    },
  },
};
</script>
