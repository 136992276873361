<template>
  <modal :show="showModal" dialogPanelClass="max-w-screen-lg" :title="null">
    <record-form
      @saveRecord="storeRecord"
      @closeRecord="showModal = false"
      @cancelRecord="showModal = false"
      recordType="Organic Amendment"
      v-if="modalType == 'record'"
    />
    <task-form
      :field-id="fieldId"
      :task-id="selectedTaskId"
      @closeModal="showModal = false"
      prepopulatedCategory="Organic Amendment"
      v-if="modalType == 'task'"
    />
  </modal>
  <div
    class="mt-2 text-left text-lg font-medium uppercase tracking-wider text-gray-500 sm:col-span-6"
  >
    Manure
  </div>
  <!-- show current cover crop records-->

  <div
    v-if="records && scenario.metadata?.crops?.length == 1"
    class="w-full text-sm sm:col-span-6"
  >
    <div
      class="mt-2 w-full border-separate border-spacing-2 rounded-lg border border-slate-300"
    >
      <div
        class="mx-2 my-2 grid auto-cols-max grid-cols-[45%_20%_15%_15%+5%] text-gray-500"
      >
        <div>Application Date</div>
        <div>Source</div>
        <div>Rate (tons / acre)</div>
        <div>Method</div>
        <div></div>
        <template v-for="(record, index) in records" :key="index">
          <div class="">
            <span v-if="record.planned_or_actual == 'planned'">
              {{ record.deadline?.substring(0, 10) }} (Planned)</span
            >
            <span v-else> {{ record.payload?.application_date }}</span>
          </div>
          <div class="">
            {{ record.payload?.source }}
            <!-- ({{ record.payload.nitrogen }}-{{
              record.payload.phosphorus
            }}-{{ record.payload.potassium }}) -->
          </div>
          <div class="">
            {{ record.payload?.application_rate?.toFixed(2) }}
            <!-- ({{ record.payload.nitrogen }}-{{
              record.payload.phosphorus
            }}-{{ record.payload.potassium }}) -->
          </div>
          <div class="">
            {{ record.payload?.application_method }}
            <!-- ({{ record.payload.nitrogen }}-{{
              record.payload.phosphorus
            }}-{{ record.payload.potassium }}) -->
          </div>
          <div class="w-8 text-center">
            <span v-if="record.planned_or_actual == 'planned'">
              <a @click="editTask(record.taskId)"
                ><pencil-icon class="h-6 w-6 text-gray-500" /></a
            ></span>
          </div>
        </template>
        <!-- <div class="font-bold text-gray-800">
          <div>Total</div>
          <div></div>
          <div class="w-24 text-right">{{ totalNitrogen }}</div>
          <div class="w-24 text-right">{{ totalPhosphorus }}</div>
          <div class="w-24 text-right">{{ totalPotassium }}</div>
        </div> -->
      </div>
    </div>

    <div class="mt-2 flex justify-end">
      <base-button
        buttonText="+ Add Planned Ammendment"
        :redButton="false"
        @buttonClick="addTask"
        v-if="!readOnly"
      />
      <base-button
        buttonText="+ Add Record"
        :redButton="false"
        @buttonClick="addRecord"
        v-if="!readOnly"
      />
    </div>
  </div>
  <template v-else>
    <select-search
      :wideItem="true"
      inputTitle="Manure"
      :selectOptions="[
        {
          label: 'Manure',
          value: 'Manure',
        },
        {
          label: 'No manure',
          value: 'No manure',
        },
      ]"
      v-model="currentInputs.manure"
      :placeholder="scenario.defaults.manure"
      @on-blur="emitUpdateScenarioInputs"
      :display-only="readOnly"
      :display-only-value="currentInputs.manure"
    />
    <number-input
      :wideItem="true"
      inputTitle="Manure Application Fuel Used (gallons)"
      inputHtmlType="text"
      v-model="currentInputs.manure_application_gallons"
      :placeholder="scenario.defaults.manure_application_gallons?.toFixed(2)"
      :helpText="`Fuel used for manure application, do not include in total fuel used in the Energy section.`"
      @blur="emitUpdateScenarioInputs"
      :display-only="readOnly"
      :display-only-value="currentInputs.manure_application_gallons?.toFixed(2)"
      :nullIfEmpty="true"
    />
    <number-input
      :wideItem="true"
      inputTitle="Manure Transportation Distance (miles)"
      inputHtmlType="text"
      v-model="currentInputs.manure_transportation_distance"
      :placeholder="
        scenario.defaults.manure_transportation_distance?.toFixed(2)
      "
      @blur="emitUpdateScenarioInputs"
      :display-only="readOnly"
      :display-only-value="
        currentInputs.manure_transportation_distance?.toFixed(2)
      "
      :nullIfEmpty="true"
    />
    <number-input
      :wideItem="true"
      inputTitle="Manure Transportation Fuel Used (gallons)"
      inputHtmlType="text"
      v-model="currentInputs.manure_transportation_gallons"
      :placeholder="scenario.defaults.manure_transportation_gallons?.toFixed(2)"
      :helpText="`Fuel used for manure transportation from source to field, do not include in total fuel used in the Energy section.`"
      @blur="emitUpdateScenarioInputs"
      :display-only="readOnly"
      :display-only-value="
        currentInputs.manure_transportation_gallons?.toFixed(2)
      "
      :nullIfEmpty="true"
    />
    <number-input
      :wideItem="true"
      inputTitle="Beef Cattle (tons manure/acre)"
      inputHtmlType="text"
      v-model="currentInputs.beef_cattle"
      :placeholder="scenario.defaults.beef_cattle?.toFixed(2)"
      @blur="emitUpdateScenarioInputs"
      :display-only="readOnly"
      :display-only-value="currentInputs.beef_cattle?.toFixed(2)"
      :nullIfEmpty="true"
    />
    <number-input
      :wideItem="true"
      inputTitle="Chicken (tons manure/acre)"
      inputHtmlType="text"
      v-model="currentInputs.chicken"
      :placeholder="scenario.defaults.chicken?.toFixed(2)"
      @blur="emitUpdateScenarioInputs"
      :display-only="readOnly"
      :display-only-value="currentInputs.chicken?.toFixed(2)"
      :nullIfEmpty="true"
    />
    <number-input
      :wideItem="true"
      inputTitle="Dairy Cow (tons manure/acre)"
      inputHtmlType="text"
      v-model="currentInputs.dairy_cow"
      :placeholder="scenario.defaults.dairy_cow?.toFixed(2)"
      @blur="emitUpdateScenarioInputs"
      :display-only="readOnly"
      :display-only-value="currentInputs.dairy_cow?.toFixed(2)"
      :nullIfEmpty="true"
    />
    <number-input
      :wideItem="true"
      inputTitle="Swine (tons manure/acre)"
      inputHtmlType="text"
      v-model="currentInputs.swine"
      :placeholder="scenario.defaults.swine?.toFixed(2)"
      @blur="emitUpdateScenarioInputs"
      :display-only="readOnly"
      :display-only-value="currentInputs.swine?.toFixed(2)"
      :nullIfEmpty="true"
    />
  </template>
</template>
<script>
import NumberInput from "@/components/form/NumberInput";
import SelectSearch from "@/components/form/SelectSearch";
import BaseButton from "@/components/buttons/BaseButton";
import { PencilIcon } from "@heroicons/vue/outline";
import Modal from "@/components/modals/PopupModal";
import RecordForm from "@/layouts/grower-portal/records/RecordForm.vue";
import TaskForm from "@/layouts/grower-portal/operations/PlanTask.vue";
export default {
  components: {
    NumberInput,
    SelectSearch,
    BaseButton,
    Modal,
    RecordForm,
    TaskForm,
    PencilIcon,
  },
  props: {
    scenarioInitial: {
      type: Object,
      required: true,
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["updateScenarioInputs"],
  data() {
    return {
      currentInputs: this.scenarioInitial?.inputs
        ? this.scenarioInitial.inputs
        : {
            manure: "Manure",
            swine: null,
            dairy_cow: null,
            beef_cattle: null,
            chicken: null,
            manure_application_energy: null,
            manure_transportation_distance: null,
            manure_transportation_energy: null,
          },
      recordCache: [],
      showModal: false,
      selectedTaskId: null,
      modalType: null,
    };
  },
  computed: {
    scenario() {
      return this.scenarioInitial;
    },
    fieldId() {
      if (this.scenario.metadata?.crops?.length == 1) {
        let cropId = this.scenario.metadata.crops[0];
        let field = this.$store.getters.getFieldByCropId(cropId);
        return field.id;
      }
      return null;
    },
    records() {
      let records = [];
      let tasks = [];
      if (this.scenario.metadata?.crops?.length == 1) {
        records = this.$store.getters.getRecordsByCrop(
          this.scenario.metadata.crops[0],
        );
        if (records && records.length > 0) {
          records = records.filter((record) => {
            return (
              record.category == "Organic Amendment" &&
              record.payload.type == "Manure"
            );
          });
        }
        // console.log("records loaded", records);
        tasks = this.$store.getters.getTasksByField(this.fieldId);
        if (tasks && tasks.length > 0) {
          tasks = tasks.filter((task) => {
            return (
              task.category == "Organic Amendment" &&
              task.details?.payload?.type == "Manure"
            );
          });
          tasks = tasks.map((task) => {
            return {
              ...task.details,
              planned_or_actual: "planned",
              deadline: task.deadline,
              taskId: task.id,
            };
          });
          records = records.concat(tasks);
        }
      }
      return records;
    },
  },
  methods: {
    emitUpdateScenarioInputs() {
      this.$emit("updateScenarioInputs", this.currentInputs);
    },
    addRecord() {
      this.modalType = "record";
      this.showModal = true;
    },
    addTask() {
      this.selectedTaskId = null;
      this.modalType = "task";
      this.showModal = true;
    },
    editTask(taskId) {
      this.selectedTaskId = taskId;
      this.modalType = "task";
      this.showModal = true;
    },
    storeRecord(record) {
      record.field = null;
      record.category = "Organic Amendment";
      record.crop = this.scenario.metadata.crops[0];
      this.$store.dispatch("createRecord", record);
      this.showModal = false;
    },
  },
  watch: {
    scenarioInitial: {
      // handler(value) or handler(newValue, oldValue)
      handler(value) {
        this.currentInputs = value.inputs;
      },
      // this will cause the handler to be run immediately on component creation
      immediate: true,
      deep: true,
    },
    records: {
      // handler(value) or handler(newValue, oldValue)
      handler(value) {
        if (!value) {
          return;
        }
        if (this.recordCache.length == value.length) {
          // no change
          return;
        }
        // console.log("records changed", value);
        value.length > 0
          ? (this.currentInputs.manure = "Manure")
          : (this.currentInputs.manure = "No manure");
        this.currentInputs.manure_application_gallons = 0;
        this.currentInputs.manure_transportation_distance = 0;
        this.currentInputs.manure_transportation_gallons = 0;
        this.currentInputs.beef_cattle = 0;
        this.currentInputs.chicken = 0;
        this.currentInputs.dairy_cow = 0;
        this.currentInputs.swine = 0;

        for (let record of value) {
          switch (record.payload.source) {
            case "Beef Cattle":
              this.currentInputs.beef_cattle += record.payload.application_rate;
              break;
            case "Chicken":
              this.currentInputs.chicken += record.payload.application_rate;
              break;
            case "Dairy Cow":
              this.currentInputs.dairy_cow += record.payload.application_rate;
              break;
            case "Swine":
              this.currentInputs.swine += record.payload.application_rate;
              break;
          }
          record.payload.transportation_distance
            ? (this.currentInputs.manure_transportation_distance +=
                record.payload.transportation_distance)
            : 0;
          record.payload.transportation_gallons
            ? (this.currentInputs.manure_transportation_gallons +=
                record.payload.transportation_gallons)
            : 0;
          record.payload.application_gallons
            ? (this.currentInputs.manure_application_gallons +=
                record.payload.application_gallons)
            : 0;
        }
        this.recordCache = value;
        this.emitUpdateScenarioInputs();
      },
      // this will cause the handler to be run immediately on component creation
      immediate: true,
      deep: true,
    },
  },
};
</script>
