<template>
  <delete-modal
    :open="modalIsActive"
    modalTitle="Delete Inquiry"
    modalDescription="Are you sure you want to permanently delete this inquiry?"
    @clickCancel="cancelDelete"
    @clickConfirmDelete="deleteCropInquiry"
  />
  <page-card :headingTitle="'Inquiry: ' + $route.params.inquiryId">
    <template #buttonArea>
      <submit-button
        buttonText="Save"
        :marginLeft="false"
        :formId="editCropInquiryFormId"
      />
      <base-button
        buttonText="Delete"
        :redButton="true"
        @buttonClick="intializeDeleteModal"
      />
    </template>
    <template #cardDetails>
      <crop-inquiry-form
        :orderId="parseInt($route.params.inquiryId)"
        :formId="editCropInquiryFormId"
        @formSaved="saveAttachments"
      />
    </template>
    <template #componentSection>
      <!-- Tabs Below Grid -->
      <TabGroup as="div" :selectedIndex="selectedTab" @change="tabChanged">
        <div class="border-b border-gray-200">
          <TabList class="-mb-px flex space-x-8">
            <Tab as="template" v-slot="{ selected }">
              <button
                :class="[
                  selected
                    ? 'border-indigo-600 text-indigo-600'
                    : 'border-transparent text-gray-700 hover:border-gray-300 hover:text-gray-800',
                  'whitespace-nowrap border-b-2 py-6 text-sm font-medium',
                ]"
              >
                Details
              </button>
            </Tab>
            <Tab as="template" v-slot="{ selected }">
              <button
                :class="[
                  selected
                    ? 'border-indigo-600 text-indigo-600'
                    : 'border-transparent text-gray-700 hover:border-gray-300 hover:text-gray-800',
                  'whitespace-nowrap border-b-2 py-6 text-sm font-medium',
                ]"
              >
                Premiums
              </button>
            </Tab>
            <Tab as="template" v-slot="{ selected }">
              <button
                :class="[
                  selected
                    ? 'border-indigo-600 text-indigo-600'
                    : 'border-transparent text-gray-700 hover:border-gray-300 hover:text-gray-800',
                  'whitespace-nowrap border-b-2 py-6 text-sm font-medium',
                ]"
              >
                Field Allocations
              </button>
            </Tab>
            <Tab as="template" v-slot="{ selected }">
              <button
                :class="[
                  selected
                    ? 'border-indigo-600 text-indigo-600'
                    : 'border-transparent text-gray-700 hover:border-gray-300 hover:text-gray-800',
                  'whitespace-nowrap border-b-2 py-6 text-sm font-medium',
                ]"
              >
                Attachments
              </button>
            </Tab>
            <Tab as="template" v-slot="{ selected }">
              <button
                :class="[
                  selected
                    ? 'border-indigo-600 text-indigo-600'
                    : 'border-transparent text-gray-700 hover:border-gray-300 hover:text-gray-800',
                  'whitespace-nowrap border-b-2 py-6 text-sm font-medium',
                ]"
              >
                Chat
              </button>
            </Tab>
          </TabList>
        </div>
        <TabPanels as="template">
          <TabPanel as="dl" class="pt-10">
            <contract-form-details
              v-if="readyToReadOffer"
              v-model="currentOfferDetails"
              :readOnly="true"
            />
          </TabPanel>
          <TabPanel as="dl" class="pt-10">
            <contract-form-premiums
              v-if="readyToReadOffer"
              v-model="currentOfferPremiums"
              :cropType="currentOfferDetails.crop"
              :readOnly="true"
            />
          </TabPanel>
          <TabPanel as="dl" class="pt-10">
            <allocations-manager :orderId="parseInt($route.params.inquiryId)" />
          </TabPanel>
          <TabPanel as="dl" class="pt-10" :unmount="false">
            <add-attachments-table
              :parentId="currentOrder?.id"
              :attachments="currentOrder?.attachments"
              addStoreAction="uploadGrowerCropOrderAttachment"
              removeStoreAction="removeGrowerCropOrderAttachment"
              ref="attachmentsTable"
            />
          </TabPanel>
          <TabPanel as="dl" class="pt-10">
            <chat-single-view :thread-id="currentOrder?.details?.threadId" />
          </TabPanel>
        </TabPanels>
      </TabGroup>
    </template>
  </page-card>
</template>

<script>
import { Tab, TabGroup, TabList, TabPanel, TabPanels } from "@headlessui/vue";
import CropInquiryForm from "@/layouts/buyer-grower/crop-inquiries/CropInquiryForm.vue";
import PageCard from "@/components/cards/PageCard.vue";
import BaseButton from "@/components/buttons/BaseButton.vue";
import SubmitButton from "@/components/buttons/SubmitButton.vue";
import DeleteModal from "@/components/modals/DeleteModal.vue";
import ContractFormDetails from "@/layouts/common/contracts/ContractFormDetails.vue";
import ContractFormPremiums from "@/layouts/common/contracts/ContractFormPremiums.vue";
import AllocationsManager from "@/layouts/grower-portal/sell/orders/AllocationsManager.vue";
import AddAttachmentsTable from "@/layouts/common/attachments/AddAttachmentsTable.vue";
import ChatSingleView from "@/layouts/common/ChatSingleView.vue";

export default {
  components: {
    Tab,
    TabGroup,
    TabList,
    TabPanel,
    TabPanels,
    PageCard,
    BaseButton,
    SubmitButton,
    CropInquiryForm,
    DeleteModal,
    ContractFormDetails,
    ContractFormPremiums,
    AllocationsManager,
    AddAttachmentsTable,
    ChatSingleView,
  },
  data() {
    let selectedTab = 0;
    if (this.$route.query.tab) {
      selectedTab = Number(this.$route.query.tab);
    }
    return {
      modalIsActive: false,
      editCropInquiryFormId: "editCropInquiry",
      selectedTab,
      currentOfferDetails: {},
      currentOfferPremiums: [],
      currentOfferTerritory: {},
    };
  },
  computed: {
    inquiryId() {
      return parseInt(this.$route.params.inquiryId);
    },
    currentOrder() {
      return this.$store.getters.getGrowerCropOrderById(this.inquiryId);
    },
    offerId() {
      return this.currentOrder?.offer;
    },
    currentOffer() {
      return this.$store.getters.getGrowerMarketOfferById(this.offerId);
    },
    readyToReadOffer() {
      if (
        this.offerId &&
        this.$store.getters.getGrowerMarketOfferById(this.offerId)
      ) {
        return true;
      } else {
        return false;
      }
    },
  },
  methods: {
    // Modal Methods
    intializeDeleteModal() {
      this.modalIsActive = true;
    },
    cancelDelete() {
      this.modalIsActive = false;
    },
    async deleteCropInquiry() {
      this.modalIsActive = false;
      // console.log("DELETE");
      await this.$store.dispatch("deleteGrowerCropOrder", {
        id: this.$route.params.inquiryId,
      });
      if (!this.$store.getters.getApiError) {
        this.$router.push({ name: "grower-portal-produce-inquiries" });
      }
    },
    tabChanged(index) {
      // console.log("tabChanged", index);
      this.selectedTab = index;
      this.$router.push({
        query: { tab: index },
      });
    },
    readOffer() {
      if (!this.readyToReadOffer) {
        return null;
      }
      // set current contract details, premiums, and territory
      let currentOffer = this.$store.getters.getGrowerMarketOfferById(
        this.offerId,
      );
      this.currentOfferDetails = { ...currentOffer };
      this.currentOfferPremiums = currentOffer.premiums;
      this.currentOfferTerritory = currentOffer.territory;
      delete this.currentOfferDetails.premiums;
      delete this.currentOfferDetails.territory;
    },
    saveAttachments() {
      // console.log("saveAttachments");
      this.$refs.attachmentsTable.saveAttachments();
    },
  },
  watch: {
    inquiryId: {
      // handler(value) or handler(newValue, oldValue)
      handler() {
        this.readOffer();
      },
      // this will cause the handler to be run immediately on component creation
      immediate: true,
    },
    readyToReadOffer: {
      handler(value) {
        if (value && this.offerId) {
          this.readOffer();
        }
      },
      immediate: true,
    },
  },
};
</script>
