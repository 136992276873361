<template>
  <div class="flex">
    <!-- Map Display here -->
    <div id="mapEditId" style="height: 75vh; width: 75vw" class="z-0"></div>
  </div>
  <slot></slot>
</template>

<script>
import "leaflet/dist/leaflet.css";
import L from "leaflet";
import "./leaflet-geoman.css";
import "@geoman-io/leaflet-geoman-free";
// This is workaround code to make leaflet work with webpack.
delete L.Icon.Default.prototype._getIconUrl;
L.Icon.Default.mergeOptions({
  iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
  iconUrl: require("leaflet/dist/images/marker-icon.png"),
  shadowUrl: require("leaflet/dist/images/marker-shadow.png"),
});
// end of workaround

import * as turf from "@turf/turf";

export default {
  components: {},
  props: {
    fieldId: { type: Number, default: null },
    layers: { type: Array, default: null },
    showLayerSelector: { type: Boolean, default: false },
  },
  emits: ["edited", "location-added", "removeMarker"],
  data: function () {
    return {
      message: "initial",
      loading: false,
      access_token: process.env.VUE_APP_MAP_ACCESS_TOKEN,
      // map: {},
      draw: {},
      area: "",
      distance: "",
      selectedField: null,
    };
  },
  mounted() {
    this.createMap();
  },
  methods: {
    async createMap() {
      // try {
      let componentHandle = this;
      // Create the map

      const map = new L.map("mapEditId");
      //this.map = map  disabling due to a bug in vue3 / leaflet
      // Initialise the FeatureGroup to store editable layers
      var editableLayers = new L.FeatureGroup();
      map.addLayer(editableLayers);

      if (this.showLayerSelector)
        var layerControl = L.control
          .layers(null, null, { collapsed: false })
          .addTo(map);

      // add the satellite base layer
      L.tileLayer(
        "https://api.mapbox.com/styles/v1/{id}/tiles/{z}/{x}/{y}?access_token={accessToken}",
        {
          attribution:
            'Map data &copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors, Imagery © <a href="https://www.mapbox.com/">Mapbox</a>',
          maxZoom: 18,
          id: "airrer1/ckqqvow3854p218l3d6lo43s5",
          tileSize: 512,
          zoomOffset: -1,
          accessToken: this.access_token,
        },
      ).addTo(map);

      // add the field to the map
      if (this.fieldId) {
        // add existing field to the map
        let field = this.$store.getters.getFieldById(this.fieldId);
        // let field = await this.$_rm_getField(this.fieldId);
        let locations = this.$store.getters.getGrowerLocationsByFieldId(
          this.fieldId,
        );

        for (let loc of locations) {
          loc.geometry = loc.point;
          if (loc?.properties?.label)
            loc.properties = { label: loc.properties.label };
          else loc.properties = { label: loc.type };
          loc.type = "Feature";
        }

        // console.log("field returned", field);
        var fieldStyle = {
          opacity: 1,
          weight: 1,
          color: "blue",
        };
        let fields = [];
        fields.push(field);

        let centroid = L.geoJson(fields).getBounds().getCenter();
        // console.log(centroid);
        map.setView(centroid, 20);
        map.fitBounds(L.geoJson(fields).getBounds());

        let fieldLayer = L.geoJSON(fields, {
          onEachFeature: function (feature, layer) {
            editableLayers.addLayer(layer);
          },
          style: fieldStyle,
        }).addTo(map);

        let locationsLayer = L.geoJSON(locations, {
          onEachFeature: function (feature, layer) {
            //layer.bindPopup("popupContent <button @click="remove layer">delete me </button>").openPopup(); TODO: add popup and add a delete method in it.
            editableLayers.addLayer(layer);
          },
        }).addTo(map);

        locationsLayer.bindTooltip(function (layer) {
          return layer.feature.properties.label;
        });

        // Display field name on hover (tap on mobile)
        fieldLayer.bindTooltip(function (layer) {
          return layer.feature.properties.name;
        });

        // Add editing features to the map

        // Initialise the draw control and pass it the FeatureGroup of editable layers
        map.pm.addControls({
          position: "topleft",
          drawPolygon: false,
          drawMarker: true,
          drawPolyline: false,
          drawRectangle: false,
          drawCircleMarker: false,
          drawCircle: false,
          drawText: false,
          rotateMode: false,
          dragMode: false,
          removalMode: false, //TODO: allow user to remove a whole field, instead of one point at a time...
        });

        // Track events that occur when editing.
        fieldLayer.on("pm:edit", (e) => {
          var layers = L.PM.Utils.findLayers(map);
          var group = L.featureGroup();
          layers.forEach((layer) => {
            group.addLayer(layer);
          });
          let shape = group.toGeoJSON();
          e.layer.feature.geometry = shape.features[0].geometry; // override original feature with new geometry
          let acres =
            Math.round(
              (turf.area(shape.features[0].geometry) / 4046.85642) * 100,
            ) / 100; // 4046.85642 is number of square meters in an acre. *100/100 is a hack to get two decimals
          this.$emit("edited", e.layer.feature, acres);
        });
        // Track remove an area event.
        fieldLayer.on("pm:remove", (e) => {
          // console.log("remove shape", e);
          e.layer.feature.geometry = null; // override original feature with null geometry
          let acres = 0;
          this.$emit("edited", e.layer.feature, acres);
        });
        locationsLayer.on("pm:remove", (e) => {
          // console.log("remove marker", e.layer.feature.id);
          this.$emit("removeMarker", e.layer.feature.id);
        });
      } else {
        // console.log("adding a new field");
        // new field
        map.pm.addControls({
          position: "topleft",
          drawMarker: false,
          drawPolyline: false,
          drawRectangle: false,
          drawCircleMarker: false,
          drawCircle: false,
          drawText: false,
          editMode: false,
          cutPolygon: false,
          removalMode: false,
          rotateMode: false,
          dragMode: false,
        });

        // center the map on any existing fields so its easier to draw a new one
        let centroid = [36.897433, -121.736681]; // default if there are no fields
        let bounds = this.$store.state.fields.bounds;
        let bbox = [bounds[0][0], bounds[0][1], bounds[1][0], bounds[1][1]];
        let poly = turf.bboxPolygon(bbox);
        //console.log("centroid", bounds, bbox, poly, this.$store.state.fields);
        if (poly) centroid = turf.center(poly)?.geometry?.coordinates;
        //console.log(centroid);
        if (!centroid) centroid = [36.897433, -121.736681]; // default if there are no fields
        map.setView(centroid, 10);
      }

      // show any other layers passed in as props
      var visibleLayers = new L.FeatureGroup();
      map.addLayer(visibleLayers);
      if (this.layers) {
        for (let layer of this.layers) {
          let features = layer;
          // console.log("features", features);
          let geojson_points = features.geojson;
          // console.log("points", geojson_points);
          let layerStyle = null;
          if (layer.style) layerStyle = layer.style;
          //console.log(JSON.stringify(geojson_points));
          let mapLayer = null; // will add as either an image layer or a geojson layer.
          // Now add the key if present
          var legend = L.control({ position: "bottomleft" });
          legend.onAdd = function () {
            var div = L.DomUtil.create("div", "legend");
            // let label = "<img  width='50' src='" + layer.key + "' />";
            // div.innerHTML = label;
            div.innerHTML = layer.key;
            return div;
          };
          legend.addTo(map);
          if (layer.images != null) {
            mapLayer = new L.LayerGroup(); // adds a set of images as a single layer so we can turn it on / off.
            //Image layer
            layer.images.forEach((image) => {
              // console.log("image overlay", image);
              L.imageOverlay(image.url, image.bounds).addTo(mapLayer);
            });

            mapLayer.addTo(map);
          } else {
            //GeoJSON layer
            mapLayer = L.geoJSON(geojson_points, {
              pmIgnore: true,

              onEachFeature: function (feature, layer) {
                // This would let us select a feature, but we want the focus
                // on edit to remain on the editable field
                // layer.on({
                //   click: (e) => {
                //     //calls up the feature clicked on
                //     mapLayer.resetStyle();
                //     var selectedFeature = e.target;
                //     if (selectedFeature && selectedFeature.feature)
                //       componentHandle.selectedFeature = selectedFeature.feature; //save link to selected feature so we can navigate to it if asked
                //     var highlightStyle = {
                //       opacity: 1,
                //       weight: 5,
                //       color: "red",
                //     };
                //     selectedFeature.bringToFront();
                //     selectedFeature.setStyle(highlightStyle);
                //   },
                // });
                visibleLayers.addLayer(layer);
              },
              style: layerStyle,
            });

            // default to off
            mapLayer.addTo(map);

            // console.log("LAYER BOUNDS", mapLayer.getBounds());
            // mapBounds.push(mapLayer.getBounds());
          }
          if (this.showLayerSelector)
            layerControl.addOverlay(mapLayer, layer.name);
          // Display field name on hover (tap on mobile)
          mapLayer.bindTooltip(function (featureCollection) {
            // console.log("tooltip", featureCollection);
            return featureCollection.feature.properties?.name;
          });
        }
      }
      // END OF LAYER LOOP

      // Set Toolbar tooltips - can also set tooltips for drawing if needed
      const customTranslation = {
        tooltips: {
          placeMarker: "Add a pickup or entrance location",
        },
        buttonTitles: {
          drawMarkerButton: "Add a pickup or entrance location",
          drawPolyButton: "Draw the Field",
          editButton: "Edit Field",
          cutButton: "Add Excluded Area in Field",
          deleteButton: "Remove a Field",
        },
      };

      map.pm.setLang("custom_en", customTranslation, "en");
      map.pm.setGlobalOptions({ snappable: false });
      // Track adding new field.
      map.on("pm:create", function (e) {
        // console.log("drew a field");
        var type = e.shape;
        var layer = e.layer;
        // console.log(type);
        if (type === "Marker") {
          // console.log("added a marker");
          componentHandle.$emit("location-added", e.layer.toGeoJSON().geometry);
        } else {
          // console.log(layer);
          editableLayers.addLayer(layer);
          //TODO: Save new shape
          //var layers = L.PM.Utils.findLayers(map);
          //var group = L.featureGroup();
          //.forEach((layer) => {
          //  group.addLayer(layer);
          //});
          //let shape = group.toGeoJSON();
          let shape = editableLayers.toGeoJSON();
          let acres =
            Math.round(
              (turf.area(shape.features[0].geometry) / 4046.85642) * 100,
            ) / 100; // 4046.85642 is number of square meters in an acre. *100/100 is a hack to get two decimals
          // console.log(shape);
          componentHandle.$emit("edited", shape.features[0], acres);
        }
        map.pm.addControls({
          editMode: true,
        });
      });
      editableLayers.on("pm:edit", () => {
        // console.log("edit shape in add", e, editableLayers);
        let shape = editableLayers.toGeoJSON();
        let acres =
          Math.round(
            (turf.area(shape.features[0].geometry) / 4046.85642) * 100,
          ) / 100; // 4046.85642 is number of square meters in an acre. *100/100 is a hack to get two decimals
        this.$emit("edited", shape.features[0], acres);
      });
      // } catch (err) {
      //   console.log("map error", err);
      // }
    },
  },
  computed: {},
};
</script>
