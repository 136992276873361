import client from "@/api/parcel/api.js";
// import { universalGetter } from "@/components/composables/universalGetter";
import _ from "lodash";

export default {
  state() {
    return {
      controllerMarketOffers: [],
      filterObject: {},
    };
  },
  mutations: {
    SET_CONTROLLER_MARKET_OFFERS(state, controllerMarketOfferData) {
      state.controllerMarketOffers = controllerMarketOfferData;
    },
    ADD_CONTROLLER_MARKET_OFFER(state, controllerMarketOfferObject) {
      state.controllerMarketOffers.push(controllerMarketOfferObject);
    },
    UPDATE_CONTROLLER_MARKET_OFFER(state, controllerMarketOfferObject) {
      state.controllerMarketOffers = state.controllerMarketOffers.filter(
        function (obj) {
          return obj.id !== controllerMarketOfferObject.id;
        },
      );
      state.controllerMarketOffers.push(controllerMarketOfferObject);
    },
    DELETE_CONTROLLER_MARKET_OFFER(state, controllerMarketOfferObject) {
      state.controllerMarketOffers = state.controllerMarketOffers.filter(
        function (obj) {
          return obj.id !== controllerMarketOfferObject.id;
        },
      );
    },
    RESET_CONTROLLER_MARKET_OFFERS(state) {
      state.controllerMarketOffers = [];
      state.filterObject = {};
    },
    SET_FILTER(state, filterObject) {
      state.filterObject = filterObject;
    },
  },
  actions: {
    async getControllerMarketOffers({ commit }) {
      const { data, errors } = await client.controller.marketOffers.read();
      if (errors) {
        console.log(errors);
      } else {
        commit("SET_CONTROLLER_MARKET_OFFERS", data.offers);
      }
    },
    async createControllerMarketOffer(
      { commit, dispatch },
      controllerMarketOffer,
    ) {
      const { data, errors } = await client.controller.marketOffers.create(
        null,
        controllerMarketOffer,
      );
      if (errors) {
        console.log(errors);
        dispatch("catchParcelApiError", errors);
      } else {
        // console.log(data);
        commit("ADD_CONTROLLER_MARKET_OFFER", data);
        // update controllerMarketOffers list and crops list to ensure we have the latest elsewhere (like order allocations)
        this.dispatch("getControllerMarketOffers");
      }
    },
    async updateControllerMarketOffer(
      { commit, dispatch },
      controllerMarketOffer,
    ) {
      const { data, errors } = await client.controller.marketOffers.update(
        controllerMarketOffer.id,
        controllerMarketOffer,
      );
      if (errors) {
        console.log(errors);
        dispatch("catchParcelApiError", errors);
      } else {
        // console.log(data);
        commit("UPDATE_CONTROLLER_MARKET_OFFER", data);
        // update controllerMarketOffers list and crops list to ensure we have the latest elsewhere (like order allocations)
        this.dispatch("getControllerMarketOffers");
      }
    },
    async deleteControllerMarketOffer(
      { commit, dispatch },
      controllerMarketOffer,
    ) {
      const { data, errors } = await client.controller.marketOffers.delete(
        controllerMarketOffer.id,
      );
      if (errors) {
        console.log(errors);
        dispatch("catchParcelApiError", errors);
      } else {
        console.log(data);
        commit("DELETE_CONTROLLER_MARKET_OFFER", controllerMarketOffer);
        this.dispatch("getControllerMarketOffers");
      }
    },
  },
  getters: {
    getControllerMarketOfferById: (state) => (controllerMarketOfferId) => {
      return state.controllerMarketOffers.find(
        (controllerMarketOffer) =>
          controllerMarketOffer.id === parseInt(controllerMarketOfferId),
      );
    },
    getControllerMarketOffers: (state) => () => {
      return state.controllerMarketOffers;
    },
    getControllerMarketOffersByContractId: (state) => (contractId) => {
      return state.controllerMarketOffers.filter(
        (controllerMarketOffer) =>
          controllerMarketOffer?.buyer_contract === parseInt(contractId),
      );
    },
    // UNIVERSAL GETTER
    // getControllerMarketOffers:
    //   (state) =>
    //   ({
    //     filterFunction = null,
    //     filter = {},
    //     match = "",
    //     sort = [],
    //     pageSize = null,
    //     pageNum = 0,
    //   }) => {
    //     let derivedControllerMarketOffers = universalGetter(
    //       {
    //         filterFunction: filterFunction,
    //         filter: filter,
    //         match: match,
    //         sort: sort,
    //         pageSize: pageSize,
    //         pageNum: pageNum,
    //       },
    //       state.controllerMarketOffers
    //     );
    //     return derivedControllerMarketOffers;
    //   },
    getDerivedControllerMarketOffers:
      (state) =>
      ({
        filter = {}, // {crop: "Strawberry"} - return elements where crop is exactly "Strawberry"
        match = "", // "Pajaro" - return all elements containing "pajaro"/"PAJARO"/"pAjArO"
        sort = {}, // {"acres":'asc',"id":'desc'} - sort by updated then by id
        pageSize = null, // 5 - return 5 elements, else return all
        pageNum = 0, // 0 - return <pageSize> elements, starting at page zero, if pageSize is not null
      }) => {
        let expandedControllerMarketOffers = state.controllerMarketOffers.map(
          (item) => ({
            ...item,
          }),
        );
        let filteredControllerMarketOffers = _.isEmpty(filter)
          ? expandedControllerMarketOffers
          : _.filter(expandedControllerMarketOffers, filter);
        let matchedControllerMarketOffers = _.isEmpty(match)
          ? filteredControllerMarketOffers
          : _.filter(filteredControllerMarketOffers, (item) =>
              _.some(item, (val) =>
                _.includes(_.lowerCase(val), _.lowerCase(match)),
              ),
            );
        let sortColumns = Object.keys(sort);
        let sortOrders = Object.values(sort);
        //console.log("sort in index",sort,sortColumns, sortOrders);
        let sortedControllerMarketOffers = _.isEmpty(sort)
          ? matchedControllerMarketOffers
          : _.orderBy(matchedControllerMarketOffers, sortColumns, sortOrders);
        let paginatedControllerMarketOffers =
          _.isNumber(pageSize) && _.isNumber(pageNum)
            ? _.slice(
                sortedControllerMarketOffers,
                pageSize * pageNum, // skip
                pageSize * pageNum + pageSize, // limit
              )
            : sortedControllerMarketOffers;
        return paginatedControllerMarketOffers;
      },
    getControllerMarketOffersFilter(state) {
      if (state.filterObject == null) {
        return {};
      }
      return state.filterObject;
    },
  },
};
