<template>
  <div class="flex bg-white" v-if="compare">
    <div class="w-1/2 px-6 shadow-sm sm:rounded-b-lg">
      <span class="text-lg font-medium uppercase text-gray-500"
        >Existing Practice ({{ compareScenario?.name }} )</span
      >
      <carbon-scenario-form
        onSubmitNavigationRouteName="carbon-single-scenario"
        :readOnly="true"
        :scenarioId="compare"
      />
    </div>
    <div class="w-1/2 px-6">
      <span class="text-lg font-medium uppercase text-gray-500"
        >Proposed Practice</span
      >
      <carbon-scenario-form
        onSubmitNavigationRouteName="carbon-single-scenario"
        :reserveSpace="true"
        :compareScenarioId="compare"
      />
    </div>
  </div>
  <div
    class="grid grid-cols-1 gap-x-4 gap-y-8 bg-white px-6 shadow-sm sm:grid-cols-6 sm:rounded-b-lg"
    v-else
  >
    <carbon-scenario-form
      onSubmitNavigationRouteName="carbon-single-scenario"
    />
  </div>
</template>

<script>
import CarbonScenarioForm from "@/layouts/common/carbon/CarbonScenarioForm.vue";

export default {
  components: {
    CarbonScenarioForm,
  },

  data() {
    return {};
  },
  computed: {
    compare() {
      if (this.$route.query.compare) return this.$route.query.compare;
      else return null;
    },
    compareScenario() {
      if (!this.compare) return null;
      return this.$store.getters.getScenarioById(this.compare);
    },
  },
  methods: {},
};
</script>
