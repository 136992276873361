<template>
  <delete-modal
    :open="modalIsActive"
    modalTitle="Delete Order"
    modalDescription="Are you sure you want to permanently delete this order?"
    @clickCancel="cancelDelete"
    @clickConfirmDelete="deleteProduceOrder"
  />
  <page-card :headingTitle="'Order: ' + $route.params.orderId">
    <template #buttonArea>
      <base-button buttonText="Save" @buttonClick="saveAttachments" />
      <base-button
        buttonText="Delete"
        :redButton="true"
        @buttonClick="intializeDeleteModal"
      />
    </template>
    <template #cardDetails>
      <crop-order-display
        v-if="currentOrder?.status != 'Internal'"
        :orderId="parseInt($route.params.orderId)"
        @formSaved="saveAttachments()"
      />
      <internal-crop-order-form
        v-else
        :orderId="parseInt($route.params.orderId)"
        :formId="editProduceOrderFormId"
        @formSaved="
          saveAllocations();
          saveAttachments();
        "
      />
      <allocation-aggregate :orderId="parseInt($route.params.orderId)" />
      <CropHarvestAggregate
        :orderId="parseInt($route.params.orderId)"
        perspective="grower"
      />
      <DeliveryAggregate :orderId="parseInt($route.params.orderId)" />
    </template>
    <template #componentSection>
      <!-- Tabs Below Grid -->
      <TabGroup as="div" :selectedIndex="selectedTab" @change="tabChanged">
        <div class="border-b border-gray-200">
          <TabList class="-mb-px flex space-x-8">
            <Tab as="template" v-slot="{ selected }">
              <button
                :class="[
                  selected
                    ? 'border-indigo-600 text-indigo-600'
                    : 'border-transparent text-gray-700 hover:border-gray-300 hover:text-gray-800',
                  'whitespace-nowrap border-b-2 py-6 text-sm font-medium',
                ]"
              >
                Allocate Fields To Order
              </button>
            </Tab>
            <!-- <Tab as="template" v-slot="{ selected }">
              <button
                :class="[
                  selected
                    ? 'border-indigo-600 text-indigo-600'
                    : 'border-transparent text-gray-700 hover:border-gray-300 hover:text-gray-800',
                  'whitespace-nowrap border-b-2 py-6 text-sm font-medium',
                ]"
              >
                Update Quantities Allocated By Field
              </button>
            </Tab> -->
            <Tab as="template" v-slot="{ selected }">
              <button
                :class="[
                  selected
                    ? 'border-indigo-600 text-indigo-600'
                    : 'border-transparent text-gray-700 hover:border-gray-300 hover:text-gray-800',
                  'whitespace-nowrap border-b-2 py-6 text-sm font-medium',
                ]"
              >
                Details
              </button>
            </Tab>
            <Tab as="template" v-slot="{ selected }">
              <button
                :class="[
                  selected
                    ? 'border-indigo-600 text-indigo-600'
                    : 'border-transparent text-gray-700 hover:border-gray-300 hover:text-gray-800',
                  'whitespace-nowrap border-b-2 py-6 text-sm font-medium',
                ]"
              >
                Premiums
              </button>
            </Tab>
            <Tab as="template" v-slot="{ selected }">
              <button
                :class="[
                  selected
                    ? 'border-indigo-600 text-indigo-600'
                    : 'border-transparent text-gray-700 hover:border-gray-300 hover:text-gray-800',
                  'whitespace-nowrap border-b-2 py-6 text-sm font-medium',
                ]"
              >
                Attachments
              </button>
            </Tab>
            <Tab as="template" v-slot="{ selected }">
              <button
                :class="[
                  selected
                    ? 'border-indigo-600 text-indigo-600'
                    : 'border-transparent text-gray-700 hover:border-gray-300 hover:text-gray-800',
                  'whitespace-nowrap border-b-2 py-6 text-sm font-medium',
                ]"
              >
                Deliveries
              </button>
            </Tab>
            <Tab
              as="template"
              v-slot="{ selected }"
              v-if="currentOrder?.status != 'Internal'"
            >
              <button
                :class="[
                  selected
                    ? 'border-indigo-600 text-indigo-600'
                    : 'border-transparent text-gray-700 hover:border-gray-300 hover:text-gray-800',
                  'whitespace-nowrap border-b-2 py-6 text-sm font-medium',
                ]"
              >
                Chat
              </button>
            </Tab>
          </TabList>
        </div>
        <TabPanels as="template">
          <TabPanel as="dl" class="pt-10">
            <allocations-manager :orderId="parseInt($route.params.orderId)" />
          </TabPanel>
          <!-- <TabPanel class="pt-10">
            <allocations-table :orderId="parseInt($route.params.orderId)" />
          </TabPanel> -->
          <TabPanel as="dl" class="pt-10">
            <contract-form-details
              v-if="readyToReadOffer"
              v-model="currentOfferDetails"
              :readOnly="true"
            />
          </TabPanel>
          <TabPanel as="dl" class="pt-10">
            <contract-form-premiums
              v-if="readyToReadOffer"
              v-model="currentOfferPremiums"
              :cropType="currentOfferDetails.crop"
              :readOnly="true"
            />
          </TabPanel>
          <TabPanel as="dl" class="pt-10" :unmount="false">
            <view-attachments-table
              v-if="currentOrder?.status != 'Internal'"
              :attachments="currentOrder?.attachments"
            />
            <add-attachments-table
              v-else
              :parentId="currentOrder?.id"
              :attachments="currentOrder?.attachments"
              addStoreAction="uploadGrowerCropOrderAttachment"
              removeStoreAction="removeGrowerCropOrderAttachment"
              ref="attachmentsTable"
            />
          </TabPanel>
          <TabPanel as="dl" class="pt-10">
            <crop-delivery-table
              :deliveries="currentOrderDeliveries"
              :orderId="parseInt(orderId)"
            ></crop-delivery-table>
          </TabPanel>
          <TabPanel
            as="dl"
            class="pt-10"
            v-if="currentOrder?.status != 'Internal'"
          >
            <chat-single-view :thread-id="currentOrder?.details?.threadId" />
          </TabPanel>
        </TabPanels>
      </TabGroup>
    </template>
  </page-card>
</template>

<script>
import { Tab, TabGroup, TabList, TabPanel, TabPanels } from "@headlessui/vue";
import CropOrderDisplay from "@/layouts/buyer-grower/crop-orders/CropOrderDisplay.vue";
// import CropPremiumDisplay from "@/layouts/buyer-grower/crop-orders/CropPremiumDisplay.vue";
import CropDeliveryTable from "@/layouts/grower-portal/sell/CropDeliveryTable.vue";
import InternalCropOrderForm from "@/layouts/grower-portal/sell/orders/InternalCropOrderForm.vue";
import AddAttachmentsTable from "@/layouts/common/attachments/AddAttachmentsTable.vue";
import ViewAttachmentsTable from "@/layouts/common/attachments/ViewAttachmentsTable.vue";
import AllocationAggregate from "@/layouts/grower-portal/sell/orders/AllocationAggregate.vue";
import DeliveryAggregate from "@/layouts/grower-portal/sell/orders/DeliveryAggregate.vue";
import CropHarvestAggregate from "@/layouts/common/orders/CropHarvestAggregate.vue";
// import AllocationsTable from "@/layouts/grower-portal/sell/orders/AllocationsTable.vue";
import AllocationsManager from "@/layouts/grower-portal/sell/orders/AllocationsManager.vue";
import PageCard from "@/components/cards/PageCard.vue";
import BaseButton from "@/components/buttons/BaseButton.vue";
import DeleteModal from "@/components/modals/DeleteModal.vue";
import ChatSingleView from "@/layouts/common/ChatSingleView.vue";
import ContractFormDetails from "@/layouts/common/contracts/ContractFormDetails.vue";
import ContractFormPremiums from "@/layouts/common/contracts/ContractFormPremiums.vue";
export default {
  components: {
    Tab,
    TabGroup,
    TabList,
    TabPanel,
    TabPanels,
    PageCard,
    BaseButton,

    CropOrderDisplay,
    // CropPremiumDisplay,
    InternalCropOrderForm,
    AddAttachmentsTable,
    ViewAttachmentsTable,
    DeleteModal,
    // AllocationsTable,
    AllocationsManager,
    AllocationAggregate,
    DeliveryAggregate,
    CropHarvestAggregate,
    ChatSingleView,
    CropDeliveryTable,
    ContractFormDetails,
    ContractFormPremiums,
  },
  data() {
    // console.log("CROP ORDER");
    let selectedTab = 0;
    if (this.$route.query.tab) {
      selectedTab = Number(this.$route.query.tab);
    }
    return {
      modalIsActive: false,
      editProduceOrderFormId: "editProduceOrder",
      selectedTab,
      currentOfferDetails: {},
      currentOfferPremiums: [],
      currentOfferTerritory: {},
    };
  },
  computed: {
    orderId() {
      return parseInt(this.$route.params.orderId);
    },
    currentOrder() {
      return this.$store.getters.getGrowerCropOrderById(this.orderId);
      // let test = this.$store.getters.getGrowerCropOrderById(this.orderId);
      // console.log("currentOrder", test);
      // return test;
    },
    currentOrderDeliveries() {
      let deliveries =
        this.$store.state.growerDeliveries.growerDeliveries.filter(
          (d) => d.crop_order === parseInt(this.orderId),
        );
      return deliveries;
    },
    offerId() {
      return this.currentOrder?.offer;
    },
    currentOffer() {
      return this.$store.getters.getGrowerMarketOfferById(this.offerId);
    },
    readyToReadOffer() {
      if (
        this.offerId &&
        this.$store.getters.getGrowerMarketOfferById(this.offerId)
      ) {
        return true;
      } else {
        return false;
      }
    },
  },
  methods: {
    // Modal Methods
    intializeDeleteModal() {
      this.modalIsActive = true;
    },
    cancelDelete() {
      this.modalIsActive = false;
    },
    saveAllocations() {
      // console.log("form saved at edit order");
      this.$store.dispatch("saveWorkingAllocations", this.orderId);
      this.$router.push({ name: "grower-portal-produce-orders" });
    },
    saveAttachments() {
      // console.log("saveAttachments");
      this.$refs.attachmentsTable.saveAttachments();
    },

    async deleteProduceOrder() {
      this.modalIsActive = false;
      // console.log("DELETE");
      let allosToDelete = this.$store.getters.getGrowerAllocationsByOrderId(
        this.$route.params.orderId,
      );
      for (let allo of allosToDelete) {
        this.$store.dispatch("deleteGrowerAllocation", { id: allo.id });
      }
      await this.$store.dispatch("deleteGrowerCropOrder", {
        id: this.$route.params.orderId,
      });
      if (!this.$store.getters.getApiError) {
        this.$router.push({ name: "grower-portal-produce-orders" });
      }
    },
    tabChanged(index) {
      // console.log("tabChanged", index);
      this.selectedTab = index;
      // set the query param to the selected tab
      this.$router.push({
        query: { tab: index },
      });
    },
    readOffer() {
      if (!this.readyToReadOffer) {
        return null;
      }
      // set current contract details, premiums, and territory
      let currentOffer = this.$store.getters.getGrowerMarketOfferById(
        this.offerId,
      );
      this.currentOfferDetails = { ...currentOffer };
      this.currentOfferPremiums = currentOffer.premiums;
      this.currentOfferTerritory = currentOffer.territory;
      delete this.currentOfferDetails.premiums;
      delete this.currentOfferDetails.territory;
    },
  },
  watch: {
    orderId: {
      // handler(value) or handler(newValue, oldValue)
      handler() {
        this.readOffer();
      },
      // this will cause the handler to be run immediately on component creation
      immediate: true,
    },
    readyToReadOffer: {
      handler(value) {
        if (value && this.offerId) {
          this.readOffer();
        }
      },
      immediate: true,
    },
  },
};
</script>
