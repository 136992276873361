<template>
  <div>
    <div class="flex">
      <!-- Map Display here -->
      <div id="mapid" style="height: 75vh; width: 75vw" class="z-0"></div>
    </div>
  </div>
</template>

<script>
import "leaflet/dist/leaflet.css";
import L from "leaflet";
import "@/components/map/leaflet-geoman.css";
import "@geoman-io/leaflet-geoman-free";
// This is workaround code to make leaflet work with webpack.
delete L.Icon.Default.prototype._getIconUrl;
L.Icon.Default.mergeOptions({
  iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
  iconUrl: require("leaflet/dist/images/marker-icon.png"),
  shadowUrl: require("leaflet/dist/images/marker-shadow.png"),
});
// end of workaround

import * as turf from "@turf/turf";
import { exceptionHandler } from "@/api/parcel/api";

export default {
  props: {
    geojson: { type: Object, default: null },
  },
  data: function () {
    return {
      message: "initial",
      loading: false,
      access_token: process.env.VUE_APP_MAP_ACCESS_TOKEN,
      // map: {},
      draw: {},
      area: "",
      distance: "",
      selectedField: null,
    };
  },
  mounted() {
    this.createMap();
  },
  methods: {
    async createMap() {
      try {
        let componentHandle = this;
        // Create the map

        const map = new L.map("mapid");
        //this.map = map  disabling due to a bug in vue3 / leaflet

        // Initialise the FeatureGroup to store editable layers
        var editableLayers = new L.FeatureGroup();

        map.addLayer(editableLayers);

        // add the satellite base layer
        L.tileLayer(
          "https://api.mapbox.com/styles/v1/{id}/tiles/{z}/{x}/{y}?access_token={accessToken}",
          {
            attribution:
              'Map data &copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors, Imagery © <a href="https://www.mapbox.com/">Mapbox</a>',
            maxZoom: 18,
            id: "airrer1/ckqqvow3854p218l3d6lo43s5",
            tileSize: 512,
            zoomOffset: -1,
            accessToken: this.access_token,
          },
        ).addTo(map);

        if (this.geojson) {
          let geojson_points = this.geojson;

          // Add devices as individual markers in stead of geojson
          geojson_points.features.map((device) => {
            L.marker([
              device.geometry.coordinates[1],
              device.geometry.coordinates[0],
            ]).addTo(map); //NOTE - flipping coordinates to work with leaflet, opposite of MapBox
          });

          // let centroid = L.geoJson(geojson_points).getBounds().getCenter();
          // console.log(centroid);
          // map.setView(centroid, 5);
          map.fitBounds(L.geoJson(geojson_points).getBounds());
        } else {
          // show empty map location
          map.setView([37.655328357429305, -120.85592865943907], 6);
        }

        // Set Toolbar tooltips - can also set tooltips for drawing if needed
        const customTranslation = {
          tooltips: {},
          buttonTitles: {
            drawPolyButton: "Draw the Field",
            editButton: "Edit Field",
            cutButton: "Add Excluded Area in Field",
            deleteButton: "Remove a Field",
          },
        };

        map.pm.setLang("custom_en", customTranslation, "en");
        map.pm.setGlobalOptions({ snappable: false });
        // Track adding new field.
        map.on("pm:create", function (e) {
          // console.log("drew a field");
          //var type = e.shape;
          var layer = e.layer;
          // console.log(type);
          // console.log(layer);
          editableLayers.addLayer(layer);
          //TODO: Save new shape
          //var layers = L.PM.Utils.findLayers(map);
          //var group = L.featureGroup();
          //.forEach((layer) => {
          //  group.addLayer(layer);
          //});
          //let shape = group.toGeoJSON();
          let shape = editableLayers.toGeoJSON();
          let acres =
            Math.round(
              (turf.area(shape.features[0].geometry) / 4046.85642) * 100,
            ) / 100; // 4046.85642 is number of square meters in an acre. *100/100 is a hack to get two decimals
          // console.log(shape);
          componentHandle.$emit("edited", shape.features[0], acres);
        });
      } catch (err) {
        if (exceptionHandler) {
          exceptionHandler(err);
        }
        console.log("map error", err);
      }
    },
  },
  computed: {},
};
</script>
