import client from "@/api/parcel/api.js";
import _ from "lodash";

export default {
  state() {
    return {
      vendorProducts: [],
    };
  },
  mutations: {
    SET_VENDOR_PRODUCTS(state, vendorProductData) {
      state.vendorProducts = vendorProductData;
    },
    ADD_VENDOR_PRODUCT(state, vendorProductObject) {
      const result = state.vendorProducts.find(
        (vendorProduct) =>
          vendorProduct.id === parseInt(vendorProductObject.id),
      );
      if (!result) state.vendorProducts.push(vendorProductObject);
    },
    UPDATE_VENDOR_PRODUCT(state, vendorProductObject) {
      state.vendorProducts = state.vendorProducts.filter(function (obj) {
        return obj.id !== vendorProductObject.id;
      });
      state.vendorProducts.push(vendorProductObject);
    },
    DELETE_VENDOR_PRODUCT(state, vendorProductId) {
      state.vendorProducts = state.vendorProducts.filter(function (obj) {
        return obj.id !== parseInt(vendorProductId);
      });
    },
    RESET_VENDOR_PRODUCTS(state) {
      state.vendorProducts = [];
    },
  },
  actions: {
    async getVendorProducts({ commit }) {
      const { data, errors } = await client.vendor.services.read();
      if (errors) {
        console.log(errors);
      } else {
        // console.log("vendorProducts", data);
        commit("SET_VENDOR_PRODUCTS", data.services);
      }
    },
    async createVendorProduct({ commit, dispatch }, vendorProduct) {
      const { data, errors } = await client.vendor.services.create(
        null,
        vendorProduct,
      );
      if (errors) {
        console.log(errors, data);
        dispatch("catchParcelApiError", errors);
      } else {
        commit("ADD_VENDOR_PRODUCT", data);
        this.dispatch("getVendorProducts");
        return data;
      }
    },
    async updateVendorProduct({ commit, dispatch }, vendorProduct) {
      const { data, errors } = await client.vendor.services.update(
        vendorProduct.id,
        vendorProduct,
      );
      if (errors) {
        console.log(errors, data);
        dispatch("catchParcelApiError", errors);
      } else {
        commit("UPDATE_VENDOR_PRODUCT", data);
        await this.dispatch("getVendorProducts");
      }
    },
    async deleteVendorProduct({ commit, dispatch }, vendorProduct) {
      const { data, errors } = await client.vendor.services.delete(
        vendorProduct.id,
      );
      if (errors) {
        console.log(errors, data);
        dispatch("catchParcelApiError", errors);
      } else {
        // console.log(data);
        commit("DELETE_VENDOR_PRODUCT", vendorProduct);
        this.dispatch("getVendorProducts");
      }
    },
    async uploadVendorProductImage(
      { commit, dispatch, getters },
      vendorProductIdAndImage,
    ) {
      const { data, errors } = await client.vendor.services.createServiceImage(
        vendorProductIdAndImage.productId,
        vendorProductIdAndImage.file,
      );
      if (errors) {
        console.log(errors);
        dispatch("catchParcelApiError", errors);
      } else {
        let product = getters.getVendorProductById(
          vendorProductIdAndImage.productId,
        );
        product.image_url = data.image_url;
        commit("UPDATE_VENDOR_PRODUCT", product);

        return data;
        // await this.dispatch("getVendorProducts");
      }
    },
    async uploadVendorProductAttachment(
      { commit, dispatch, getters },
      productIdAndAttachment,
    ) {
      const { data, errors } = await client.vendor.services.createAttachment(
        productIdAndAttachment.parentId,
        productIdAndAttachment.file,
      );
      if (errors) {
        console.log(errors);
        dispatch("catchParcelApiError", errors);
      } else {
        let product = getters.getVendorProductById(
          productIdAndAttachment.parentId,
        );
        product.attachments.push(data);
        commit("UPDATE_VENDOR_PRODUCT", product);

        return data;
        // await this.dispatch("getVendorProducts");
      }
    },
    async removeVendorProductAttachment(
      { commit, dispatch, state },
      attachmentId,
    ) {
      let product = null;
      for (let listing of state.vendorProducts) {
        if (!listing?.attachments) {
          continue;
        }
        for (let attachment of listing.attachments) {
          if (attachment.attachment_id === attachmentId) {
            product = listing;
            break;
          }
        }
        if (product) {
          break;
        }
      }
      const { data, errors } = await client.vendor.services.removeAttachment(
        product.id,
        attachmentId,
      );
      if (errors) {
        console.log(errors);
        dispatch("catchParcelApiError", errors);
      } else {
        if (product) {
          product.attachments = product.attachments.filter(
            (a) => a.attachment_id !== attachmentId,
          );
          commit("UPDATE_VENDOR_PRODUCT", product);
        }
        return data;
        // await this.dispatch("getVendorProducts");
      }
    },
  },
  getters: {
    getVendorProductById: (state) => (vendorProductId) => {
      return state.vendorProducts.find(
        (vendorProduct) => vendorProduct.id === parseInt(vendorProductId),
      );
    },
    getVendorProductByTitle: (state) => (vendorProductTitle) => {
      return state.vendorProducts.find(
        (vendorProduct) => vendorProduct.title === parseInt(vendorProductTitle),
      );
    },
    getDerivedVendorProducts:
      (state) =>
      ({
        filter = {},
        match = "",
        sort = [],
        pageSize = null,
        pageNum = 0,
      }) => {
        let expandedVendors = state.vendorProducts;
        let filteredVendors = _.isEmpty(filter)
          ? expandedVendors
          : _.filter(expandedVendors, filter);
        let matchedVendors = _.isEmpty(match)
          ? filteredVendors
          : _.filter(filteredVendors, (item) =>
              _.some(item, (val) =>
                _.includes(_.lowerCase(val), _.lowerCase(match)),
              ),
            );
        let sortedVendors = _.isEmpty(sort)
          ? matchedVendors
          : _.sortBy(matchedVendors, sort);
        let paginatedVendors =
          _.isNumber(pageSize) && _.isNumber(pageNum)
            ? _.slice(
                sortedVendors,
                pageSize * pageNum, // skip
                pageSize * pageNum + pageSize, // limit
              )
            : sortedVendors;
        return paginatedVendors;
      },
  },
};
