<template>
  <TabGroup as="div" :selectedIndex="selectedTab" @change="tabChanged">
    <div class="border-b border-gray-200">
      <TabList class="-mb-px flex space-x-8">
        <Tab as="template" v-slot="{ selected }">
          <button
            :class="[
              selected
                ? 'border-indigo-600 text-indigo-600'
                : 'border-transparent text-gray-700 hover:border-gray-300 hover:text-gray-800',
              'whitespace-nowrap border-b-2 py-6 text-sm font-medium',
            ]"
          >
            User
          </button>
        </Tab>
        <Tab as="template" v-slot="{ selected }" v-if="userIsAdmin">
          <button
            :class="[
              selected
                ? 'border-indigo-600 text-indigo-600'
                : 'border-transparent text-gray-700 hover:border-gray-300 hover:text-gray-800',
              'whitespace-nowrap border-b-2 py-6 text-sm font-medium',
            ]"
          >
            Organization
          </button>
        </Tab>
        <Tab as="template" v-slot="{ selected }" v-if="userIsAdmin">
          <button
            :class="[
              selected
                ? 'border-indigo-600 text-indigo-600'
                : 'border-transparent text-gray-700 hover:border-gray-300 hover:text-gray-800',
              'whitespace-nowrap border-b-2 py-6 text-sm font-medium',
            ]"
          >
            Navigation
          </button>
        </Tab>
      </TabList>
    </div>
    <TabPanels as="template">
      <TabPanel as="dl" class="pt-10">
        <user-settings-form
          :isUserProfile="true"
          :notificationEvents="[
            { event: 'task_assigned', label: 'Task Assigned' },
            { event: 'chat_received', label: 'Chat Received' },
            {
              event: 'quote_request_received',
              label: 'Quote Request Received',
            },
            // { event: 'quote_response_received', label: 'Quote Response Received' },
            { event: 'order_request_received', label: 'Order Received' },
            // { event: 'order_response_received', label: 'Order Response Received' },
            { event: 'order_canceled', label: 'Order Canceled' },
            // {
            //   event: 'opportunity_received',
            //   label: 'Product Recommendation Received',
            // },
            // {
            //   event: 'recommendation_received',
            //   label: 'Activity Recommendation Received',
            // },
            // {
            //   event: 'scenario_created',
            //   label: 'Carbon Scenario Score Created',
            //   flag: 'gp-carbon',
            // },
            // { event: 'crop_order_created', label: 'Crop Order Created' },
            // {
            //   event: 'crop_order_premium_created',
            //   label: 'Premium Added to Crop Order',
            // },
          ]"
        />
      </TabPanel>
      <TabPanel class="pt-10" v-if="userIsAdmin">
        <org-settings-form
          routeUserNew="vendor-settings-new-user"
          routeExternalOrgNew="vendor-settings-new-external-org"
          routeUserEdit="vendor-single-user"
        />
      </TabPanel>
      <TabPanel class="pt-10" v-if="userIsAdmin">
        <navigation-settings-form />
      </TabPanel>
    </TabPanels>
  </TabGroup>
</template>

<script>
import { Tab, TabGroup, TabList, TabPanel, TabPanels } from "@headlessui/vue";
import UserSettingsForm from "@/layouts/common/UserSettingsForm.vue";
import OrgSettingsForm from "@/layouts/common/OrgSettingsForm.vue";
import NavigationSettingsForm from "@/layouts/vendor-portal/settings/VendorNavigationSettingsForm.vue";

export default {
  components: {
    UserSettingsForm,
    OrgSettingsForm,
    NavigationSettingsForm,
    Tab,
    TabGroup,
    TabList,
    TabPanel,
    TabPanels,
  },
  data() {
    console.log(this.$route.query);
    let selectedTab = 0;
    if (this.$route.query.tab) {
      selectedTab = Number(this.$route.query.tab);
    }
    return { selectedTab: selectedTab };
  },
  computed: {
    userIsAdmin() {
      return this.$store.getters.getUserAdminStatus;
    },
  },
  methods: {
    tabChanged(index) {
      // console.log("tabChanged", index);
      this.selectedTab = index;
      this.$router.push({
        query: { tab: index },
      });
    },
  },
};
</script>
