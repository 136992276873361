// IMPORTANT: Calling this component reqires the parent to call
saveAttachments() to save the attachments to the store
<template>
  <div class="mt-2"></div>
  <table-toolbar>
    <template #toolbarComponents>
      <div></div>
      <FileSelect
        buttonText="Add Attachment"
        @input="addFileToPendingAttachment"
        accept="*"
      />
    </template>
  </table-toolbar>
  <div
    v-if="attachments.length == 0 && pendingProductAttachments.length == 0"
    class="text-center"
  >
    <h3 class="mt-2 text-sm font-semibold text-gray-900">
      There's Nothing Here
    </h3>
    <p class="mt-1 text-sm text-gray-500">No attachments have been added.</p>
  </div>
  <base-table v-else>
    <template #headerCells>
      <table-header-cell :isLeadingCell="true" headerText="Attachment" />
      <table-header-cell
        :isEmptyHeader="true"
        headerText="Delete Attachments"
      />
      <table-header-cell :isEmptyHeader="true" headerText="View Attachments" />
      <!-- <table-header-cell :isEmptyHeader="true" headerText="Pending Action" /> -->
      <table-header-cell :isEmptyHeader="true" headerText="Pending Status" />
    </template>
    <template #bodyRows>
      <tr v-for="attachment in attachments" :key="attachment.attachment_url">
        <table-body-cell
          v-if="attachment.filename"
          :cellData="attachment.filename"
          :isLeadingCell="true"
        />
        <table-body-cell
          v-else
          :cellData="attachment.attachment_name"
          :isLeadingCell="true"
        />
        <table-body-cell
          :isButtonCell="true"
          :buttonDisplayText="
            attachment.owner == currentOrg.id && !isPendingDelete(attachment)
              ? 'Delete'
              : ''
          "
          @buttonCellClick="addFileToPendingAttachmentRemovals(attachment)"
        />
        <table-body-cell
          :isButtonCell="true"
          :buttonDisplayText="isPendingDelete(attachment) ? '' : 'View'"
          @buttonCellClick="openFileTab(attachment)"
        />
        <!-- <table-body-cell
          class="text-right"
          :cellData="isPendingDelete(attachment) ? '' : ''"
        /> -->
        <table-body-cell
          class="text-right"
          :cellData="
            isPendingDelete(attachment) ? 'Will Delete When Form is Saved' : ''
          "
        />
      </tr>
      <tr v-for="attachment in pendingProductAttachments" :key="attachment.url">
        <table-body-cell :cellData="attachment.name" :isLeadingCell="true" />
        <table-body-cell
          :isButtonCell="true"
          buttonDisplayText="Delete"
          @buttonCellClick="removeFromPendingAttachment(attachment)"
        />
        <table-body-cell
          :isButtonCell="true"
          buttonDisplayText=""
          @buttonCellClick="openFileTab(pendingAttachmentUrl(attachment))"
        />
        <!-- <table-body-cell
          class="whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6"
          cellData="Will Add"
        /> -->
        <table-body-cell
          class="text-right"
          cellData="Will Add When Form is Saved"
        />
      </tr>
    </template>
  </base-table>
</template>

<script>
import { nextTick } from "vue";
import BaseTable from "@/components/table/BaseTable.vue";
import TableHeaderCell from "@/components/table/TableHeaderCell.vue";
import TableBodyCell from "@/components/table/TableBodyCell.vue";
import TableToolbar from "@/components/table/TableToolbar.vue";
import FileSelect from "@/components/form/FileSelect.vue";

export default {
  props: {
    parentId: {
      type: [String, Number, null],
      required: true,
    },
    attachments: {
      type: Array,
      required: true,
    },
    addStoreAction: {
      type: String,
      required: true,
    },
    removeStoreAction: {
      type: String,
      required: true,
    },
  },
  components: {
    BaseTable,
    TableBodyCell,
    TableHeaderCell,
    TableToolbar,
    FileSelect,
  },
  data() {
    return {
      pendingProductAttachments: [],
      pendingProductAttachmentRemovals: [],
    };
  },
  computed: {
    currentOrg() {
      return this.$store.getters.getCurrentOrg;
    },
  },
  methods: {
    pendingAttachmentUrl(file) {
      return URL.createObjectURL(file);
    },
    addFileToPendingAttachment(file) {
      this.pendingProductAttachments.push(file);
    },
    addFileToPendingAttachmentRemovals(file) {
      this.pendingProductAttachmentRemovals.push(file);
    },
    openFileTab(attachment) {
      let url = null;
      if (attachment.attachment_url) {
        url = attachment.attachment_url;
      } else if (attachment.url) {
        url = attachment.url;
      }
      window.open(url, "_blank");
    },
    isAttachmentOwner(attachment) {
      return attachment.owner == this.currentOrg.id;
    },
    removeFromPendingAttachment(attachment) {
      this.pendingProductAttachments = this.pendingProductAttachments.filter(
        (file) => file !== attachment,
      );
    },
    isPendingDelete(attachment) {
      return this.pendingProductAttachmentRemovals.includes(attachment);
    },
    isPendingAdd(attachment) {
      return this.pendingProductAttachments.includes(attachment);
    },
    // Called by parent component to save attachments once the parent component is saved
    async saveAttachments() {
      nextTick(async () => {
        // Wait for the next tick to ensure the props are updated (parentId)
        if (this.parentId == null) {
          console.error("No parent ID provided for attachments");
          return;
        }
        for (let attachment of this.pendingProductAttachments) {
          await this.$store.dispatch(this.addStoreAction, {
            parentId: this.parentId,
            file: attachment,
          });
        }
        for (let attachment of this.pendingProductAttachmentRemovals) {
          await this.$store.dispatch(this.removeStoreAction, attachment.id);
        }
        this.pendingProductAttachments = [];
        this.pendingProductAttachmentRemovals = [];
      });
    },
  },
};
</script>
