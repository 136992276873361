<template>
  <div v-if="scenario && scenario.scores">
    <div class="flex">
      <div class="w-full border text-gray-500">
        <h2
          class="pb-2 pt-2 text-xl text-gray-600"
          v-if="scenario && scenario.crop != 'other'"
        >
          <div class="pb-2 font-bold">
            Your Carbon Intensity (CI) Score.
            <!-- <span class="text-xs"> based on R&D GREET Model (2023)</span> -->
          </div>

          CI Score<sup class="text-xs"> (1)</sup> :
          <span class="font-bold text-gray-900">{{
            scenario?.scores.ci_score
              ? scenario.scores.ci_score.toString().substring(0, 6)
              : "None"
          }}</span>
        </h2>
        <h2 class="pb-2 text-xl text-gray-600">
          Emissions per {{ yieldUnits }}:
          <span class="font-bold text-gray-900"
            >{{
              scenario?.scores.carbon_per_unit.toString().substring(0, 6)
            }}
            grams ({{
              (scenario?.scores.carbon_per_unit / 453.592)?.toFixed(1)
            }}
            lbs ) CO2e</span
          >
        </h2>
        <h2 class="pb-2 text-xl text-gray-600">
          Total Emissions:
          <span class="font-bold text-gray-900"
            >{{
              scenario?.scores.total_carbon.toString().substring(0, 6)
            }}
            metric tons C02e</span
          >
        </h2>
        <h2 class="pb-2 text-xl text-gray-600" v-if="taxValue > 0">
          Potential Tax Credit Value to Buyer*:
          <span class="font-bold text-gray-900"
            >${{ taxValue.toLocaleString() }}</span
          ><br />
          <span class="text-xs text-gray-500"
            >*Based on 2025 clean fuel production tax credit, see explanation
            tab for details</span
          >
        </h2>
      </div>
    </div>
  </div>
  <div v-else-if="reserveSpace" class="pb-24 pt-24">&nbsp;</div>
</template>
<script>
import { harvestUnits } from "@/components/composables/convertUnits.js";
export default {
  props: {
    scenario: {
      type: Object,
      required: true,
    },
    reserveSpace: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    yieldUnits() {
      let crop = this.scenario.crop;
      // console.log("scenario type", crop, this.scenario);
      if (!crop) {
        return "unit";
      }
      let units = harvestUnits(crop);

      // console.log("harvest units", units, crop);

      units = units.label.substring(0, units.label.length - 1);
      // console.log("yield units", units, crop);
      return units;
    },
    taxValue() {
      let tax_credit = 0;
      let crop = this.scenario.crop;
      let taxValue = 1.0; // $1 / gallon
      let gal_bushel = 2.77;
      if (crop == "corn") {
        let ethanol_emission = 27.376; // total emissions from feedstock in ethanol production
        let emission_rate =
          ((this.scenario.scores.ci_score / 1000) * 1000000) / 947.8;
        let emission_reduction = ethanol_emission - emission_rate;
        let emission_factor = (50 - (50 - emission_reduction)) / 50;
        tax_credit = emission_factor * taxValue * gal_bushel;
      }
      if (crop == "soybean") {
        let ethanol_emission = 29.536; // total emissions from feedstock in ethanol production
        let emission_rate =
          ((this.scenario.scores.ci_score / 1000) * 1000000) / 947.8;
        let emission_reduction = ethanol_emission - emission_rate;
        let emission_factor = (50 - (50 - emission_reduction)) / 50;
        tax_credit = emission_factor * taxValue * gal_bushel;
      }

      let total_credit =
        tax_credit *
        this.scenario.inputs.farm_size *
        this.scenario.inputs.crop_yield;
      total_credit = Math.round(total_credit * 100) / 100;
      return total_credit;
    },
  },
  methods: {},
};
</script>
