<template>
  <page-card headingTitle="New Contract">
    <template #buttonArea>
      <base-button
        v-if="currentStep > 1"
        buttonText="Back"
        :marginLeft="false"
        @buttonClick="handleStepChange(currentStep - 1)"
      />
      <base-button
        v-if="currentStep < 3"
        buttonText="Next"
        :marginLeft="true"
        @buttonClick="handleStepChange(currentStep + 1)"
      />
      <base-button
        v-if="currentStep == 3"
        buttonText="Save"
        :marginLeft="true"
        @buttonClick="createContract"
      />
    </template>
    <template #cardDetails>
      <div class="sm:col-span-6">
        <horizontal-form-steps :steps="newContractSteps" />
      </div>
      <contract-form-details
        v-if="currentStep == 1"
        v-model="currentContractDetails"
      />
      <contract-form-premiums
        v-if="currentStep == 2"
        v-model="currentContractPremiums"
        :cropType="currentContractDetails.crop"
      />
      <contract-form-territory
        v-if="currentStep == 3"
        v-model="currentContractTerritory"
      />
    </template>
  </page-card>
</template>

<script>
import PageCard from "@/components/cards/PageCard.vue";
import BaseButton from "@/components/buttons/BaseButton.vue";
import ContractFormDetails from "@/layouts/common/contracts/ContractFormDetails.vue";
import ContractFormPremiums from "@/layouts/common/contracts/ContractFormPremiums.vue";
import ContractFormTerritory from "@/layouts/common/contracts/ContractFormTerritory.vue";

import HorizontalFormSteps from "@/components/progress/HorizontalFormSteps.vue";
import { useToast } from "vue-toastification";

// import zod, use it to check if the required data is present
import { z } from "zod";

// define the contract schema, specifically the required attributes
// crop, quantity, quantity_units, contract_name, deliver_by, delivery_location
// are all required, and empty strings are not allowed. nonempty() has been deprecated, so use Use z.string().min(1) instead.
const contractSchema = z.object({
  crop: z.string().min(1),
  quantity: z.number().positive(),
  quantity_units: z.string().min(1),
  contract_name: z.string().min(1),
  deliver_by: z.string().min(1),
  // delivery_location: z.string().min(1),
});

export default {
  components: {
    PageCard,
    BaseButton,
    ContractFormDetails,
    ContractFormPremiums,
    ContractFormTerritory,
    HorizontalFormSteps,
  },

  data() {
    return {
      toast: useToast(),
      currentContractDetails: {
        crop: null,
        quantity: 0,
        quantity_units: null,
        contract_name: null,
        contract_description: null,
        program_name: null,
        program_year: null,
        deliver_by: null,
        delivery_location: null,
        elevator: null,
        payment_date: null,
        contract_partner_payment: null,
        contract_partner_payment_units: null,
        contract_total_value: null,
        client_name: null,
        client_email: null,
        additional_prior_year_records: 0,
      },
      currentContractPremiums: [],
      currentContractTerritory: {
        type: "MultiPolygon",
        coordinates: [],
      },
    };
  },
  computed: {
    currentStep() {
      if (this.$route.query.step > 3 || this.$route.query.step < 1) {
        this.handleStepChange(0);
      }
      return this.$route.query.step ? parseInt(this.$route.query.step) : 1;
    },
    newContractSteps() {
      return [
        {
          name: "Contract Details",
          status: this.stepStatus(1),
          id: 1,
        },
        {
          name: "Premiums",
          status: this.stepStatus(2),
          id: 2,
        },
        {
          name: "Territory",
          status: this.stepStatus(3),
          id: 3,
        },
      ];
    },
    contractForSubmission() {
      return {
        ...this.currentContractDetails,
        premiums: this.currentContractPremiums,
        territory: this.currentContractTerritory.coordinates.length
          ? this.currentContractTerritory
          : null,
      };
    },
  },
  methods: {
    handleStepChange(step) {
      this.$router.push({
        query: {
          step: step,
        },
      });
    },
    stepStatus(stepIndex) {
      if (stepIndex < this.currentStep) {
        return "complete";
      } else if (stepIndex == this.currentStep) {
        return "current";
      } else {
        return "inactive";
      }
    },
    async createContract() {
      // ensure the required data is present
      try {
        contractSchema.parse(this.contractForSubmission);
      } catch (error) {
        // log the error and show a toast message containing which part of the schema is missing
        console.error(error);
        // check which part of the schema is missing and show a toast message indicating the missing part
        let missingPart = error.errors[0].path[0];
        this.toast.error(`Missing required field: ${missingPart}`);
        return;
      }
      let newContract = await this.$store.dispatch(
        "createContract",
        this.contractForSubmission,
      );
      console.log("newContract", newContract);
      this.$router.push({
        name: "buyer-assignment-new",
        query: { contractId: newContract.id },
      });
    },
  },
  watch: {},
};
</script>
