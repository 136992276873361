<template>
  <slot-modal
    :open="modalIsActive"
    modalTitle="Respond to Offer"
    modalDescription="Submit your response to the interested buyer. Parcel will connect you with the buyer upon submission so that specific terms may be confirmed."
    @closeModal="cancelRequest"
  >
    <!-- <template #icon
      ><PaperAirplaneIcon class="text-black-600 h-6 w-6" aria-hidden="true"
    /></template> -->
    <template #content>
      <div
        class="mt-2 grid grid-cols-1 gap-x-4 gap-y-8 sm:col-span-6 sm:grid-cols-6"
      >
        <text-input
          :wideItem="true"
          inputTitle="Crop"
          inputHtmlType="text"
          :displayOnly="true"
          :displayOnlyValue="currentOffer.crop"
        />
        <number-input
          :wideItem="true"
          inputTitle="Buyer's Desired Quantity"
          inputHtmlType="text"
          :required="true"
          :displayOnly="true"
          :displayOnlyValue="currentOffer.quantity"
        />
        <text-input
          :wideItem="true"
          inputTitle="Order Units"
          inputHtmlType="text"
          :displayOnly="true"
          :displayOnlyValue="currentOffer.quantity_units"
        />
        <div class="hidden sm:col-span-3" />
        <number-input
          :wideItem="true"
          inputTitle="Quantity You'd Like to Sell'"
          inputHtmlType="text"
          :required="true"
          v-model="inquiryQuantity"
        />
        <div class="sm:col-span-6">
          <text-input
            :displayOnly="true"
            :displayOnlyValue="selectedFieldNames"
            inputTitle="Selected Fields"
            :required="true"
            class="mt-2 font-bold"
            help-text="You must select at least one field to open an inquiry"
          />
        </div>
        <div class="sm:col-span-6">
          <checkbox-input
            inputTitle="Show Field Select"
            v-model="showFieldSelect"
          />
        </div>
        <div v-if="showFieldSelect">
          <table-card>
            <template #header>
              <table-head
                :columns="[
                  { name: 'properties.farm', label: 'Farm' },
                  { name: 'properties.name', label: 'Field', filtered: false },
                  { name: 'properties.crop', label: 'Crop' },
                  { name: 'properties.details.variety', label: 'Variety' },
                  { name: 'properties.acres', label: 'Acres', filtered: false },
                ]"
                :collection="fields"
                @update:derived-collection="derivedFields = $event"
                selectionMode="Multiple"
                @update:selectedCollection="selectedFields = $event"
              >
                <!-- <template #toolbarButtons>
                <div class="hidden sm:flex">
                  <table-toolbar-button
                    buttonText="Apply Selected Acres"
                    :usePlusIcon="true"
                    @buttonClick="applySelectedAcres"
                  />
                </div>
              </template> -->
              </table-head>
            </template>
            <tbody>
              <tr v-for="field in derivedFields" :key="field">
                <table-data td-label="Selected">
                  <input
                    type="checkbox"
                    v-model="field.selected"
                    class="cursor-pointer rounded-md border-gray-400 text-indigo-600"
                    @change="
                      selectedFields = derivedFields.filter(
                        (field) => field.selected,
                      )
                    "
                  />
                </table-data>
                <table-data td-label="Farm">
                  {{ field.properties.farm }}
                </table-data>
                <table-data td-label="Field">
                  <div class="flex">
                    <field-thumbnail
                      :title="field.properties.name"
                      :geojson="field"
                      class="mr-6"
                    ></field-thumbnail>
                    {{ field.properties.name }}
                  </div>
                </table-data>
                <table-data td-label="Crop">
                  {{ field.properties.crop }}
                </table-data>
                <table-data td-label="Variety">
                  {{
                    field.properties?.details?.variety
                      ? field.properties?.details?.variety
                      : ""
                  }}
                </table-data>
                <table-data td-label="Acres">
                  {{ field.properties.acres }}
                </table-data>
              </tr>
            </tbody>
          </table-card>
        </div>
        <div class="sm:col-span-6">
          <CheckboxInput
            :wideItem="true"
            inputTitle="I confirm I will provide the crop and / or data as required in the terms if accepted"
            v-model="termsConfirmed"
            :required="true"
          />
        </div>
      </div>
    </template>
    <template #buttons
      ><base-button
        buttonText="Submit"
        @click="openOfferInquiry"
        :disabled="
          !inquiryQuantity || !selectedFields.length || !termsConfirmed
        "
      />
    </template>
  </slot-modal>
  <page-card headingTitle="View Offer to Buy">
    <template #buttonArea>
      <base-button
        buttonText="Open Inquiry"
        :marginLeft="false"
        @buttonClick="intializeRequestModal(offerId)"
        :disabled="!currentOffer"
      />
      <base-button
        buttonText="Back"
        @buttonClick="exitOffer"
        :redButton="true"
      />
    </template>
    <template #cardDetails>
      <text-input
        v-if="currentOffer"
        inputTitle="Buyer"
        :display-only="true"
        :display-only-value="currentOffer.affiliate_name"
        :wideItem="true"
      />
      <text-input
        v-if="currentOfferDetails?.contract_name"
        inputTitle="Contract Name"
        :display-only="true"
        :display-only-value="currentOfferDetails?.contract_name"
        :wideItem="true"
      />
    </template>
    <template #componentSection>
      <TabGroup as="div" :selectedIndex="selectedTab" @change="tabChanged">
        <div class="border-b border-gray-200">
          <TabList class="-mb-px flex space-x-8">
            <!-- <Tab as="template" v-slot="{ selected }">
              <button
                :class="[
                  selected
                    ? 'border-indigo-600 text-indigo-600'
                    : 'border-transparent text-gray-700 hover:border-gray-300 hover:text-gray-800',
                  'whitespace-nowrap border-b-2 py-6 text-sm font-medium',
                ]"
              >
                Summary
              </button>
            </Tab> -->
            <Tab as="template" v-slot="{ selected }">
              <button
                :class="[
                  selected
                    ? 'border-indigo-600 text-indigo-600'
                    : 'border-transparent text-gray-700 hover:border-gray-300 hover:text-gray-800',
                  'whitespace-nowrap border-b-2 py-6 text-sm font-medium',
                ]"
              >
                Details
              </button>
            </Tab>
            <Tab as="template" v-slot="{ selected }">
              <button
                :class="[
                  selected
                    ? 'border-indigo-600 text-indigo-600'
                    : 'border-transparent text-gray-700 hover:border-gray-300 hover:text-gray-800',
                  'whitespace-nowrap border-b-2 py-6 text-sm font-medium',
                ]"
              >
                Premiums
              </button>
            </Tab>
            <Tab as="template" v-slot="{ selected }">
              <button
                :class="[
                  selected
                    ? 'border-indigo-600 text-indigo-600'
                    : 'border-transparent text-gray-700 hover:border-gray-300 hover:text-gray-800',
                  'whitespace-nowrap border-b-2 py-6 text-sm font-medium',
                ]"
              >
                Territory
              </button>
            </Tab>
          </TabList>
        </div>
        <TabPanels as="template">
          <!-- <TabPanel as="dl" class="pt-10">
            <div class="-ml-6 sm:col-span-6">
              <offer-details :offerId="offerId" />
            </div>
            <p class="my-4">Inquiries</p>
            <inquiry-table :offerId="offerId" />
            <p class="my-4">Orders</p>
            <orders-table :offerId="offerId" />
          </TabPanel> -->
          <TabPanel as="dl" class="pt-10">
            <contract-form-details
              v-if="readyToReadOffer"
              v-model="currentOfferDetails"
              :readOnly="true"
            />
          </TabPanel>
          <TabPanel as="dl" class="pt-10">
            <contract-form-premiums
              v-if="readyToReadOffer"
              v-model="currentOfferPremiums"
              :cropType="currentOfferDetails.crop"
              :readOnly="true"
            />
          </TabPanel>
          <TabPanel as="dl" class="pt-10">
            <contract-form-territory
              v-if="readyToReadOffer"
              v-model="currentOfferTerritory"
              :readOnly="true"
            />
          </TabPanel>
        </TabPanels>
      </TabGroup>
    </template>
  </page-card>
</template>

<script>
import PageCard from "@/components/cards/PageCard.vue";
import BaseButton from "@/components/buttons/BaseButton.vue";
// import OfferForm from "@/layouts/grower-portal/sell/offers/OfferForm.vue";
import SlotModal from "@/components/modals/SlotModal.vue";
import TextInput from "@/components/form/TextInput.vue";
import NumberInput from "@/components/form/NumberInput.vue";
// import { PaperAirplaneIcon } from "@heroicons/vue/outline";
import { useToast } from "vue-toastification";
import CheckboxInput from "@/components/form/CheckboxInput.vue";
import { Tab, TabList, TabPanel, TabPanels, TabGroup } from "@headlessui/vue";
import ContractFormDetails from "@/layouts/common/contracts/ContractFormDetails.vue";
import ContractFormPremiums from "@/layouts/common/contracts/ContractFormPremiums.vue";
import ContractFormTerritory from "@/layouts/common/contracts/ContractFormTerritory.vue";
import FieldThumbnail from "@/components/map/FieldThumbnail.vue";
import TableHead from "@/components/table/TableHead.vue";
import TableCard from "@/components/table/TableCard.vue";
import TableData from "@/components/table/TableData.vue";

export default {
  components: {
    SlotModal,
    PageCard,
    BaseButton,
    // OfferForm,
    TextInput,
    NumberInput,
    // PaperAirplaneIcon,
    CheckboxInput,
    Tab,
    TabList,
    TabPanel,
    TabPanels,
    TabGroup,
    ContractFormDetails,
    ContractFormPremiums,
    ContractFormTerritory,
    FieldThumbnail,
    TableHead,
    TableCard,
    TableData,
  },
  data() {
    let selectedTab = 0;
    if (this.$route.query.tab) {
      selectedTab = Number(this.$route.query.tab);
    }
    return {
      modalIsActive: false,
      offerIdToRequest: null,
      inquiryQuantity: null,
      toast: useToast(),
      // Should we only allow them to submit an inquiry if the terms are confirmed?
      termsConfirmed: true,
      currentOfferDetails: {},
      currentOfferPremiums: [],
      currentOfferTerritory: {},
      derivedFields: [],
      selectedFields: [],
      showFieldSelect: true,
      selectedTab,
    };
  },
  computed: {
    offerId() {
      return this.$route.params.offerId;
    },
    currentOffer() {
      return this.$store.getters.getGrowerMarketOfferById(this.offerId);
    },
    readyToReadOffer() {
      if (
        this.offerId &&
        this.$store.getters.getGrowerMarketOfferById(this.offerId)
      ) {
        return true;
      } else {
        return false;
      }
    },
    fields() {
      return this.$store.getters.getAllFields;
    },
    selectedFieldNames() {
      return this.selectedFields
        .map((field) => field.properties.name)
        .join(", ");
    },
  },
  methods: {
    async openOfferInquiry() {
      if (!this.currentOffer) {
        await this.$store.dispatch("getGrowerMarketOffers");
      }
      // console.log("openOfferInquiry", this.currentOffer);
      if (this.inquiryQuantity === null) {
        this.toast.error("Please enter a quantity");
        return;
      }
      if (this.selectedFields.length == 0) {
        this.toast.error("Please enter one or more fields");
        return;
      }
      if (!this.termsConfirmed) {
        this.toast.error("Please confirm the terms");
        return;
      }
      let newThreadId = await this.$store.dispatch("createThread", {
        partner: this.currentOffer.org,
        subject: "Order Discussion ",
      });
      // console.log("newThreadId", newThreadId);
      let order = await this.$store.dispatch("createGrowerCropOrder", {
        offer: parseInt(this.offerId),
        quantity: this.inquiryQuantity,
        units: this.currentOffer.quantity_units,
        terms:
          // this.inquiryQuantity +
          // " " +
          // this.currentOffer.quantity_units +
          // " of " +
          // this.currentOffer.crop +
          // ". Additional Details: " +
          this.currentOffer.contract_description,
        details: {
          threadId: newThreadId,
          crop: this.currentOffer.crop,
        },
      });
      if (this.termsConfirmed) {
        await this.$store.dispatch("updateGrowerCropOrder", {
          id: order.id,
          grower_signature: true,
        });
      }
      // Create crop allocations against the selected fields for the order
      for (let field of this.selectedFields) {
        this.$store.dispatch("createGrowerAllocation", {
          crop_order: order.id,
          crop: field.properties.current_crop.id,
          acres: 0,
          notes: "Allocation created on the offer's inquiry submission",
        });
      }
      let messageContent =
        " We are interested in providing " +
        this.inquiryQuantity.toString() +
        " " +
        this.currentOffer.quantity_units +
        " of " +
        this.currentOffer.crop;
      let message = {
        content: messageContent,
      };
      this.$store.dispatch("createMessage", {
        threadId: newThreadId,
        message,
      });
      // Route to orders
      this.$router.push({ name: "grower-portal-produce-inquiries" });
      this.offerIdToRequest = undefined;
      this.modalIsActive = false;
    },
    intializeRequestModal(offerId) {
      this.modalIsActive = true;
      this.offerIdToRequest = offerId;
    },
    cancelRequest() {
      this.modalIsActive = false;
      this.offerIdToRequest = undefined;
    },
    exitOffer() {
      this.$router.push({ name: "grower-portal-market-offers" });
    },
    readOffer() {
      if (!this.readyToReadOffer) {
        return null;
      }
      // set current contract details, premiums, and territory
      let currentOffer = this.$store.getters.getGrowerMarketOfferById(
        this.offerId,
      );
      this.currentOfferDetails = { ...currentOffer };
      this.currentOfferPremiums = currentOffer.premiums;
      this.currentOfferTerritory = currentOffer.territory;
      delete this.currentOfferDetails.premiums;
      delete this.currentOfferDetails.territory;
      this.selectedFields = [];
    },
    tabChanged(index) {
      // console.log("tabChanged", index);
      this.selectedTab = index;
      this.$router.push({
        query: { tab: index },
      });
    },
  },
  watch: {
    offerId: {
      // handler(value) or handler(newValue, oldValue)
      handler() {
        this.readOffer();
      },
      // this will cause the handler to be run immediately on component creation
      immediate: true,
    },
    readyToReadOffer: {
      handler(value) {
        if (value && this.offerId) {
          this.readOffer();
        }
      },
      immediate: true,
    },
  },
};
</script>
