<!--
  USAGE:
  <template #header>
    <table-head
      :columns="[
        { name: 'name', label: 'Name' }, // default behavior
        { name: 'age', label: 'Age', filtered: false }, // disable filtering
        { name: 'job', label: 'Job', multipick: false }, // disable multipick filtering, only one value at a time
        { name: 'category', label: 'Category', sortFilterOptions: false }, // disable sorting of filter options in dropdown
        { name: 'category', label: 'Category', hidden: true }, // hide this column
      ]"
      :collection="people" // the collection to be filtered
      @update:derviedCollection="derivedPeople = $event" // Use this value for your loop in the tbody
      :defaultFilter="defaultFilter" // example: { name: ["Bob", "John"], age: [18, 20, 22] },
      :defaultSort="defaultSort"  // example: { name: "asc", age: "desc" },
      >
      <template #toolbarButtons>
        // put any buttons you want in the toolbar here
        <table-toolbar-button
         buttonText="New"
         :usePlusIcon="true"
         @buttonClick="showAlert('New')"
        />
      </template>
    </table-head>
  </template>
-->

<template>
  <thead class="sticky top-[122px] bg-gray-50">
    <tr class="hidden sm:table-row">
      <th
        v-if="selectionMode == 'Multiple'"
        class="border-gray-400 text-left sm:table-cell sm:border-b sm:border-t sm:py-2 sm:pl-3"
      >
        <input
          type="checkbox"
          class="cursor-pointer rounded-md border-gray-400 text-indigo-600"
          @click="toggleSelect"
          v-model="selectAll"
        />
      </th>
      <th
        v-for="(column, columnIdx) in displayedColumns"
        :key="column"
        class="block border-gray-400 sm:table-cell sm:border-b sm:border-t sm:py-2"
      >
        <!-- TODO change above sm:py-6 to be sm:py-2 to save space on toolbar once consistent use of table-head-->
        <div
          class="flex items-center px-3 text-left text-sm font-semibold text-gray-900"
        >
          <sortable-column
            v-if="column.sorted != false"
            :sorted="column.sorted != false ? true : false"
            :headerText="column.label"
            :sortColumns="pageSorts"
            :sortColumn="column.name"
            @sort="toggleSort"
          />
          <div class="flex pl-3" v-else>{{ column.label }}</div>

          <div class="hidden sm:table-cell">
            <Listbox v-if="filterOptions != [] && column.filtered != false">
              <ListboxButton
                class="ml-1 cursor-pointer rounded-lg border border-gray-300 p-1 text-left shadow-sm hover:bg-gray-100 focus:outline-none focus-visible:border-twilight-500 focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-orange-300 sm:text-sm print:hidden"
                :data-testid="`filter-button-${column.label}`"
              >
                <FilterIcon
                  class="hidden h-5 w-5 text-gray-400 sm:block"
                  aria-hidden="true"
                />
              </ListboxButton>
              <transition leave-active-class="transition duration-100 ease-in">
                <ListboxOptions
                  class="absolute z-50 max-h-60 w-2/12 overflow-auto rounded-md bg-white text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm"
                >
                  <ListboxOption
                    v-for="option in filterOptions[columnIdx]"
                    :key="option"
                    @click="selectFilterValue(option, columnIdx)"
                    as="template"
                  >
                    <li
                      class="relative cursor-default select-none py-2 pl-10 pr-4 text-gray-900 hover:bg-gray-100"
                    >
                      <span class="block truncate font-normal">{{
                        option
                      }}</span>
                    </li>
                  </ListboxOption>
                </ListboxOptions>
              </transition>
            </Listbox>
          </div>
        </div>
      </th>
    </tr>
  </thead>
  <!-- caption moved to bottom of template to change its css stack context order. Will still paint at the top-->
  <caption
    :colspan="displayedColumns.length"
    class="sticky top-0 caption-top print:hidden"
  >
    <table-toolbar v-if="toolbar">
      <template #toolbarComponents>
        <table-toolbar-search
          v-model="searchValue"
          @update:model-value="filterCollection"
        />
        <div class="flex">
          <slot name="toolbarButtons" />
        </div>
      </template>
      <template #activeFilters>
        <table-toolbar-active-filters
          :activeFilters="filterPredicatePretty"
          @clearFilter="clearSelectedFilter"
          :active="this.workingCollection?.length"
          :total="this.collection?.length"
        />
      </template>
    </table-toolbar>
    <!-- mobile filter-->
    <div class="sm:hidden">
      <div
        class="-mr-5 flex w-screen justify-end sm:mr-0 sm:hidden"
        v-if="$store.getters.getViewState == true"
      >
        <button type="button" @click="hideMobileFilter">
          <x-circle-icon class="mr-6 h-6 w-6" />
        </button>
      </div>
    </div>
    <div v-for="(column, columnIdx) in displayedColumns" :key="column">
      <div
        class="w-10/12 text-left sm:hidden"
        v-if="$store.getters.getViewState == true && column.filtered != false"
      >
        <SelectSearch
          :inputTitle="column.label"
          :wideItem="false"
          :selectOptions="filterOptions[columnIdx]"
          v-model="selectSearchIdAttr[columnIdx]"
          @onBlur="selectFilterValue($event, columnIdx)"
        />
      </div>
    </div>
  </caption>
</template>
<script>
import { filter, flattenObj } from "@/components/composables/scriptUtils.js";
import SortableColumn from "@/components/table/SortableColumn.vue";
import SelectSearch from "@/components/form/SelectSearch.vue";
import TableToolbar from "@/components/table/TableToolbar.vue";
// import TableToolbarButton from "@/components/table/TableToolbarButton.vue";
import TableToolbarSearch from "@/components/table/TableToolbarSearch.vue";
import TableToolbarActiveFilters from "@/components/table/TableToolbarActiveFilters.vue";
import _ from "lodash";
import {
  Listbox,
  ListboxButton,
  ListboxOptions,
  ListboxOption,
} from "@headlessui/vue";
import { FilterIcon, XCircleIcon } from "@heroicons/vue/solid";

export default {
  components: {
    SortableColumn,
    SelectSearch,
    Listbox,
    ListboxButton,
    ListboxOptions,
    ListboxOption,
    FilterIcon,
    XCircleIcon,
    TableToolbar,
    // TableToolbarButton,
    TableToolbarSearch,
    TableToolbarActiveFilters,
  },
  props: {
    limit: {
      type: Number,
      default: 0,
    },
    columns: {
      type: Array,
      required: true,
    },
    collection: {
      type: Array,
      required: true,
    },
    defaultFilter: {
      type: Object,
      required: false,
      default: () => {
        return {};
      },
    },
    defaultSort: {
      type: Object,
      required: false,
      default: () => {
        return {};
      },
    },
    selectionMode: {
      type: String,
      required: false,
      default: null,
    },
    toolbar: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  emits: [
    "update:derivedCollection",
    "update:selectedCollection",
    "update:filter",
  ],
  data() {
    // console.log("filter in data()", this.defaultFilter);
    return {
      filterPredicate: this.defaultFilter,
      workingCollection: [],
      searchValue: "",
      pageFixedFilters: {},
      pageSorts: this.defaultSort,
      selectSearchIdAttr: [],
      selectAll: false,
    };
  },
  computed: {
    displayedColumns() {
      return this.columns.filter((column) => !column.hidden);
    },
    filterPredicatePretty() {
      let pretty = [];
      for (let key in this.filterPredicate) {
        if (Array.isArray(this.filterPredicate[key])) {
          // filter out null values, then join with commas
          let filter = this.filterPredicate[key].filter((n) => n).join(",");
          filter = filter.split(",").sort().join(", "); // sort alphabetically
          pretty.push(filter);
        } else {
          pretty.push(this.filterPredicate[key]);
        }
      }
      return pretty;
    },
    filterOptions() {
      let options = [];
      // first flatten the collection so we have access to filters that are nested
      let flatCollection = [];
      for (let i = 0; i < this.collection.length; i++) {
        flatCollection.push(flattenObj(this.collection[i]));
      }
      for (
        let columnIdx = 0;
        columnIdx < this.displayedColumns.length;
        columnIdx++
      ) {
        options[columnIdx] = [];
        for (let i = 0; i < flatCollection.length; i++) {
          let option = flatCollection[i][this.displayedColumns[columnIdx].name];
          // console.log("option", option, options);
          if (!options[columnIdx].includes(option)) {
            options[columnIdx].push(option);
          }
        }
        if (!(this.displayedColumns[columnIdx]?.sortFilterOptions == false)) {
          // console.log("sorting filter  options");
          options[columnIdx] = options[columnIdx].sort();
        }
      }
      return options;
    },
    filterViewState() {
      /* this is to show the filters on mobile when the filter button is pressed, and always show the column headers on desktop */
      return this.$store.getters.getViewState
        ? "block" // show the filters on mobile if filter button is pressed
        : "sm:table-header-group hidden"; // hide on mobile if viewsState is false, show on desktop as a table header group
    },
    viewFilterState() {
      return this.$store.getters.getViewState;
    },
    flatCollection() {
      let flatCollection = [];
      for (let i = 0; i < this.collection.length; i++) {
        flatCollection.push(flattenObj(this.collection[i], true));
      }
      return flatCollection;
    },
  },
  methods: {
    filterCollection() {
      // remove filter predicate if it is not a column in this table view
      let filterPredicate = {};
      for (let key in this.filterPredicate) {
        if (this.displayedColumns.map((column) => column.name).includes(key)) {
          filterPredicate[key] = this.filterPredicate[key];
        }
      }
      this.filterPredicate = filterPredicate;
      let newCollection = filter(this.flatCollection, this.filterPredicate);
      newCollection = _.isEmpty(this.searchValue)
        ? newCollection
        : _.filter(newCollection, (item) =>
            _.some(item, (val) =>
              _.includes(_.lowerCase(val), _.lowerCase(this.searchValue)),
            ),
          );
      this.workingCollection =
        this.limit > 0 ? _.take(newCollection, this.limit) : newCollection;
      // console.log("save filtered collection", this.filterPredicate);
      this.sortColumn(this.pageSorts);
    },
    clearSelectedFilter(e) {
      let filterKey = Object.keys(this.filterPredicate)[e];
      delete this.filterPredicate[filterKey];
      // console.log("clearSelectedFilter", e, this.filterPredicate);
      this.$emit("update:filter", this.filterPredicate);
      this.filterCollection();
    },
    selectFilterValue(filterValue, columnIdx) {
      if (
        !this.filterPredicate[this.displayedColumns[columnIdx].name] ||
        this.displayedColumns[columnIdx].multipick == false
      ) {
        this.filterPredicate[this.displayedColumns[columnIdx].name] = [];
      }
      if (
        !this.filterPredicate[this.displayedColumns[columnIdx].name].includes(
          filterValue,
        )
      ) {
        this.filterPredicate[this.displayedColumns[columnIdx].name].push(
          filterValue,
        );
      }
      // console.log(
      //   "set Filter in tablehead",
      //   this.filterPredicate,
      //   filterValue,
      //   columnIdx
      // );
      this.filterCollection();
      this.$emit("update:filter", this.filterPredicate);
    },
    toggleSort(order) {
      //   console.log("sort in tableheader cell", order);
      this.pageSorts = order;
      this.sortColumn(order);
    },
    toggleSelect() {
      this.selectAll = !this.selectAll;
      this.workingCollection.forEach((item) => {
        item.selected = this.selectAll;
      });
      this.$emit(
        "update:selectedCollection",
        this.workingCollection.filter((item) => item.selected),
      );
    },
    sortColumn(sort) {
      this.pageSorts = sort;
      // console.log("sortColumn", this.pageSorts, this.workingCollection);
      this.workingCollection = _.orderBy(
        this.workingCollection,
        Object.keys(this.pageSorts),
        Object.values(this.pageSorts),
      );
      this.$emit("update:derivedCollection", this.workingCollection);
    },
    hideMobileFilter() {
      this.$store.dispatch("hideView");
    },
  },
  mounted() {
    // console.log("mounted");
    this.filterCollection();
    this.sortColumn(this.defaultSort);
  },
  watch: {
    collection: {
      handler() {
        // console.log("collection changed");
        this.filterCollection();
      },
      deep: true,
    },
  },
};
</script>
