<template>
  <template v-if="$store.getters.getFieldById($route.params.fieldId) == null">
    <page-card headingTitle="Field could not be found."> </page-card>
  </template>
  <template v-else>
    <page-card
      :headingTitle="
        field.properties.farm +
          ' - ' +
          field.properties.name +
          '&nbsp;&nbsp;&nbsp;&nbsp;' +
          (field.properties?.details?.variety
            ? field.properties?.details?.variety
            : '') +
          ' ' +
          field.properties.crop || ' '
      "
    >
      <template #buttonArea>
        <base-button buttonText="Add Task" @buttonClick="addTask" />
        <base-button buttonText="Get Plan" @buttonClick="getPlan" />
        <base-button
          buttonText="Assign Plan To Fields"
          @buttonClick="assignPlan"
        />
        <base-button
          buttonText="Back"
          @buttonClick="exitPlan"
          :redButton="true"
        />
      </template>
      <template #cardDetails>
        <!-- timeline panel -->
        <div class="sm:col-span-3">
          <timeline
            :items="activitiesTimeline"
            @timeline-delete="intializeDeleteModal"
            @timeline-edit="editTask"
          />
        </div>
        <!-- actions panel -->
        <div class="hidden sm:col-span-3 sm:block">
          <div class="flex pt-4">
            <bookmark-icon class="h-8 w-8 fill-gray-500" />
            Create a plan for this field by adding tasks one by one using the
            'Add Task' button above.
          </div>
          <div class="flex pt-4">
            <bookmark-icon class="h-8 w-8 fill-gray-500" />You can also retrieve
            a plan from another field using the 'Get Plan' button above.
          </div>
          <div class="flex pt-4">
            <bookmark-icon class="h-8 w-8 fill-gray-500" />You can edit or
            remove a task using the controls on that task's description.
          </div>
          <div class="flex pt-4">
            <bookmark-icon class="h-8 w-8 fill-gray-500" />Once you complete the
            plan, you can also assign this plan to other fields.
          </div>
        </div>
      </template>
    </page-card>
  </template>
  <modal
    :show="showSelectedTask"
    dialogPanelClass="max-w-screen-lg"
    title="Task "
  >
    <plan-task
      :fieldId="field.id"
      :taskId="selectedTaskId"
      formId="task-modal"
      @closeModal="hideModal"
    />
  </modal>
  <modal :show="showGetPlan" title="Retrieve Plan ">
    <plan-retrieve :currentFieldId="field.id" @closeModal="hideModal" />
  </modal>
  <modal
    :show="showAssignPlan"
    title="Assign Plan"
    dialogPanelClass="max-w-sm sm:max-w-4xl"
  >
    <plan-assign :currentFieldId="field.id" @closeModal="hideModal" />
  </modal>
  <delete-modal
    :open="showDeleteModal"
    modalTitle="Delete Task"
    modalDescription="Are you sure you want to permanently delete this task?"
    @clickCancel="cancelDelete"
    @clickConfirmDelete="deleteTask"
  />
</template>

<script>
import PageCard from "@/components/cards/PageCard.vue";
// import ActivitiesTable from "@/layouts/grower-portal/operations/ActivitiesTable.vue";
import Timeline from "@/components/calendar/TimelineView.vue";
import BaseButton from "@/components/buttons/BaseButton.vue";
import PlanTask from "@/layouts/grower-portal/operations/PlanTask.vue";
import Modal from "@/components/modals/PopupModal.vue";
import DeleteModal from "@/components/modals/DeleteModal.vue";
import PlanRetrieve from "@/layouts/grower-portal/operations/PlanRetrieve.vue";
import PlanAssign from "@/layouts/grower-portal/operations/PlanAssign.vue";
// import SubmitButton from "@/components/buttons/SubmitButton.vue";
import { BookmarkIcon } from "@heroicons/vue/solid";
export default {
  components: {
    PageCard,
    // ActivitiesTable,
    BaseButton,
    // SubmitButton,
    Timeline,
    PlanTask,
    Modal,
    DeleteModal,
    PlanRetrieve,
    PlanAssign,
    BookmarkIcon,
  },
  data() {
    let field = this.$store.getters.getFieldById(this.$route.params.fieldId);
    console.log(field);
    return {
      modalIsActive: false,
      editFieldFormId: "editField",
      errorArray: new Array(),
      field,
      showSelectedTask: false,
      selectedTaskId: null,
      showGetPlan: false,
      showAssignPlan: false,
      showDeleteModal: false,
    };
  },
  computed: {
    activitiesTimeline() {
      let activities = this.$store.getters
        .getTasksByField(this.field.id)
        ?.sort((a, b) => a.deadline?.localeCompare(b.deadline));
      let activitiesTimeline = [];

      activities.forEach((activity) => {
        let today = new Date();
        let yesterday = new Date();
        yesterday.setDate(today.getDate() - 1);
        let nextWeek = new Date();
        nextWeek.setDate(today.getDate() + 7);
        //console.log("dates", today, yesterday, nextWeek);
        let timing = "current";
        if (!activity.deadline || activity.deadline == "1970-01-01") {
          timing = "undefined";
        } else if (new Date(activity.deadline) < yesterday) {
          timing = "past";
        } else if (new Date(activity.deadline) > nextWeek) {
          timing = "future";
        }
        // let icon = "ClipboardListIcon";
        // switch (activity.status) {
        //   case "Ready":
        //     icon = "BookmarkIcon";
        //     break;
        //   case "Assigned":
        //     icon = "PencilIcon";
        //     break;
        //   case "Complete":
        //     icon = "CheckCircleIcon";
        //     break;
        //   case "Recommended":
        //     icon = "BriefcaseIcon";
        //     break;
        // }
        let badge_label = "?";
        if (activity.deadline) {
          var timestamp = Date.parse(activity.deadline);
          var dateObj = new Date(timestamp);
          badge_label = dateObj.toLocaleString("en-US", { month: "short" });
          badge_label += "<br>" + dateObj.getDate();
        }

        activitiesTimeline.push({
          id: activity.id,
          title: activity.category,
          body: activity.instructions,
          created: activity.status,
          icon_status: timing,
          // icon: icon,
          badge_label: badge_label,
          controls: [
            {
              icon: "PencilIcon",
              method: "edit",
            },
            {
              icon: "TrashIcon",
              method: "delete",
            },
          ],
        });
      });
      return activitiesTimeline;
    },
  },
  methods: {
    hideModal() {
      // console.log("hide modal");
      this.showGetPlan = false;
      this.showAssignPlan = false;
      this.showSelectedTask = false;
    },
    addTask() {
      this.selectedTaskId = null;
      this.showSelectedTask = true;
    },
    editTask(item) {
      console.log("edit Task", item);
      this.selectedTaskId = item.id;
      this.showSelectedTask = true;
    },
    getPlan() {
      this.showGetPlan = true;
    },
    assignPlan() {
      this.showAssignPlan = true;
    },
    exitPlan() {
      this.$router.push({ name: "field-plans" });
    },
    // Modal Methods
    intializeDeleteModal(item) {
      this.selectedTaskId = item.id;
      this.showDeleteModal = true;
    },
    cancelDelete() {
      this.showDeleteModal = false;
    },
    async deleteTask() {
      this.showDeleteModal = false;
      console.log("DELETE");
      await this.$store.dispatch("deleteTask", {
        id: this.selectedTaskId,
      });
    },
  },
};
</script>
