<template>
  <div class="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-6">
    <text-input
      :wideItem="true"
      inputTitle="Contract Name"
      inputHtmlType="text"
      :display-only="true"
      :display-only-value="currentContract?.contract_name"
    />
    <text-input
      :wideItem="true"
      inputTitle="Crop"
      inputHtmlType="text"
      :display-only="true"
      :display-only-value="currentContract?.crop"
    />
    <number-input
      :wideItem="true"
      inputTitle="Contract Quantity"
      inputHtmlType="text"
      :display-only="true"
      :display-only-value="currentContract?.quantity"
    />
    <text-input
      :wideItem="true"
      inputTitle="Quantity Units (Bushels, Bags, Tons, etc)"
      inputHtmlType="text"
      :display-only="true"
      :display-only-value="currentContract?.quantity_units"
    />

    <number-input
      :wideItem="true"
      inputTitle="Total Assigned Quantity (Est.)"
      inputHtmlType="text"
      :display-only="true"
      :display-only-value="assignedQty"
    />
    <number-input
      :wideItem="true"
      inputTitle="Quantity Remaining to Assign"
      inputHtmlType="text"
      :display-only="true"
      :display-only-value="currentContract?.quantity - assignedQty"
    />
  </div>
</template>
<script>
import TextInput from "@/components/form/TextInput.vue";
import NumberInput from "@/components/form/NumberInput.vue";

export default {
  props: {
    contractId: {
      type: [Number, String],
      required: true,
    },
  },
  components: {
    TextInput,
    NumberInput,
  },
  computed: {
    currentContract() {
      return this.$store.getters.getContractById(this.contractId);
    },
    assignedQty() {
      let offers = this.$store.getters.getControllerMarketOffersByContractId(
        this.contractId,
      );
      let totalQty = 0;
      for (let offer of offers) {
        totalQty += offer.quantity;
      }
      return totalQty;
    },
  },
  methods: {},
};
</script>
