<template>
  <page-card headingTitle="Users" v-if="userIsAdmin">
    <template #cardDetails>
      <div class="sm:col-span-6">
        <table-card>
          <template #header>
            <table-head
              :columns="[
                { name: 'name', label: 'User', filtered: false },
                { name: 'email', label: 'Email', filtered: false },
                { name: 'phone', label: 'Phone', filtered: false },
                { name: 'title', label: 'Title' },
                { name: 'actions', label: '', filtered: false },
              ]"
              :collection="users"
              @update:derived-collection="derivedUsers = $event"
            >
              <template #toolbarButtons>
                <div class="flex">
                  <table-toolbar-button
                    buttonText="New"
                    :usePlusIcon="true"
                    @buttonClick="goToNewUserPage"
                  />
                </div>
              </template>
            </table-head>
          </template>
          <tbody>
            <tr v-for="user in derivedUsers" :key="user.id">
              <table-data td-label="User">
                <div class="flex">
                  <Avatar :username="user.name" :src="user.image" />
                  {{ user.name }}
                </div>
              </table-data>
              <table-data td-label="Email">
                {{ user.email }}
              </table-data>
              <table-data td-label="Phone">
                {{ user.phone }}
              </table-data>
              <table-data td-label="Title">
                {{ user.title }}
              </table-data>
              <table-data class="text-center">
                <router-link
                  :to="{
                    name: routeUserEdit,
                    params: { userId: user.id },
                  }"
                  class="pl-3 font-medium text-violet-500 hover:text-twilight-900 sm:pr-6"
                >
                  Edit
                </router-link>
              </table-data>
            </tr>
          </tbody>
        </table-card>
      </div>
    </template>
  </page-card>
</template>

<script>
import PageCard from "@/components/cards/PageCard.vue";
// import BaseButton from "@/components/buttons/BaseButton.vue";
// import VariableStringTypeInput from "@/components/form/VariableStringTypeInput.vue";
// import EmailInput from "@/components/form/EmailInput.vue";
// import PhoneInput2 from "@/components/form/PhoneInput2.vue";
// import FileSelect from "@/components/form/FileSelect.vue";

import TableCard from "@/components/table/TableCard.vue";
import TableHead from "@/components/table/TableHead.vue";
import TableData from "@/components/table/TableData.vue";
import TableToolbarButton from "@/components/table/TableToolbarButton.vue";

import Avatar from "@/components/identity/UserAvatar.vue";
import { useToast } from "vue-toastification";

export default {
  components: {
    PageCard,
    // BaseButton,
    // VariableStringTypeInput,
    // EmailInput,
    // PhoneInput2,
    TableCard,
    TableHead,
    TableData,
    TableToolbarButton,
    Avatar,
    // FileSelect,
    // SelectSearch,
  },
  props: {
    routeUserNew: {
      type: String,
      required: true,
    },
    routeExternalOrgNew: {
      type: String,
      required: true,
    },
    routeUserEdit: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      derivedUsers: [],
      derivedExternalOrgs: [],
      derivedTokens: [],
      toast: useToast(),
      currentOrg: null,
    };
  },
  computed: {
    users() {
      return this.$store.state.users.users;
    },

    userIsAdmin() {
      return this.$store.getters.getUserAdminStatus;
    },
  },
  methods: {
    goToNewUserPage() {
      this.$router.push({ name: this.routeUserNew });
      // Form Methods
    },
    readOrgById(orgId) {
      this.currentOrg = this.$store.getters.getOrgById(orgId);
    },
    readCurrentOrg() {
      this.currentOrg = this.$store.getters.getCurrentOrg;
      if (this.currentOrg?.address) {
        this.currentOrg.addressObj = JSON.parse(this.currentOrg.address);
      } else {
        this.currentOrg.addressObj = {
          house_number: "",
          road: "",
          city: "",
          county: "",
          state: "",
          zip: "",
        };
      }
    },
  },

  watch: {
    orgId: {
      // handler(value) or handler(newValue, oldValue)
      handler(value) {
        // console.log("the fieldId param changed to ", value, " from ", oldValue)
        if (value) {
          this.readOrgById(value);
        } else {
          this.readCurrentOrg();
        }
      },
      // this will cause the handler to be run immediately on component creation
      immediate: true,
    },
  },
};
</script>

<style></style>
