<template>
  <page-card headingTitle="View Offer">
    <template #buttonArea>
      <base-button
        v-if="currentOffer && !currentOffer.published"
        buttonText="Publish"
        :marginLeft="false"
        @buttonClick="() => updatePublishedStatus(true)"
      />
      <base-button
        v-else-if="currentOffer && currentOffer.published"
        buttonText="Unpublish"
        :redButton="true"
        :marginLeft="false"
        @buttonClick="() => updatePublishedStatus(false)"
      />
    </template>
    <template #cardDetails>
      <text-input
        v-if="currentOriginator"
        inputTitle="Contract Originator"
        :display-only="true"
        :display-only-value="currentOriginator.originator_details.name"
        :wideItem="true"
      />
      <text-input
        v-if="currentOfferDetails?.contract_name"
        inputTitle="Contract Name"
        :display-only="true"
        :display-only-value="currentOfferDetails?.contract_name"
        :wideItem="true"
      />
    </template>
    <template #componentSection>
      <TabGroup as="div">
        <div class="border-b border-gray-200">
          <TabList class="-mb-px flex space-x-8">
            <Tab as="template" v-slot="{ selected }">
              <button
                :class="[
                  selected
                    ? 'border-indigo-600 text-indigo-600'
                    : 'border-transparent text-gray-700 hover:border-gray-300 hover:text-gray-800',
                  'whitespace-nowrap border-b-2 py-6 text-sm font-medium',
                ]"
              >
                Summary
              </button>
            </Tab>
            <Tab as="template" v-slot="{ selected }">
              <button
                :class="[
                  selected
                    ? 'border-indigo-600 text-indigo-600'
                    : 'border-transparent text-gray-700 hover:border-gray-300 hover:text-gray-800',
                  'whitespace-nowrap border-b-2 py-6 text-sm font-medium',
                ]"
              >
                Details
              </button>
            </Tab>
            <Tab as="template" v-slot="{ selected }">
              <button
                :class="[
                  selected
                    ? 'border-indigo-600 text-indigo-600'
                    : 'border-transparent text-gray-700 hover:border-gray-300 hover:text-gray-800',
                  'whitespace-nowrap border-b-2 py-6 text-sm font-medium',
                ]"
              >
                Premiums
              </button>
            </Tab>
            <Tab as="template" v-slot="{ selected }">
              <button
                :class="[
                  selected
                    ? 'border-indigo-600 text-indigo-600'
                    : 'border-transparent text-gray-700 hover:border-gray-300 hover:text-gray-800',
                  'whitespace-nowrap border-b-2 py-6 text-sm font-medium',
                ]"
              >
                Territory
              </button>
            </Tab>
          </TabList>
        </div>
        <TabPanels as="template">
          <TabPanel as="dl" class="pt-10">
            <div class="-ml-6 sm:col-span-6">
              <offer-details :offerId="offerId" />
            </div>
            <p class="my-4">Inquiries</p>
            <inquiry-table :offerId="offerId" />
            <p class="my-4">Orders</p>
            <orders-table :offerId="offerId" />
          </TabPanel>
          <TabPanel as="dl" class="pt-10">
            <contract-form-details
              v-if="readyToReadOffer"
              v-model="currentOfferDetails"
              :readOnly="true"
            />
          </TabPanel>
          <TabPanel as="dl" class="pt-10">
            <contract-form-premiums
              v-if="readyToReadOffer"
              v-model="currentOfferPremiums"
              :cropType="currentOfferDetails.crop"
              :readOnly="true"
            />
          </TabPanel>
          <TabPanel as="dl" class="pt-10">
            <contract-form-territory
              v-if="readyToReadOffer"
              v-model="currentOfferTerritory"
              :readOnly="true"
            />
          </TabPanel>
        </TabPanels>
      </TabGroup>
    </template>
  </page-card>
</template>

<script>
import PageCard from "@/components/cards/PageCard.vue";
import OfferDetails from "@/layouts/buyer-portal/bp-affiliate/offers/OfferDetails.vue";
import InquiryTable from "@/pages/buyer-portal/bp-affiliate/orders/InquiriesTable.vue";
import OrdersTable from "@/pages/buyer-portal/bp-affiliate/orders/OrdersTable.vue";
import TextInput from "@/components/form/TextInput.vue";
import BaseButton from "@/components/buttons/BaseButton.vue";
import { Tab, TabList, TabPanel, TabPanels, TabGroup } from "@headlessui/vue";
import ContractFormDetails from "@/layouts/common/contracts/ContractFormDetails.vue";
import ContractFormPremiums from "@/layouts/common/contracts/ContractFormPremiums.vue";
import ContractFormTerritory from "@/layouts/common/contracts/ContractFormTerritory.vue";

export default {
  components: {
    PageCard,
    OfferDetails,
    InquiryTable,
    OrdersTable,
    TextInput,
    BaseButton,
    ContractFormDetails,
    ContractFormPremiums,
    ContractFormTerritory,
    Tab,
    TabList,
    TabPanel,
    TabPanels,
    TabGroup,
  },
  data() {
    return {
      currentOfferDetails: {},
      currentOfferPremiums: [],
      currentOfferTerritory: {},
    };
  },
  computed: {
    offerId() {
      return this.$route.params.offerId;
    },
    currentOffer() {
      if (this.$store.getters.getBuyerMarketOfferById(this.offerId)) {
        return this.$store.getters.getBuyerMarketOfferById(this.offerId);
      } else {
        return null;
      }
    },
    originators() {
      if (this.$store.getters.getBuyerContractOriginators()) {
        return this.$store.getters.getBuyerContractOriginators();
      } else {
        return [];
      }
    },
    currentOriginator() {
      if (this.originators == []) return null;
      return this.originators.find(
        (originator) =>
          originator?.originator_details?.id === this.currentOffer?.assigned_by,
      );
    },
    readyToReadOffer() {
      if (
        this.offerId &&
        this.$store.getters.getBuyerMarketOfferById(this.offerId)
      ) {
        return true;
      } else {
        return false;
      }
    },
  },
  methods: {
    updatePublishedStatus(published) {
      this.$store.dispatch("updateBuyerMarketOffer", {
        id: this.offerId,
        published,
      });
    },
    readOffer() {
      if (!this.readyToReadOffer) {
        return null;
      }
      // set current contract details, premiums, and territory
      let currentOffer = this.$store.getters.getBuyerMarketOfferById(
        this.offerId,
      );
      this.currentOfferDetails = { ...currentOffer };
      this.currentOfferPremiums = currentOffer.premiums;
      this.currentOfferTerritory = currentOffer.territory;
      delete this.currentOfferDetails.premiums;
      delete this.currentOfferDetails.territory;
    },
  },
  watch: {
    offerId: {
      // handler(value) or handler(newValue, oldValue)
      handler() {
        this.readOffer();
      },
      // this will cause the handler to be run immediately on component creation
      immediate: true,
    },
    readyToReadOffer: {
      handler(value) {
        if (value && this.offerId) {
          this.readOffer();
        }
      },
      immediate: true,
    },
  },
};
</script>
