<template>
  <td
    v-if="isLeadingCell"
    scope="col"
    class="max-w-[18rem] truncate whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:max-w-sm sm:pl-6"
    :class="conditionalBodyCellStyles"
  >
    <button :class="pillStyle">
      {{ cellData }}
    </button>
  </td>
  <td
    v-else
    scope="col"
    class="max-w-sm truncate whitespace-nowrap px-3 py-4 text-sm text-gray-500"
    :class="conditionalBodyCellStyles"
  >
    <button :class="pillStyle">
      {{ cellData }}
    </button>
  </td>
</template>

<script>
export default {
  props: {
    cellData: {},
    hiddenOnSmallScreens: { type: Boolean, default: false },
    hiddenOnMediumAndSmallScreens: { type: Boolean, default: false },
    isLeadingCell: { type: Boolean, default: false }, // key column, so always shows
    isLinkCell: { type: Boolean, default: false }, // only for link column, so always shows
    linkDisplayText: { type: String },
    isButtonCell: { type: Boolean, default: false }, // only for link column, so always shows
    buttonDisplayText: { type: String },
    srOnlylinkDescription: { type: String, default: "Object" },
    routerLinkObject: { default: null },
    pillColorIndex: { type: Number, default: 0 },
  },
  emits: ["buttonCellClick"],
  computed: {
    conditionalBodyCellStyles() {
      let styles = {
        "sm:table-cell": this.hiddenOnSmallScreens,
        "lg:table-cell": this.hiddenOnMediumAndSmallScreens,
        hidden: this.hiddenOnSmallScreens || this.hiddenOnMediumAndSmallScreens, // hidden should occur if either hidden clause given
      };

      return styles;
    },
    pillStyle() {
      let textColor = "";
      let backgroundColor = "";
      switch (this.pillColorIndex) {
        case 0:
          textColor = "text-violet-900";
          backgroundColor = "bg-violet-100";
          break;
        case 1:
          textColor = "text-green-900";
          backgroundColor = "bg-green-100";
          break;
        case 2:
          textColor = "text-yellow-900";
          backgroundColor = "bg-yellow-100";
          break;
        case 3:
          textColor = "text-red-900";
          backgroundColor = "bg-red-100";
          break;
        case 4:
          textColor = "text-blue-900";
          backgroundColor = "bg-blue-100";
          break;
        case 5:
          textColor = "text-indigo-900";
          backgroundColor = "bg-indigo-100";
          break;
        case 6:
          textColor = "text-purple-900";
          backgroundColor = "bg-purple-100";
          break;
        case 7:
          textColor = "text-pink-900";
          backgroundColor = "bg-pink-100";
          break;
        default:
          textColor = "text-gray-900";
          backgroundColor = "bg-gray-100";
      }
      return (
        "rounded-full pl-4 pr-4 " +
        " " +
        textColor +
        " " +
        backgroundColor +
        " "
      );
    },
  },
  methods: {
    clickButtonCell() {
      this.$emit("buttonCellClick");
    },
  },
};
</script>
