import client from "@/api/parcel/api.js";
import { universalGetter } from "@/components/composables/universalGetter";
import {
  formatStatus,
  capitalize,
} from "@/components/composables/formatStringUtils.js";

export default {
  state() {
    return {
      vendorRecommendations: [],
    };
  },
  mutations: {
    SET_VENDOR_RECOMMENDATIONS(state, vendorRecommendationData) {
      state.vendorRecommendations = vendorRecommendationData;
    },
    ADD_VENDOR_RECOMMENDATION(state, vendorRecommendationObject) {
      const result = state.vendorRecommendations.find(
        (vendorRecommendation) =>
          vendorRecommendation.id === parseInt(vendorRecommendationObject.id),
      );
      if (!result) state.vendorRecommendations.push(vendorRecommendationObject);
    },
    UPDATE_VENDOR_RECOMMENDATION(state, vendorRecommendationObject) {
      state.vendorRecommendations = state.vendorRecommendations.filter(
        function (obj) {
          return obj.id !== vendorRecommendationObject.id;
        },
      );
      state.vendorRecommendations.push(vendorRecommendationObject);
    },
    RESET_VENDOR_RECOMMENDATIONS(state) {
      state.vendorRecommendations = [];
    },
  },
  actions: {
    async getVendorRecommendations({ commit }) {
      const { data, errors } =
        await client.vendor.serviceRecommendations.read();
      if (errors) {
        console.log(errors);
      } else {
        // console.log("vendorRecommendations", data);
        commit("SET_VENDOR_RECOMMENDATIONS", data.recommendations);
      }
    },
    async createVendorRecommendation(
      { commit, dispatch },
      vendorRecommendation,
    ) {
      const { data, errors } =
        await client.vendor.serviceRecommendations.create(
          null,
          vendorRecommendation,
        );
      if (errors) {
        console.log(errors, data);
        dispatch("catchParcelApiError", errors);
      } else {
        commit("ADD_VENDOR_RECOMMENDATION", data);
        this.dispatch("getVendorRecommendations");
        return data;
      }
    },
    async updateVendorRecommendation(
      { commit, dispatch },
      vendorRecommendation,
    ) {
      const { data, errors } =
        await client.vendor.serviceRecommendations.update(
          vendorRecommendation.id,
          vendorRecommendation,
        );
      if (errors) {
        console.log(errors, data);
        dispatch("catchParcelApiError", errors);
      } else {
        commit("UPDATE_VENDOR_RECOMMENDATION", data);
        this.dispatch("getVendorRecommendations");
      }
    },
  },
  getters: {
    // UNIVERSAL GETTER
    getVendorRecommendations:
      (state) =>
      ({
        filterFunction = null,
        filter = {},
        match = "",
        sort = [],
        pageSize = null,
        pageNum = 0,
      }) => {
        let vendorRecommendations = universalGetter(
          {
            filterFunction: filterFunction,
            filter: filter,
            match: match,
            sort: sort,
            pageSize: pageSize,
            pageNum: pageNum,
          },
          state.vendorRecommendations,
        );
        return vendorRecommendations;
      },
    getEnrichedVendorRecommendations:
      (state, rootGetters) =>
      ({
        filterFunction = null,
        filter = {},
        match = "",
        sort = [],
        pageSize = null,
        pageNum = 0,
      }) => {
        let agreements = rootGetters.getAllVendorQuotes;
        // console.log(agreements)
        let initalRecs = state.vendorRecommendations;
        initalRecs.forEach((rec) => {
          let agreement = agreements.find(
            (agreement) => agreement.id === parseInt(rec.agreement),
          );
          rec.agreement_object = agreement;
        });
        let enrichedRecs = initalRecs.map((rec) => ({
          ...rec,
          status: formatStatus(rec.status),
          type: capitalize(rec.type),
          service_title: rec.agreement_object?.service_title,
          buyer_name: rec.agreement_object?.buyer_details.name,
        }));
        // for rec in enrichedRecs delete rec.agreement_object
        let readyRecs = enrichedRecs.map((rec) => {
          delete rec.agreement_object;
          return rec;
        });
        let enrichedVendorRecommendations = universalGetter(
          {
            filterFunction: filterFunction,
            filter: filter,
            match: match,
            sort: sort,
            pageSize: pageSize,
            pageNum: pageNum,
          },
          readyRecs,
        );
        return enrichedVendorRecommendations;
      },
  },
};
