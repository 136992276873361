<template>
  <core-inputs
    :scenario-initial="scenario"
    :readOnly="readOnly"
    @updateScenarioInputs="updateScenarioInputs"
  />
  <!-- <div
    class="mt-2 text-left text-lg font-medium uppercase tracking-wider text-gray-500 sm:col-span-6"
  >
    Region
  </div> -->

  <select-search
    :wideItem="true"
    :helpText="`If outside the US, please pick 'Other' crop type. Corn is based on US data and lifecycle analysis`"
    inputTitle="Location State"
    v-model="currentInputs.location_state"
    :selectOptions="states"
    @on-blur="resetCounty"
    :display-only="readOnly"
    :display-only-value="currentInputs.location_state"
    :placeholder="scenario.defaults.location_state"
  />
  <select-search
    :wideItem="true"
    inputTitle="Location County"
    v-model="currentInputs.location_county"
    :selectOptions="counties(currentInputs.location_state)"
    @on-blur="emitUpdateScenarioInputs"
    :display-only="readOnly"
    :display-only-value="currentInputs.location_county"
  />
  <!-- <select-search
    :wideItem="true"
    inputTitle="Climate Zone"
    :selectOptions="[
      {
        label: 'No consideration',
        value: 'No consideration',
      },
      { label: 'Wet or Moist', value: 'Wet or Moist' },
    ]"
    v-model="currentInputs.climate_zone"
  /> -->

  <energy-inputs
    :scenario-initial="scenario"
    :readOnly="readOnly"
    @updateScenarioInputs="updateScenarioInputs"
  />

  <fertilizer-inputs
    :scenario-initial="scenario"
    :readOnly="readOnly"
    @updateScenarioInputs="updateScenarioInputs"
  />
  <cover-crop-inputs
    :scenario-initial="scenario"
    :readOnly="readOnly"
    @updateScenarioInputs="updateScenarioInputs"
  />
  <manure-inputs
    :scenario-initial="scenario"
    :readOnly="readOnly"
    @updateScenarioInputs="updateScenarioInputs"
  />
  <other-inputs
    :scenario-initial="scenario"
    :readOnly="readOnly"
    @updateScenarioInputs="updateScenarioInputs"
  />
</template>

<script>
import CoreInputs from "@/layouts/common/carbon/scenarioSectionInputs/CoreInputs.vue";
import EnergyInputs from "@/layouts/common/carbon/scenarioSectionInputs/EnergyInputs.vue";
import FertilizerInputs from "@/layouts/common/carbon/scenarioSectionInputs/FertilizerInputs.vue";
import CoverCropInputs from "@/layouts/common/carbon/scenarioSectionInputs/CoverCropInputs.vue";
import ManureInputs from "@/layouts/common/carbon/scenarioSectionInputs/ManureInputs.vue";
import OtherInputs from "@/layouts/common/carbon/scenarioSectionInputs/OtherInputs.vue";
// import TextInput from "@/components/form/TextInput.vue";
// import NumberInput from "@/components/form/NumberInput.vue";
// import SelectDropdown from "@/components/form/SelectDropdown.vue";
import SelectSearch from "@/components/form/SelectSearch.vue";
let stateList = require("@/assets/state_county.js");

export default {
  emits: ["updateScenarioInputs"],
  props: {
    scenarioInitial: {
      type: Object,
      default: null,
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    // TextInput,
    // NumberInput,
    // SelectDropdown,
    SelectSearch,
    CoreInputs,
    EnergyInputs,
    FertilizerInputs,
    CoverCropInputs,
    ManureInputs,
    OtherInputs,
  },
  data() {
    return {
      stateList: stateList,
      currentInputs: this.scenarioInitial?.inputs
        ? this.scenarioInitial.inputs
        : {
            farm_size: null,
            crop_yield: null,
            diesel: null,
            gasoline: null,
            natural_gas: null,
            liquefied_petroleum_gas: null,
            electricity: null,
            ammonia: null,
            urea: null,
            ammonium_nitrate: null,
            ammonium_sulfate: null,
            urea_ammonium_nitrate_solution: null,
            n_monoammonium_phosphate: null,
            n_diammonium_phosphate: null,
            p_monoammonium_phosphate: null,
            p_diammonium_phosphate: null,
            potash_fertilizer: null,
            lime: null,
            herbicide: null,
            insecticide: null,
            cover_crop_rotation: "Cover crop",
            cover_crop_farming_energy: null,
            cover_crop_herbicide_application_rate: null,
            cover_crop_yield: null,
            manure: "Manure",
            swine: null,
            dairy_cow: null,
            beef_cattle: null,
            chicken: null,
            manure_application_energy: null,
            manure_transportation_distance: null,
            manure_transportation_energy: null,
            tillage: "Conventional tillage",
            location_state: null,
            location_county: null,
            // climate_zone: "No consideration",
            technology_for_nitrogen_management: "Business as usual",
            source_of_ammonia: "Conventional",
          },
    };
  },
  computed: {
    scenario() {
      return this.scenarioInitial;
    },
    states() {
      let states = [];
      for (let state in this.stateList) {
        states.push(state);
      }
      return states;
    },
  },
  methods: {
    updateScenarioInputs(e) {
      this.currentInputs = e;
      this.$emit("updateScenarioInputs", this.currentInputs);
    },
    resetScenarioInputs() {
      this.currentInputs = {
        farm_size: null,
        crop_yield: null,
        diesel: null,
        gasoline: null,
        natural_gas: null,
        liquefied_petroleum_gas: null,
        electricity: null,
        ammonia: null,
        urea: null,
        ammonium_nitrate: null,
        ammonium_sulfate: null,
        urea_ammonium_nitrate_solution: null,
        n_monoammonium_phosphate: null,
        n_diammonium_phosphate: null,
        p_monoammonium_phosphate: null,
        p_diammonium_phosphate: null,
        potash_fertilizer: null,
        lime: null,
        herbicide: null,
        insecticide: null,
        cover_crop_rotation: "Cover crop",
        cover_crop_farming_energy: null,
        cover_crop_herbicide_application_rate: null,
        cover_crop_yield: null,
        manure: "Manure",
        swine: null,
        dairy_cow: null,
        beef_cattle: null,
        chicken: null,
        manure_application_energy: null,
        manure_transportation_distance: null,
        manure_transportation_energy: null,
        tillage: "Conventional tillage",
        location_state: null,
        location_county: null,
        // climate_zone: "No consideration",
        technology_for_nitrogen_management: "Business as usual",
        source_of_ammonia: "Conventional",
      };
    },
    counties(state) {
      let counties = [];
      if (state) {
        counties = Object.values(this.stateList[state]);
      }

      return counties;
    },
    resetCounty() {
      // console.log("resetCounty");
      this.currentInputs.location_county = null;
      this.emitUpdateScenarioInputs();
    },
    emitUpdateScenarioInputs() {
      // console.log("emittting UpdatedScenarioInputs");
      this.$emit("updateScenarioInputs", this.currentInputs);
    },
  },
  watch: {
    // currentInputs: {
    //   // handler(value) or handler(newValue, oldValue)
    //   handler(value) {
    //     this.$emit("updateScenarioInputs", value);
    //   },
    //   // this will cause the handler to be run immediately on component creation
    //   immediate: true,
    //   deep: true,
    // },
    scenarioInitial: {
      // handler(value) or handler(newValue, oldValue)
      handler(value) {
        this.currentInputs = value.inputs;
      },
      // this will cause the handler to be run immediately on component creation
      immediate: true,
      deep: true,
    },
  },
};
</script>
