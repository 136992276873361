import UserLogin from "@/pages/auth/UserLogin.vue";
import SignUp from "@/pages/auth/SignUp.vue";
import CreateOrg from "@/pages/auth/CreateOrg.vue";
import UserLogout from "@/pages/auth/UserLogout.vue";
// Start unnessary
import testForm from "@/components/form/testForm.vue";
import GrowerPortalFrame from "@/pages/grower-portal/GrowerPortalFrame";
import ErrorPage from "@/pages/auth/Error.vue";
// End unnessary

export default [
  {
    path: "/",
    name: "landing-page",
    meta: {
      forwardToAppIfLoggedIn: true,
      requiresAuth: true,
      title: "Parcel",
    },
  },
  {
    path: "/login",
    component: UserLogin,
    name: "login",
    meta: {
      forwardToAppIfLoggedIn: true,
      title: "Parcel",
    },
  },
  {
    path: "/signup",
    component: SignUp,
    name: "signup",
    meta: {
      forwardToAppIfLoggedIn: true,
      title: "Sign Up | Parcel",
    },
  },
  {
    path: "/create-org",
    component: CreateOrg,
    name: "create-org",
    meta: {
      requiresAuth: true,
      title: "Create Org | Parcel",
    },
  },
  {
    path: "/logout",
    name: "logout",
    component: UserLogout,
    meta: {
      requiresAuth: true,
      title: "Parcel",
    },
  },
  {
    path: "/error",
    component: ErrorPage,
    name: "error",
    meta: {
      title: "Oops!",
    },
  },
  {
    path: "/test",
    component: GrowerPortalFrame,
    children: [
      {
        path: "form",
        component: testForm,
        name: "testform",
        meta: {},
      },
    ],
  },
];
