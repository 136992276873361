<template>
  <core-inputs
    :scenario-initial="scenario"
    :readOnly="readOnly"
    @updateScenarioInputs="updateScenarioInputs"
  />
  <energy-inputs
    :scenario-initial="scenario"
    :readOnly="readOnly"
    @updateScenarioInputs="updateScenarioInputs"
  />

  <fertilizer-inputs
    :scenario-initial="scenario"
    :readOnly="readOnly"
    @updateScenarioInputs="updateScenarioInputs"
  />
  <other-inputs
    :scenario-initial="scenario"
    :readOnly="readOnly"
    @updateScenarioInputs="updateScenarioInputs"
  />
  <select-search
    :wideItem="true"
    inputTitle="Water Regime During Cultivation"
    :selectOptions="[
      { label: 'Continuously flooded', value: 'Continuously flooded' },
      { label: 'Single drainage period', value: 'Single drainage period' },
      {
        label: 'Multiple drainage periods',
        value: 'Multiple drainage periods',
      },
      { label: 'Regular rainfed', value: 'Regular rainfed' },
      { label: 'Drought prone', value: 'Drought prone' },
      { label: 'Deep water', value: 'Deep water' },
    ]"
    v-model="currentInputs.water_regime_during_cultivation"
    :placeholder="scenario.defaults.water_regime_during_cultivation"
    @on-blur="emitUpdateScenarioInputs"
    :display-only="readOnly"
    :display-only-value="currentInputs.water_regime_during_cultivation"
  />
  <select-search
    :wideItem="true"
    inputTitle="Water Regime In Preseason"
    :selectOptions="[
      {
        label: 'Non flooded pre-season <180 d',
        value: 'Non flooded pre-season <180 d',
      },
      {
        label: 'Non flooded pre-season >180 d',
        value: 'Non flooded pre-season >180 d',
      },
      {
        label: 'Flooded pre-season (>30 d)',
        value: 'Flooded pre-season (>30 d)',
      },
      {
        label: 'Non-flooded pre-season >365 d',
        value: 'Non-flooded pre-season >365 d',
      },
    ]"
    v-model="currentInputs.water_regime_in_preseason"
    :placeholder="scenario.defaults.water_regime_in_preseason"
    @on-blur="emitUpdateScenarioInputs"
    :display-only="readOnly"
    :display-only-value="currentInputs.water_regime_in_preseason"
  />
  <select-search
    :wideItem="true"
    inputTitle="Time for Straw Incorporation"
    :selectOptions="[
      {
        label: 'Straw incorporated shortly (<30 days) before cultivation',
        value: 'Straw incorporated shortly (<30 days) before cultivation',
      },
      {
        label: 'Straw incorporated long (>30 days) before cultivation',
        value: 'Straw incorporated long (>30 days) before cultivation',
      },
    ]"
    v-model="currentInputs.time_for_straw_incorporation"
    :placeholder="scenario.defaults.time_for_straw_incorporation"
    @on-blur="emitUpdateScenarioInputs"
    :display-only="readOnly"
    :display-only-value="currentInputs.time_for_straw_incorporation"
  />
</template>

<script>
import CoreInputs from "@/layouts/common/carbon/scenarioSectionInputs/CoreInputs.vue";
import EnergyInputs from "@/layouts/common/carbon/scenarioSectionInputs/EnergyInputs.vue";
import FertilizerInputs from "@/layouts/common/carbon/scenarioSectionInputs/FertilizerInputs.vue";
import OtherInputs from "@/layouts/common/carbon/scenarioSectionInputs/OtherInputs.vue";
// import NumberInput from "@/components/form/NumberInput.vue";
// import SelectDropdown from "@/components/form/SelectDropdown.vue";
import SelectSearch from "@/components/form/SelectSearch.vue";

export default {
  emits: ["updateScenarioInputs"],
  props: {
    scenarioInitial: {
      type: Object,
      default: null,
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    CoreInputs,
    EnergyInputs,
    FertilizerInputs,
    OtherInputs,

    // NumberInput,
    SelectSearch,
    // SelectDropdown,
  },
  data() {
    return {
      currentInputs: this.scenarioInitial?.inputs
        ? this.scenarioInitial.inputs
        : {
            farm_size: null,
            crop_yield: null,
            diesel: null,
            gasoline: null,
            natural_gas: null,
            liquefied_petroleum_gas: null,
            electricity: null,
            ammonia: null,
            urea: null,
            ammonium_nitrate: null,
            ammonium_sulfate: null,
            urea_ammonium_nitrate_solution: null,
            n_monoammonium_phosphate: null,
            n_diammonium_phosphate: null,
            p_monoammonium_phosphate: null,
            p_diammonium_phosphate: null,
            potash_fertilizer: null,
            lime: null,
            herbicide: null,
            insecticide: null,
            source_of_ammonia: "Conventional",
            water_regime_during_cultivation: "Continuously flooded",
            water_regime_in_preseason: "Non flooded pre-season <180 d",
            time_for_straw_incorporation:
              "Straw incorporated shortly (<30 days) before cultivation",
          },
    };
  },
  computed: {
    scenario() {
      return this.scenarioInitial;
    },
  },
  methods: {
    updateScenarioInputs(e) {
      this.currentInputs = e;
      this.$emit("updateScenarioInputs", this.currentInputs);
    },
    resetScenarioInputs() {
      this.currentInputs = {
        farm_size: null,
        crop_yield: null,
        diesel: null,
        gasoline: null,
        natural_gas: null,
        liquefied_petroleum_gas: null,
        electricity: null,
        ammonia: null,
        urea: null,
        ammonium_nitrate: null,
        ammonium_sulfate: null,
        urea_ammonium_nitrate_solution: null,
        n_monoammonium_phosphate: null,
        n_diammonium_phosphate: null,
        p_monoammonium_phosphate: null,
        p_diammonium_phosphate: null,
        potash_fertilizer: null,
        lime: null,
        herbicide: null,
        insecticide: null,
        source_of_ammonia: "Conventional",
        water_regime_during_cultivation: "Continuously flooded",
        water_regime_in_preseason: "Non flooded pre-season <180 d",
        time_for_straw_incorporation:
          "Straw incorporated shortly (<30 days) before cultivation",
      };
    },
    emitUpdateScenarioInputs() {
      // console.log("emittting UpdatedScenarioInputs");
      this.$emit("updateScenarioInputs", this.currentInputs);
    },
  },
  watch: {
    // currentInputs: {
    //   // handler(value) or handler(newValue, oldValue)
    //   handler(value) {
    //     this.$emit("updateScenarioInputs", value);
    //   },
    //   // this will cause the handler to be run immediately on component creation
    //   immediate: true,
    //   deep: true,
    // },
    scenarioInitial: {
      // handler(value) or handler(newValue, oldValue)
      handler(value) {
        this.currentInputs = value.inputs;
      },
      // this will cause the handler to be run immediately on component creation
      immediate: true,
      deep: true,
    },
  },
};
</script>
