<template>
  <div class="bg-white font-semibold uppercase">
    Field Level Carbon Emission Estimates
  </div>
  <table-card>
    <template #header>
      <table-head
        :columns="[
          { name: 'crop', label: 'Crop' },
          { name: 'farm', label: 'Farm' },
          { name: 'field', label: 'Field', filtered: false },
          { name: 'acres', label: 'Acres', filtered: false },
          { name: 'score', label: 'Score', filtered: false },
          { name: 'actions', label: '', filtered: false },
        ]"
        :collection="cropScenarios"
        @update:derived-collection="derivedFieldScenarios = $event"
        :default-filter="fieldScenariosDefaultFilter"
      >
        <template #toolbarButtons>
          <div class="flex">
            <table-toolbar-button
              v-if="orgHasActiveCarbonAgreement"
              buttonText="New Carbon Worksheet"
              :usePlusIcon="true"
              @buttonClick="goToNewScenarioPage"
            />
          </div>
        </template>
      </table-head>
    </template>
    <tbody>
      <tr v-for="s in derivedFieldScenarios" :key="s.id">
        <table-data td-label="Crop">
          {{ s.crop }}
        </table-data>
        <table-data td-label="Farm">
          {{ s.farm }}
        </table-data>
        <table-data td-label="Field">
          {{ s.field }}
        </table-data>
        <table-data td-label="Acres">
          {{ s.acres }}
        </table-data>
        <table-data td-label="Score">
          {{ s.ci_score?.toFixed(2) }}
        </table-data>
        <table-data class="text-center">
          <router-link
            :to="{
              name: carbonSingleRouteName,
              params: { scenarioId: s.scenarioId },
            }"
            class="pl-3 font-medium text-violet-500 hover:text-twilight-900 sm:pr-6"
            v-if="s.scenarioId"
          >
            Edit
          </router-link>
          <router-link
            :to="{
              name: 'carbon-new-scenario',
              query: { cropId: s.id },
            }"
            class="pl-3 font-medium text-violet-500 hover:text-twilight-900 sm:pr-6"
            v-else
          >
            New
          </router-link>
        </table-data>
      </tr>
    </tbody>
  </table-card>
  <div
    class="mt-6 bg-white font-semibold uppercase"
    v-if="otherScenarios.length > 0"
  >
    Carbon Emission Worksheets
    <div class="text-xs font-normal normal-case">
      Use these worksheets to test out different scenarios to see how they can
      affect your carbon score.
    </div>
  </div>
  <table-card v-if="otherScenarios.length > 0">
    <template #header>
      <table-head
        :columns="[
          { name: 'crop', label: 'Crop' },
          { name: 'name', label: 'Scenario Name', filtered: false },
          { name: 'inputs.farm_size', label: 'Acres', filtered: false },
          { name: 'scores.ci_score', label: 'Score', filtered: false },
          { name: 'actions', label: '', filtered: false },
        ]"
        :collection="otherScenarios"
        @update:derived-collection="derivedScenarios = $event"
      >
      </table-head>
    </template>
    <tbody>
      <tr v-for="s in derivedScenarios" :key="s.id">
        <table-data td-label="Crop">
          {{ s.crop }}
        </table-data>
        <table-data td-label="Scenario Name">
          {{ s.name }}
        </table-data>
        <table-data td-label="Acres">
          {{ s.inputs.farm_size }}
        </table-data>
        <table-data td-label="Score">
          {{ s.scores.ci_score?.toFixed(2) }}
        </table-data>
        <table-data class="text-center">
          <router-link
            :to="{
              name: carbonSingleRouteName,
              params: { scenarioId: s.id },
            }"
            class="pl-3 font-medium text-violet-500 hover:text-twilight-900 sm:pr-6"
          >
            Edit
          </router-link>
        </table-data>
      </tr>
    </tbody>
  </table-card>
  <!-- <div v-else>
      <div class="text-center">
        <h3 class="mt-2 text-sm font-semibold text-gray-900">No results</h3>
        <p class="mt-1 text-sm text-gray-500">Select a new tab</p>
      </div>
    </div> -->
</template>

<script>
import TableCard from "@/components/table/TableCard.vue";
import TableHead from "@/components/table/TableHead.vue";
import TableData from "@/components/table/TableData.vue";
import TableToolbarButton from "@/components/table/TableToolbarButton.vue";

export default {
  components: {
    TableCard,
    TableHead,
    TableData,
    TableToolbarButton,
  },
  props: {},
  data() {
    return {
      derivedScenarios: [],
      derivedFieldScenarios: [],
      activeCarbonAgreement: function (o) {
        if (o.service_category === "Carbon" && o.status === "buyer_confirmed") {
          return true;
        } else {
          return false;
        }
      },
      fieldScenariosDefaultFilter: {
        crop: ["Corn", "Soybeans", "Rice", "Sorghum", "Sugarcane"],
      },
    };
  },
  computed: {
    scenarios() {
      return this.$store.getters.getEnrichedGrowerScenarios({
        //filterFunction: this.tabFilters,
      });
    },
    otherScenarios() {
      let scenarios = this.scenarios.filter((s) => {
        return s.metadata?.crops.length == 0;
      });
      return scenarios;
    },
    cropScenarios() {
      let crops = this.$store.getters.getExpandedCrops({});
      let cropScenarios = [];
      for (let crop of crops) {
        let cropScenario = {
          id: crop.id,
          crop: crop.crop_type,
          field: crop.field,
          farm: crop.farm,
          acres: crop.acres,
          ci_score: 0,
          scenarioId: null,
        };
        switch (cropScenario.crop) {
          case "Corn":
            cropScenario.ci_score = 29.1;
            break;
          case "Soybeans":
            cropScenario.ci_score = 38.4;
            break;
          default:
            cropScenario.ci_score = 0;
        }
        // find any stored scenario for this crop id
        let scenario = this.scenarios.filter((s) => {
          return s.metadata?.crops[0] === crop.id;
        });
        // console.log("scenario match", scenario, this.scenarios, crop.id);
        if (scenario.length > 0) {
          cropScenario.scenarioId = scenario[0].id;
          cropScenario.ci_score = scenario[0].scores?.ci_score;
        }
        cropScenarios.push(cropScenario);
      }
      return cropScenarios;
    },
    carbonSingleRouteName() {
      return "carbon-single-scenario";
    },
    carbonNewRouteName() {
      return "carbon-new-worksheet";
    },
    orgHasActiveCarbonAgreement() {
      return true;

      // let agreements = this.$store.getters.getDerivedGrowerQuotes({
      //   filterFunction: this.activeCarbonAgreement,
      // });
      // if (agreements.length > 0) {
      //   return true;
      // } else {
      //   return false;
      // }
    },
  },
  methods: {
    goToNewScenarioPage() {
      this.$router.push({
        name: this.carbonNewRouteName,
      });
    },
  },
};
</script>
