<template>
  <modal :show="showModal" dialogPanelClass="max-w-screen-lg" :title="null">
    <record-form
      @saveRecord="storeRecord"
      @closeRecord="showModal = false"
      @cancelRecord="showModal = false"
      recordType="Cover Crop"
      v-if="modalType == 'record'"
    />
    <task-form
      :field-id="fieldId"
      :task-id="selectedTaskId"
      @closeModal="showModal = false"
      prepopulatedCategory="Cover Crop"
      v-if="modalType == 'task'"
    />
  </modal>

  <div
    class="mt-2 text-left text-lg font-medium uppercase tracking-wider text-gray-500 sm:col-span-6"
  >
    Cover Crop
  </div>

  <!-- show current cover crop records-->

  <div
    v-if="records && scenario.metadata?.crops?.length == 1"
    class="w-full text-sm sm:col-span-6"
  >
    <div
      class="mt-2 w-full border-separate border-spacing-2 rounded-lg border border-slate-300"
    >
      <div
        class="mx-2 my-2 grid auto-cols-max grid-cols-[45%_20%_15%_15%+5%] text-gray-500"
      >
        <div>Planting Date</div>
        <div>Fuel (gallons)</div>
        <div>Herbicide (g/acre)</div>
        <div>Yield (dry tons/acre)</div>
        <div></div>
        <template v-for="(record, index) in records" :key="index">
          <div class="">
            <span v-if="record.planned_or_actual == 'planned'">
              {{ record.deadline?.substring(0, 10) }} (Planned)</span
            >
            <span v-else> {{ record.payload?.cover_crop_planting_date }}</span>
          </div>
          <div class="">
            {{ record.payload?.diesel?.toFixed(2) }}
            <!-- ({{ record.payload.nitrogen }}-{{
              record.payload.phosphorus
            }}-{{ record.payload.potassium }}) -->
          </div>
          <div class="">
            {{ record.payload?.herbicide?.toFixed(2) }}
            <!-- ({{ record.payload.nitrogen }}-{{
              record.payload.phosphorus
            }}-{{ record.payload.potassium }}) -->
          </div>
          <div class="">
            {{ record.payload?.yield?.toFixed(2) }}
            <!-- ({{ record.payload.nitrogen }}-{{
              record.payload.phosphorus
            }}-{{ record.payload.potassium }}) -->
          </div>
          <div class="w-8 text-center">
            <span v-if="record.planned_or_actual == 'planned'">
              <a @click="editTask(record.taskId)"
                ><pencil-icon class="h-6 w-6 text-gray-500" /></a
            ></span>
          </div>
        </template>
        <!-- <div class="font-bold text-gray-800">
          <div>Total</div>
          <div></div>
          <div class="w-24 text-right">{{ totalNitrogen }}</div>
          <div class="w-24 text-right">{{ totalPhosphorus }}</div>
          <div class="w-24 text-right">{{ totalPotassium }}</div>
        </div> -->
      </div>
    </div>

    <div class="mt-2 flex justify-end">
      <base-button
        buttonText="+ Add Planned Cover Crop"
        :redButton="false"
        @buttonClick="addTask"
        v-if="!readOnly"
      />
      <base-button
        buttonText="+ Add Record"
        :redButton="false"
        @buttonClick="addRecord"
        v-if="!readOnly"
      />
    </div>
  </div>
  <template v-else>
    <select-search
      :wideItem="true"
      inputTitle="Cover Crop Rotation"
      :selectOptions="[
        {
          label: 'Cover crop',
          value: 'Cover crop',
        },
        {
          label: 'No cover crop',
          value: 'No cover crop',
        },
      ]"
      v-model="currentInputs.cover_crop_rotation"
      :placeholder="scenario.defaults.cover_crop_rotation"
      @on-blur="emitUpdateScenarioInputs"
      :display-only="readOnly"
      :display-only-value="currentInputs.cover_crop_rotation"
    />
    <number-input
      :wideItem="true"
      inputTitle="Cover Crop Farming Fuel (gallons)"
      inputHtmlType="text"
      v-model="currentInputs.cover_crop_farming_gallons"
      :placeholder="scenario.defaults.cover_crop_farming_gallons?.toFixed(2)"
      :helpText="`Fuel used for cover crop farming, do not include in total fuel used in the Energy section.`"
      @blur="emitUpdateScenarioInputs"
      :display-only="readOnly"
      :display-only-value="currentInputs.cover_crop_farming_gallons?.toFixed(2)"
      :nullIfEmpty="true"
    />
    <number-input
      :wideItem="true"
      inputTitle="Cover Crop Herbicide Application Rate (g/acre)"
      inputHtmlType="text"
      v-model="currentInputs.cover_crop_herbicide_application_rate"
      :placeholder="
        scenario.defaults.cover_crop_herbicide_application_rate?.toFixed(2)
      "
      @blur="emitUpdateScenarioInputs"
      :display-only="readOnly"
      :display-only-value="
        currentInputs.cover_crop_herbicide_application_rate?.toFixed(2)
      "
      :nullIfEmpty="true"
    />
    <number-input
      :wideItem="true"
      inputTitle="Cover Crop Yield (dry ton/acre)"
      inputHtmlType="text"
      v-model="currentInputs.cover_crop_yield"
      :placeholder="scenario.defaults.cover_crop_yield?.toFixed(2)"
      :helpText="`Report any crops removed from field, do not report residue left in field.`"
      @blur="emitUpdateScenarioInputs"
      :display-only="readOnly"
      :display-only-value="currentInputs.cover_crop_yield?.toFixed(2)"
      :nullIfEmpty="true"
    />
  </template>
</template>
<script>
import NumberInput from "@/components/form/NumberInput";
import SelectSearch from "@/components/form/SelectSearch";
import BaseButton from "@/components/buttons/BaseButton";
import { PencilIcon } from "@heroicons/vue/outline";
import Modal from "@/components/modals/PopupModal";
import RecordForm from "@/layouts/grower-portal/records/RecordForm.vue";
import TaskForm from "@/layouts/grower-portal/operations/PlanTask.vue";
export default {
  components: {
    NumberInput,
    SelectSearch,
    BaseButton,
    Modal,
    RecordForm,
    TaskForm,
    PencilIcon,
  },
  props: {
    scenarioInitial: {
      type: Object,
      required: true,
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["updateScenarioInputs"],
  data() {
    return {
      currentInputs: this.scenarioInitial?.inputs
        ? this.scenarioInitial.inputs
        : {
            cover_crop_rotation: "Cover crop",
            cover_crop_farming_energy: null,
            cover_crop_herbicide_application_rate: null,
            cover_crop_yield: null,
          },
      recordCache: [],
      showModal: false,
      selectedTaskId: null,
      modalType: null,
    };
  },
  computed: {
    scenario() {
      return this.scenarioInitial;
    },
    fieldId() {
      if (this.scenario.metadata?.crops?.length == 1) {
        let cropId = this.scenario.metadata.crops[0];
        let field = this.$store.getters.getFieldByCropId(cropId);
        return field.id;
      }
      return null;
    },
    records() {
      let records = [];
      let tasks = [];
      if (this.scenario.metadata?.crops?.length == 1) {
        records = this.$store.getters.getRecordsByCrop(
          this.scenario.metadata.crops[0],
        );
        if (records && records.length > 0) {
          records = records.filter((record) => {
            return record.category == "Cover Crop";
          });
        }
        // console.log("records loaded", records);
        tasks = this.$store.getters.getTasksByField(this.fieldId);
        if (tasks && tasks.length > 0) {
          tasks = tasks.filter((task) => {
            return task.category == "Cover Crop";
          });
          tasks = tasks.map((task) => {
            return {
              ...task.details,
              planned_or_actual: "planned",
              deadline: task.deadline,
              taskId: task.id,
            };
          });
          records = records.concat(tasks);
        }
      }
      return records;
    },
  },
  methods: {
    emitUpdateScenarioInputs() {
      this.$emit("updateScenarioInputs", this.currentInputs);
    },
    addRecord() {
      this.modalType = "record";
      this.showModal = true;
    },
    addTask() {
      this.selectedTaskId = null;
      this.modalType = "task";
      this.showModal = true;
    },
    editTask(taskId) {
      this.selectedTaskId = taskId;
      this.modalType = "task";
      this.showModal = true;
    },
    storeRecord(record) {
      record.field = null;
      record.category = "Cover Crop";
      record.crop = this.scenario.metadata.crops[0];
      this.$store.dispatch("createRecord", record);
      this.showModal = false;
    },
  },
  watch: {
    scenarioInitial: {
      // handler(value) or handler(newValue, oldValue)
      handler(value) {
        this.currentInputs = value.inputs;
      },
      // this will cause the handler to be run immediately on component creation
      immediate: true,
      deep: true,
    },
    records: {
      // handler(value) or handler(newValue, oldValue)
      handler(value) {
        if (!value) {
          return;
        }
        if (this.recordCache.length == value.length) {
          // no change
          return;
        }
        // console.log("records changed", value);
        value.length > 0
          ? (this.currentInputs.cover_crop_rotation = "Cover crop")
          : (this.currentInputs.cover_crop_rotation = "No cover crop");
        this.currentInputs.cover_crop_farming_gallons = 0;
        this.currentInputs.cover_crop_herbicide_application_rate = 0;
        this.currentInputs.cover_crop_yield = 0;
        for (let record of value) {
          record.payload.diesel
            ? (this.currentInputs.cover_crop_farming_gallons +=
                record.payload.diesel)
            : 0;
          record.payload.herbicide
            ? (this.currentInputs.cover_crop_herbicide_application_rate +=
                record.payload.herbicide)
            : 0;
          record.payload.yield
            ? (this.currentInputs.cover_crop_yield += record.payload.yield)
            : 0;
        }
        this.recordCache = value;
        this.emitUpdateScenarioInputs();
      },
      // this will cause the handler to be run immediately on component creation
      immediate: true,
      deep: true,
    },
  },
};
</script>
