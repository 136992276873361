<template>
  <div v-if="attachments.length === 0" class="text-center">
    <h3 class="mt-2 text-sm font-semibold text-gray-900">
      There's Nothing Here
    </h3>
    <p class="mt-1 text-sm text-gray-500">No attachments have been added.</p>
  </div>
  <base-table v-else>
    <template #headerCells>
      <table-header-cell :isLeadingCell="true" headerText="Attachment" />
      <table-header-cell :isEmptyHeader="true" headerText="View Attachments" />
    </template>
    <template #bodyRows>
      <tr v-for="attachment in attachments" :key="attachment.attachment_url">
        <table-body-cell
          v-if="attachment.filename"
          :cellData="attachment.filename"
          :isLeadingCell="true"
        />
        <table-body-cell
          v-else
          :cellData="attachment.attachment_name"
          :isLeadingCell="true"
        />
        <table-body-cell
          v-if="attachment.attachment_url"
          :isButtonCell="true"
          buttonDisplayText="View File"
          @buttonCellClick="openFileTab(attachment.attachment_url)"
        />
        <table-body-cell
          v-else
          :isButtonCell="true"
          buttonDisplayText="View File"
          @buttonCellClick="openFileTab(attachment.url)"
        />
      </tr>
    </template>
  </base-table>
</template>

<script>
import BaseTable from "@/components/table/BaseTable.vue";
import TableHeaderCell from "@/components/table/TableHeaderCell.vue";
import TableBodyCell from "@/components/table/TableBodyCell.vue";

export default {
  components: {
    BaseTable,
    TableHeaderCell,
    TableBodyCell,
  },
  props: {
    attachments: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {};
  },
  computed: {},
  methods: {
    openFileTab(url) {
      window.open(url, "_blank");
    },
  },
};
</script>
