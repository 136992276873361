<!-- This example requires Tailwind CSS v2.0+ -->
<template>
  <div class="mb-2">
    <h3 v-if="title" class="text-lg font-medium leading-6 text-gray-900">
      {{ title }}
    </h3>
    <dl v-if="title" class="mt-5 grid grid-cols-1 gap-5 sm:grid-cols-3">
      <div
        v-for="item in stats"
        :key="item.name"
        class="overflow-hidden rounded-lg bg-white px-4 py-5 shadow sm:p-6"
      >
        <dt class="truncate text-sm font-medium text-gray-500">
          {{ item.name }}
        </dt>
        <dd class="mt-1 text-xl font-semibold text-gray-900">
          <!-- If the value is a Number, use toFixed(2) to display 2 decimal places. Otherwise, display the value as is. -->
          {{ typeof item.stat === "number" ? item.stat.toFixed(2) : item.stat }}
        </dd>
      </div>
    </dl>
    <dl v-else class="grid grid-cols-1 gap-5 sm:grid-cols-3">
      <div
        v-for="item in stats"
        :key="item.name"
        class="overflow-hidden rounded-lg bg-white px-4 py-5 shadow sm:p-6"
      >
        <dt class="truncate text-sm font-medium text-gray-500">
          {{ item.name }}
        </dt>
        <dd class="mt-1 text-xl font-semibold text-gray-900">
          <!-- If the value is a Number, use toFixed(2) to display 2 decimal places. Otherwise, display the value as is. -->
          {{ typeof item.stat === "number" ? item.stat.toFixed(2) : item.stat }}
        </dd>
      </div>
    </dl>
  </div>
</template>

<script>
// const stats = [
//   { name: "Total Subscribers", stat: "71,897" },
//   { name: "Avg. Open Rate", stat: "58.16%" },
//   { name: "Avg. Click Rate", stat: "24.57%" },
// ];
export default {
  components: {},
  props: {
    title: {
      type: String,
      default: null,
    },
    stats: {
      default: () => [],
    },
  },
};
</script>
