<template>
  <form
    @submit.prevent="submitTaskForm"
    :id="formId"
    class="grid grid-cols-1 gap-x-4 gap-y-8 sm:col-span-6 sm:grid-cols-6"
  >
    <select-dropdown
      :wideItem="true"
      inputTitle="Category"
      v-model="currentTask.category"
      :selectOptions="taskTypes"
      :noSort="true"
      @on-blur="setCategory"
    />
    <select-search
      :wideItem="true"
      inputTitle="Equipment"
      v-model="currentTask.equipment"
      :selectOptions="equipmentList"
      v-if="
        currentTask.category == 'Equipment Maintenance' ||
        currentTask.category == 'Equipment Repair' ||
        currentTask.category == 'Other equipment task'
      "
    />
    <select-search
      :wideItem="true"
      inputTitle="Field"
      v-model="currentTask.field"
      :selectOptions="fieldList"
      v-else
    />
    <select-dropdown
      :wideItem="true"
      inputTitle="Status"
      :selectOptions="statusOptions"
      v-model="currentTask.status"
    />
    <div class="sm:col-span-3">
      <label class="block text-sm font-medium text-gray-700">Assigned To</label>
      <div class="mt-1">
        <select
          v-model="currentTask.user"
          id="user"
          name="user"
          class="mt-1 block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-twilight-500 focus:outline-none focus:ring-twilight-500 sm:text-sm"
        >
          <option v-for="u in allUsers" :key="u.id" :value="u.id">
            {{ u.name }}
          </option>

          <option v-if="allPartners.length > 0" :value="-1">
            __________________________
          </option>
          <option v-for="p in allPartners" :key="p.id" :value="'p' + p.id">
            {{ p.name }}
          </option>
        </select>
      </div>
    </div>
    <div class="sm:col-span-6">
      <text-area
        inputTitle="Instructions"
        v-model="currentTask.instructions"
        dataTestid="instructions"
      />
    </div>
    <div
      class="grid grid-cols-6 sm:col-span-6"
      v-if="currentTask.details?.category"
    >
      <record-form-layout
        @update:record="this.currentTask.details = $event"
        :recordProp="this.currentTask.details"
        :displayOnly="false"
        :recordType="this.currentTask.details.category"
      />
    </div>
    <div class="sm:col-span-6" v-if="currentTask.details?.more_details">
      <a
        target="_blank"
        :href="currentTask.details.more_details"
        class="whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium text-violet-500 hover:text-twilight-900 sm:pr-6"
      >
        Click here for more details...
      </a>
    </div>

    <div class="sm:col-span-3">
      <date-time-input inputTitle="Deadline" v-model="currentTask.deadline" />
    </div>
  </form>
</template>

<script>
import SelectDropdown from "@/components/form/SelectDropdown.vue";
import DateTimeInput from "@/components/form/DateTimeInput.vue";
import TextArea from "@/components/form/TextArea.vue";
import SelectSearch from "@/components/form/SelectSearch.vue";
// import BaseButton from "@/components/buttons/BaseButton.vue";
const RecordTypeSchemas = require("@/layouts/grower-portal/records/RecordTypes.json");
import RecordFormLayout from "@/layouts/grower-portal/records/RecordFormLayout.vue";

export default {
  props: {
    taskId: {
      type: Number,
      default: null,
    },
    // needed to allow a submit button outside the form tags to submit the form
    formId: {
      type: String,
      required: true,
    },
    prepopulatedStatus: {
      type: String,
      default: "Ready",
    },
  },
  emits: ["save"],

  components: {
    RecordFormLayout,
    SelectDropdown,
    DateTimeInput,
    TextArea,
    SelectSearch,
    // BaseButton,
  },
  data() {
    const statusOptions = [
      "Recommended",
      "Requested",
      "Ready",
      "Assigned",
      "InProcess",
      "Complete",
      "Canceled",
    ];
    let basicTaskTypes = [
      // { label: "Planting", value: "Planting" },
      // { label: "Tilling", value: "Tilling" },
      // { label: "Irrigation", value: "Irrigation" },
      // { label: "Input Application", value: "Input Application" },
      // { label: "Fertilzer Application", value: "Fertilzer Application" },
      { label: "Manual / Hand labor", value: "Manual / Hand labor" },
      { label: "Hauling", value: "Hauling" },
      { label: "Scouting", value: "Scouting" },
      // { label: "Observation", value: "Observation" },
      { label: "Other field task", value: "Other field task" },
      // { label: "Harvesting", value: "Harvesting" },
      // { label: "Maintenance", value: "Maintenance" },
      { label: "Equipment Repair", value: "Equipment Repair" },
      { label: "Other equipment task", value: "Other equipment task" },
    ];
    return {
      statusOptions,
      basicTaskTypes,
      currentTask: null,
      allFields: this.$store.state.fields.fields,
      allUsers: this.$store.state.users.users,
      allEquipment: this.$store.state.equipment.equipment,
    };
  },
  computed: {
    taskTypes() {
      let recordTypeSchemas = RecordTypeSchemas.filter(
        (recordTypeSchema) => !recordTypeSchema.readonly,
      );
      let filteredRecordTypeSchemas = recordTypeSchemas.filter(
        (recordTypeSchema) => recordTypeSchema.activity === "true",
      );
      recordTypeSchemas = filteredRecordTypeSchemas.map((recordTypeSchema) => {
        return {
          label: recordTypeSchema.category,
          value: recordTypeSchema.category,
        };
      });
      recordTypeSchemas.sort((a, b) => a.label.localeCompare(b.label));

      return recordTypeSchemas.concat(this.basicTaskTypes).sort((a, b) => {
        return a.label.localeCompare(b.label);
      });
    },
    fieldList() {
      return this.allFields.map((field) => {
        return { label: field.properties.name, value: field.id };
      });
    },
    equipmentList() {
      return this.allEquipment.map((asset) => {
        return { label: asset.alias, value: asset.id };
      });
    },
    allPartners() {
      let partners = [];
      this.$store.state.orgs.orgs.forEach((partner) => {
        // console.log("partner flags", partner.name, partner.services);
        if (
          partner.services.find((service) => service === "agronomic-advice")
        ) {
          // TODO: only show partners that have the work order feature flag vp-work-orders
          partners.push(partner);
        }
      });
      return partners;
    },
    assignedUserId() {
      return this.currentTask.user;
    },
  },
  methods: {
    setCategory() {
      if (this.currentTask.details == null) {
        this.currentTask.details = {};
      }
      if (
        // task only, no associated record type
        this.currentTask.category == "Manual / Hand labor" ||
        this.currentTask.category == "Hauling" ||
        this.currentTask.category == "Scouting" ||
        this.currentTask.category == "Other field task" ||
        this.currentTask.category == "Equipment Repair" ||
        this.currentTask.category == "Other equipment task"
      ) {
        this.currentTask.details.category == "";
      } else {
        this.currentTask.details.category = this.currentTask.category;
      }
    },
    submitTaskForm() {
      if (this.taskId) {
        this.updateTask();
      } else {
        this.createTask();
      }
    },
    // Form Methods
    readTask() {
      this.currentTask = this.$store.getters.getTaskById(this.taskId);
      this.currentTask.deadline = this.currentTask.deadline?.substring(0, 16);
      // check if it is assigned to a partner and then set the user to the partner org id
      if (this.currentTask.assigned_org) {
        this.currentTask.user = "p" + this.currentTask.assigned_org;
      }
    },
    async createTask() {
      this.currentTask.source = this.$store.getters.getCurrentUser.id;
      if (this.$store.getters.getIntegrationByType("trimble") !== undefined) {
        this.currentTask.integrations = {
          trimble: true,
        };
      }
      if (this.currentTask.user === -1) {
        this.currentTask.user = null;
      }
      if (this.currentTask.user && this.currentTask.user[0] === "p") {
        this.currentTask.org = this.currentTask.source_org =
          this.$store.getters.getCurrentUser.org;
        this.currentTask.assigned_org = parseInt(
          this.currentTask.user.substring(1),
        );
        this.currentTask.user = null;
        await this.$store.dispatch("createSharedTask", this.currentTask);
      } else {
        await this.$store.dispatch("createTask", this.currentTask);
      }
      this.$emit("save");
      this.$router.push({ name: "activities" });
    },
    updateTask() {
      if (this.currentTask.user === -1) {
        this.currentTask.user = null;
      }
      if (this.currentTask.user && this.currentTask.user[0] === "p") {
        this.currentTask.org = this.currentTask.source_org =
          this.$store.getters.getCurrentUser.org;

        this.currentTask.assigned_org = parseInt(
          this.currentTask.user.substring(1),
        );
        // console.log("assigned org", this.currentTask.assigned_org);
        this.currentTask.user = null;
        this.$store.dispatch("updateTask", {
          taskId: this.taskId,
          task: this.currentTask,
        });
      } else {
        this.currentTask.assigned_org = null;
        this.$store.dispatch("updateTask", {
          taskId: this.taskId,
          task: this.currentTask,
        });
      }
      this.$router.push({ name: "activities" });
    },
    clearForm() {
      this.currentTask = {
        category: "", // string
        instructions: "", // string
        status: null, // string - enum
        user: null, // int
        source: null, // int
        field: null, // int
        deadline: null, // string - datetime
      };
      this.currentTask.status = this.prepopulatedStatus;
    },
  },
  watch: {
    taskId: {
      // handler(value) or handler(newValue, oldValue)
      handler(value) {
        // console.log("the fieldId param changed to ", value, " from ", oldValue)
        if (value) {
          this.readTask();
        } else {
          this.clearForm();
        }
      },
      // this will cause the handler to be run immediately on component creation
      immediate: true,
    },
    assignedUserId: {
      handler(value) {
        if (
          (value != null && this.currentTask.status == "Ready") ||
          this.currentTask.status == "Recommended" ||
          this.currentTask.status == "Requested" ||
          this.currentTask.status == "Assigned"
        ) {
          if (value && value[0] == "p") {
            this.currentTask.status = "Requested";
          } else {
            this.currentTask.status = "Assigned";
          }
        }
      },
    },
  },
};
</script>
