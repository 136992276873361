<template>
  <!-- <carbon-scenario-form-single-crop
    onSubmitNavigationRouteName="carbon-single-scenario"
  /> -->
  <div
    class="grid grid-cols-1 gap-x-4 gap-y-8 bg-white px-6 shadow-sm sm:grid-cols-6 sm:rounded-b-lg"
  >
    <carbon-scenario-form
      onSubmitNavigationRouteName="carbon-single-scenario"
    />
  </div>
</template>

<script>
import CarbonScenarioForm from "@/layouts/common/carbon/CarbonScenarioForm.vue";

export default {
  components: {
    CarbonScenarioForm,
  },

  data() {
    return {};
  },
  computed: {},
  methods: {},
};
</script>
