<template>
  <TransitionRoot as="template" :show="sidebarOpen">
    <Dialog
      as="div"
      class="fixed inset-0 z-40 flex md:hidden"
      @close="sidebarOpen = false"
    >
      <TransitionChild
        as="template"
        enter="transition-opacity ease-linear duration-300"
        enter-from="opacity-0"
        enter-to="opacity-100"
        leave="transition-opacity ease-linear duration-300"
        leave-from="opacity-100"
        leave-to="opacity-0"
      >
        <DialogOverlay class="fixed inset-0 bg-gray-600 bg-opacity-75" />
      </TransitionChild>
      <TransitionChild
        as="template"
        enter="transition ease-in-out duration-300 transform"
        enter-from="-translate-x-full"
        enter-to="translate-x-0"
        leave="transition ease-in-out duration-300 transform"
        leave-from="translate-x-0"
        leave-to="-translate-x-full"
      >
        <div
          class="relative flex w-full max-w-xs flex-1 flex-col pb-4 pt-5"
          :class="barClass"
        >
          <TransitionChild
            as="template"
            enter="ease-in-out duration-300"
            enter-from="opacity-0"
            enter-to="opacity-100"
            leave="ease-in-out duration-300"
            leave-from="opacity-100"
            leave-to="opacity-0"
          >
            <div class="absolute right-0 top-0 -mr-12 pt-2">
              <button
                type="button"
                class="ml-1 flex h-10 w-10 items-center justify-center rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                @click="sidebarOpen = false"
              >
                <span class="sr-only">Close sidebar</span>
                <OutlineHeroIcon
                  name="XIcon"
                  classProp="w-6 h-6 text-white"
                  aria-hidden="true"
                />
              </button>
            </div>
          </TransitionChild>
          <div class="flex flex-shrink-0 items-center px-4">
            <router-link :to="{ name: homePath }" @click="sidebarOpen = false">
              <img class="h-16 w-auto" :src="logo" alt="Parcel" />
            </router-link>
            <!-- <p>{{ parcelLogoPath }}</p> -->
          </div>
          <div class="mt-5 h-0 flex-1 overflow-y-auto">
            <nav class="space-y-1 px-2">
              <!-- <h1
                  class="px-3 text-lg font-semibold tracking-wider text-white uppercase "
                  id="navigation-headline"
                >
                  {{ navigationMenu.appHeading }}
                </h1> -->
              <div
                v-for="navSection in navigationMenu.sections"
                :key="navSection"
              >
                <first-party-only
                  :required="navSection.firstPartyOnly ?? false"
                >
                  <feature-flagged :requires="navSection.featureFlagForAccess">
                    <div>
                      <h3
                        class="px-3 pt-3 text-xs font-semibold uppercase tracking-wider text-white"
                        id="projects-headline"
                      >
                        {{ navSection.sectionHeading }}
                      </h3>
                      <div class="mt-1"></div>
                      <div
                        v-for="navItem in navSection.sectionNavigation"
                        :key="navItem.name"
                      >
                        <first-party-only
                          :required="navItem.firstPartyOnly ?? false"
                        >
                          <feature-flagged
                            :requires="navItem.featureFlagForAccess"
                          >
                            <router-link
                              v-if="navItem.routeType !== 'external'"
                              :to="{ name: navItem.routeName }"
                              class="group flex items-center rounded-md px-2 py-2 text-sm font-medium text-twilight-100 hover:bg-twilight-600"
                              active-class="flex items-center px-2 py-2 text-sm font-medium text-white rounded-md bg-twilight-800 group"
                              @click="sidebarOpen = false"
                            >
                              <div v-if="navItem.img" class="flex">
                                <img
                                  :src="getImage(navItem.img)"
                                  class="mr-3 h-6 w-6 text-white"
                                />
                                {{ navItem.name }}
                              </div>
                              <div v-else class="flex">
                                <OutlineHeroIcon
                                  :name="navItem.icon"
                                  classProp="flex-shrink-0 w-6 h-6 mr-3 "
                                  aria-hidden="true"
                                />
                                {{ navItem.name }}
                              </div>
                            </router-link>
                            <a
                              v-else
                              :href="navItem.routeName"
                              target="_blank"
                              class="group flex items-center rounded-md px-2 py-2 text-sm font-medium text-twilight-100 hover:bg-twilight-600"
                            >
                              <div v-if="navItem.img" class="flex">
                                <img
                                  :src="getImage(navItem.img)"
                                  class="mr-3 h-6 w-6 text-white"
                                />
                                {{ navItem.name }}
                              </div>
                              <div v-else class="flex">
                                <OutlineHeroIcon
                                  :name="navItem.icon"
                                  classProp="flex-shrink-0 w-6 h-6 mr-3 "
                                  aria-hidden="true"
                                />
                                {{ navItem.name }}
                              </div>
                              <ExternalLinkIcon
                                class="right-0 mb-2 ml-1 h-4 w-4 flex-shrink-0"
                                aria-hidden="true"
                              />
                            </a>
                          </feature-flagged>
                        </first-party-only>
                      </div>
                    </div>
                  </feature-flagged>
                </first-party-only>
              </div>
            </nav>
          </div>
          <div class="flex flex-shrink-0 border-t border-gray-200 p-4">
            <router-link
              :to="{ name: settingsPath }"
              class="group block flex-shrink-0"
            >
              <div class="flex items-center">
                <div>
                  <Avatar
                    :username="currentUser.name"
                    :src="currentUser.image"
                  />
                </div>
                <div class="ml-3">
                  <p
                    class="text-base font-medium text-twilight-200 group-hover:text-twilight-100"
                  >
                    {{ currentUser.name }}
                  </p>
                  <p
                    class="text-sm font-medium text-twilight-300 group-hover:text-twilight-200"
                  >
                    {{ settingsDisplayText }}
                  </p>
                </div>
              </div>
            </router-link>
          </div>
        </div>
      </TransitionChild>
      <div class="w-14 flex-shrink-0" aria-hidden="true">
        <!-- Dummy element to force sidebar to shrink to fit close icon -->
      </div>
    </Dialog>
  </TransitionRoot>

  <!-- Static sidebar for desktop -->
  <div class="hidden md:flex md:flex-shrink-0 print:hidden" :class="barClass">
    <div class="flex w-64 flex-col">
      <!-- Sidebar component, swap this element with another sidebar if you like -->
      <div class="flex flex-grow flex-col overflow-y-auto pb-4 pt-5">
        <div class="flex flex-shrink-0 items-center px-4">
          <router-link :to="{ name: homePath }">
            <img class="h-16 w-auto" :src="logo" alt="Parcel" />
          </router-link>
          <div class="ml-2 font-bold">
            <router-link :to="{ name: orgPath }">
              {{ currentOrg?.name }}
              <div
                class="text-xs text-gray-400 hover:text-blue-600"
                v-if="sharedOrgs?.length > 1"
              >
                (switch org)
              </div>
            </router-link>
          </div>
        </div>
        <div class="mt-5 flex flex-1 flex-col">
          <nav class="flex-1 space-y-1 px-2">
            <!-- <h1
                class="px-3 text-lg font-semibold tracking-wider text-white uppercase "
                id="navigation-headline"
              >
                {{ navigationMenu.appHeading }}
              </h1> -->
            <div
              v-for="navSection in navigationMenu.sections"
              :key="navSection"
            >
              <first-party-only :required="navSection.firstPartyOnly ?? false">
                <feature-flagged :requires="navSection.featureFlagForAccess">
                  <div>
                    <h3
                      class="px-3 pt-3 text-xs font-semibold uppercase tracking-wider"
                      id="projects-headline"
                    >
                      <div class="flex" @click="toggleMenu(navSection)">
                        <ChevronRightIcon
                          :class="[
                            navSection?.expanded ? 'rotate-90 ' : 'rotate-0',
                            '-ml-4 mr-1 h-4 w-4 shrink-0',
                          ]"
                          aria-hidden="true"
                          v-if="navSection.expandable"
                        />
                        {{ navSection.sectionHeading }}
                      </div>
                    </h3>
                    <div v-if="navSection.expanded">
                      <div class="mt-1"></div>
                      <div
                        v-for="navItem in navSection.sectionNavigation"
                        :key="navItem.name"
                      >
                        <first-party-only
                          :required="navItem.firstPartyOnly ?? false"
                        >
                          <feature-flagged
                            v-if="!navItem.requiresAdmin || userIsAdmin"
                            :requires="navItem.featureFlagForAccess"
                          >
                            <button
                              v-if="navItem.routeType !== 'external'"
                              class="group flex items-center rounded-md px-2 py-2 text-sm font-medium hover:bg-twilight-600"
                              active-class="flex items-center px-2 py-2 text-sm font-medium text-white rounded-md bg-twilight-500 group"
                              :class="isActive(navItem)"
                              @click="menuClick(navItem, $event)"
                            >
                              <div v-if="navItem.img" class="flex">
                                <img
                                  :src="getImage(navItem.img)"
                                  class="mr-3 h-6 w-6 text-white"
                                />
                                {{ navItem.name }}
                              </div>
                              <div v-else class="flex">
                                <OutlineHeroIcon
                                  :name="navItem.icon"
                                  classProp="flex-shrink-0 w-6 h-6 mr-3 "
                                  aria-hidden="true"
                                />
                                {{ navItem.name }}
                                <div
                                  v-if="navItem.alerts?.count"
                                  class="ml-2 inline-flex items-center rounded-full px-2 text-xs font-medium"
                                  :class="navItem.alerts.color"
                                >
                                  {{ navItem.alerts.count }}
                                </div>
                              </div>
                            </button>
                            <a
                              v-else
                              :href="navItem.routeName"
                              target="_blank"
                              class="group flex items-center rounded-md px-2 py-2 text-sm font-medium hover:bg-twilight-600"
                            >
                              <div v-if="navItem.img" class="flex">
                                <img
                                  :src="getImage(navItem.img)"
                                  class="mr-3 h-6 w-6 text-white"
                                />
                                {{ navItem.name }}
                              </div>
                              <div v-else class="flex">
                                <OutlineHeroIcon
                                  :name="navItem.icon"
                                  classProp="flex-shrink-0 w-6 h-6 mr-3 "
                                  aria-hidden="true"
                                />
                                {{ navItem.name }}
                              </div>
                              <ExternalLinkIcon
                                class="right-0 mb-2 ml-1 h-4 w-4 flex-shrink-0"
                                aria-hidden="true"
                              />
                            </a>
                          </feature-flagged>
                        </first-party-only>
                      </div>
                    </div>
                  </div>
                </feature-flagged>
              </first-party-only>
            </div>
          </nav>
        </div>
      </div>
      <!-- Help Menu -->
      <feature-flagged requires="gp-help">
        <div class="px-2">
          <router-link
            :to="{ name: 'grower-portal-help' }"
            class="group flex items-center rounded-md px-2 py-2 text-sm font-medium hover:bg-twilight-600"
            active-class="flex items-center px-2 py-2 text-sm font-medium text-white rounded-md bg-twilight-500 group"
            @click="activeSelection = null"
          >
            <OutlineHeroIcon
              name="QuestionMarkCircleIcon"
              classProp="flex-shrink-0 w-6 h-6 mr-3 "
              aria-hidden="true"
            />
            Help
          </router-link>
        </div>
      </feature-flagged>
      <feature-flagged requires="vp-help">
        <div class="px-2">
          <router-link
            :to="{ name: 'vendor-portal-help' }"
            class="group flex items-center rounded-md px-2 py-2 text-sm font-medium hover:bg-twilight-600"
            active-class="flex items-center px-2 py-2 text-sm font-medium text-white rounded-md bg-twilight-500 group"
            @click="activeSelection = null"
          >
            <OutlineHeroIcon
              name="QuestionMarkCircleIcon"
              classProp="flex-shrink-0 w-6 h-6 mr-3 "
              aria-hidden="true"
            />
            Help
          </router-link>
        </div>
      </feature-flagged>
      <!-- Profile and Settings -->
      <div class="flex flex-shrink-0 border-t border-gray-200 p-4">
        <button
          @click="menuClick({ routeName: settingsPath }, $event)"
          class="group block flex-shrink-0"
        >
          <div class="flex items-center">
            <div>
              <Avatar :username="currentUser.name" :src="currentUser.image" />
            </div>
            <div class="ml-3">
              <p
                class="text-base font-medium text-twilight-200 group-hover:text-twilight-100"
              >
                {{ currentUser.name }}
              </p>
              <p
                class="text-sm font-medium text-twilight-300 group-hover:text-twilight-200"
              >
                {{ settingsDisplayText }}
              </p>
            </div>
          </div>
        </button>
      </div>
    </div>
  </div>
</template>
<script>
import OutlineHeroIcon from "@/components/icons/OutlineHeroIcon.vue";
import { ExternalLinkIcon, ChevronRightIcon } from "@heroicons/vue/outline";
import Avatar from "@/components/identity/UserAvatar.vue";
import FeatureFlagged from "@/components/containers/FeatureFlagged.vue";
import FirstPartyOnly from "@/components/containers/FirstPartyOnly.vue";

import {
  Dialog,
  DialogOverlay,
  TransitionChild,
  TransitionRoot,
} from "@headlessui/vue";

export default {
  components: {
    OutlineHeroIcon,
    Avatar,
    Dialog,
    DialogOverlay,
    TransitionChild,
    TransitionRoot,
    FeatureFlagged,
    ExternalLinkIcon,
    ChevronRightIcon,
    FirstPartyOnly,
  },
  props: {
    navigation: { type: Object },
    showSideBar: { type: Boolean },
    settingsPath: { type: String },
    homePath: { type: String },
    orgPath: { type: String },
    barTheme: { type: String, default: "bg-twilight-900 text-white" },
  },
  emits: ["sidebarOpen"],
  data() {
    return {
      navigationMenu: this.navigation,
      sidebarOpen: this.showSideBar,
      settingsDisplayText: "Profile and Settings",
      activeSelection: null,
    };
  },
  computed: {
    routeName() {
      return this.$route.name;
    },
    currentOrg() {
      let org = this.$store.getters.getCurrentOrg;
      if (!org) org = {};
      return org;
    },
    sharedOrgs() {
      return this.$store.state.sharedOrgs.sharedOrgs;
    },
    logo() {
      // default logo
      let logo = "/img/icons/parcel-logo.svg"; // "../../assets/parcel-logo.svg";
      // override with white label sponsor logo if a sponsor is set
      if (this.currentOrg.referrer) {
        logo = this.$store.getters.getSponsorById(
          this.currentOrg.referrer,
        ).logo_url;
      }
      // override with org logo if set
      if (this.currentOrg.logo_url) logo = this.currentOrg.logo_url;
      return logo;

      //return this.$store.getters.getCurrentOrgLogo;
    },
    barClass() {
      let barClass = this.barTheme;
      if (this.currentOrg.referrer) {
        let sponsor = this.$store.getters.getSponsorById(
          this.currentOrg.referrer,
        );
        barClass =
          "bg-[" +
          sponsor.growerMenuBgColor +
          "] text-[" +
          sponsor.growerMenuTextColor +
          "]";
      }
      return barClass;
    },
    currentUser() {
      return this.$store.getters.getCurrentUser;
      // let user = this.$store.getters.getCurrentUser;
      // if (!user) user={};
      // return user;
    },
    userIsAdmin() {
      return this.$store.getters.getUserAdminStatus;
    },
  },
  methods: {
    // dynamically import images from assets folder
    getImage(img) {
      return require(`../../assets/${img}`);
    },
    toggleMenu(navSection) {
      // console.log("NAV SECTION clicked", navSection);
      if (navSection?.expandable) navSection.expanded = !navSection.expanded;
    },
    isActive(navItem) {
      let activeClass = "";
      if (this.activeSelection === navItem) {
        activeClass = "bg-gray-200 bg-opacity-50 ";
      }
      return activeClass;
    },
    menuClick(navItem) {
      // console.log("NAV ITEM clicked", navItem);
      this.activeSelection = navItem;
      this.$router.push({ name: navItem.routeName });
    },
  },
  watch: {
    showSideBar() {
      this.sidebarOpen = this.showSideBar;
    },
    sidebarOpen() {
      this.$emit("sidebarOpen", this.sidebarOpen);
    },
    currentOrg() {
      // if the org changes, reset the navigation menu as we may have changed feature flags
      this.navigationMenu = this.navigation;
    },
    navigation: {
      // watch for changes to the alert counts
      handler: function (newVal) {
        // console.log("navigation update ", newVal);
        for (let i = 0; i < this.navigationMenu?.sections.length; i++) {
          for (
            let j = 0;
            j < this.navigationMenu.sections[i].sectionNavigation.length;
            j++
          ) {
            let item = this.navigationMenu.sections[i].sectionNavigation[j];
            if (item.alerts) {
              item.alerts.count =
                newVal.sections[i].sectionNavigation[j].alerts.count;
            }
          }
        }
      },
      deep: true,
    },
    routeName() {
      // console.log(
      //   "routeName",
      //   this.routeName,
      //   this.activeSelection,
      //   this.navigationMenu,
      // );
      for (let section in this.navigationMenu.sections) {
        for (let item in section.sectionNavigation) {
          if (item.routeName === this.routeName) {
            console.log("found active selection", item);
            this.activeSelection = item;
          }
        }
      }
    },
  },
};
</script>
