<template>
  <div>
    <span class="text-2xl">You have been successfully logged out</span>
    Redirecting you to the login page...
  </div>
</template>
<script>
export default {
  async mounted() {
    await this.$store.dispatch("logout").then(() => {
      this.$router.push({ path: "/login" });
    });
  },
};
</script>
