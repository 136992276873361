<template>
  <table-toolbar>
    <template #toolbarComponents>
      <div></div>
      <base-button
        buttonText="Add Delivery"
        @buttonClick="nagivateToAddDelivery"
      />
    </template>
  </table-toolbar>
  <div v-if="deliveries.length === 0" class="text-center">
    <h3 class="mt-2 text-sm font-semibold text-gray-900">
      There's Nothing Here
    </h3>
    <p class="mt-1 text-sm text-gray-500">No deliveries have been added.</p>
  </div>
  <base-table v-else>
    <template #headerCells>
      <table-header-cell :isLeadingCell="true" headerText="Delivery Location" />
      <table-header-cell :isLeadingCell="true" headerText="Quantity" />
      <table-header-cell :isLeadingCell="true" headerText="Units" />
      <table-header-cell :isLeadingCell="true" headerText="Attachments" />
      <table-header-cell :isEmptyHeader="true" headerText="View Delivery" />
    </template>
    <template #bodyRows>
      <tr v-for="delivery in deliveries" :key="delivery.id">
        <table-body-cell
          :cellData="delivery.delivery_location"
          :isLeadingCell="true"
        />
        <table-body-cell
          :cellData="delivery.quantity_delivered"
          :isLeadingCell="true"
        />
        <table-body-cell
          :cellData="delivery.quantity_units"
          :isLeadingCell="true"
        />
        <table-body-cell
          :cellData="delivery.attachments.length"
          :isLeadingCell="true"
        />
        <table-body-cell
          :isButtonCell="true"
          buttonDisplayText="View"
          @buttonCellClick="() => navigateDeliveryDetail(delivery.id)"
        />
      </tr>
    </template>
  </base-table>
</template>

<script>
import BaseTable from "@/components/table/BaseTable.vue";
import TableHeaderCell from "@/components/table/TableHeaderCell.vue";
import TableBodyCell from "@/components/table/TableBodyCell.vue";
import TableToolbar from "@/components/table/TableToolbar.vue";
import BaseButton from "@/components/buttons/BaseButton.vue";

export default {
  components: {
    BaseTable,
    TableHeaderCell,
    TableBodyCell,
    TableToolbar,
    BaseButton,
  },
  props: {
    deliveries: {
      type: Array,
      default: () => [],
    },
    orderId: {
      type: Number,
    },
  },
  data() {
    return {};
  },
  computed: {
    currentOrder() {
      return this.$store.getters.getGrowerCropOrderById(this.orderId);
    },
    currentOffer() {
      return this.$store.getters.getGrowerMarketOfferById(
        this.currentOrder.offer,
      );
    },
  },
  methods: {
    navigateDeliveryDetail(id) {
      this.$router.push({
        name: "grower-portal-produce-delivery",
        params: { deliveryId: id },
      });
    },
    nagivateToAddDelivery() {
      this.$router.push({
        name: "grower-portal-produce-delivery-new",
        params: { orderId: this.orderId },
        query: {
          units: this.currentOrder.quantity_units,
          deliveryLocation: this.currentOffer.delivery_location,
        },
      });
    },
  },
};
</script>
