<template>
  <div class="sm:col-span-6">
    <page-card
      v-for="(premium, premiumIndex) in validatedPremiums"
      :key="premiumIndex"
      :heading-title="
        premium.type === 'fixed'
          ? '$' + premium.value
          : premium.type + ' Premium'
      "
      :headingDescription="
        premium.allocations_met +
        ' of ' +
        premium.allocations.length +
        ' completed allocations'
      "
      :inserted="true"
    >
      <template #cardDetails>
        <div
          class="grid grid-cols-1 gap-x-4 gap-y-8 sm:col-span-6 sm:grid-cols-6"
        >
          <ul role="list" class="divide-y divide-gray-100 sm:col-span-6">
            <li
              v-for="allo in premium.allocations"
              :key="allo.id"
              class="relative flex justify-between py-5"
              @click="navigateToOrderPremiums(allo.id, premiumIndex)"
            >
              <div class="flex gap-x-4 pr-6 sm:w-1/2 sm:flex-none">
                <!-- <img class="h-12 w-12 flex-none rounded-full bg-gray-50" :src="person.imageUrl" alt="" /> -->
                <div class="min-w-0 flex-auto">
                  <p class="flex text-sm font-semibold leading-6 text-gray-900">
                    <span class="absolute inset-x-0 -top-px bottom-0" />

                    {{ allo.field_data.properties.name }}
                  </p>
                  <p class="mt-1 flex text-xs leading-5 text-gray-500">
                    <span class="mr-2">
                      <template v-if="allo.premium_requirements_met">
                        <CheckIcon class="h-5 w-5 text-green-500" />
                      </template>
                      <template v-else>
                        <XIcon class="h-5 w-5 text-red-500" />
                      </template>
                    </span>
                    {{
                      allo.premium_requirements_met
                        ? "Complete"
                        : "Requirements Not Met"
                    }}
                  </p>
                </div>
              </div>
              <div
                class="flex items-center justify-between gap-x-4 sm:w-1/2 sm:flex-none"
              >
                <div class="hidden sm:block">
                  <p class="text-sm leading-6 text-gray-900">
                    {{
                      allo.validation_records.length == 1
                        ? allo.validation_records.length + " Requirement"
                        : allo.validation_records.length + " Requirements"
                    }}
                  </p>
                  <p class="mt-1 text-xs leading-5 text-gray-500">
                    <!-- Last seen <time :datetime="person.lastSeenDateTime">{{ person.lastSeen }}</time> -->
                    {{ allo.number_of_requirements_met }} Complete
                  </p>
                </div>
                <p class="flex text-gray-400">
                  Edit
                  <OutlineHeroIcon
                    :name="'PencilIcon'"
                    classProp="h-5 w-5 ml-2 text-gray-400"
                    aria-hidden="true"
                  />
                </p>
              </div>
            </li>
          </ul>
        </div>
      </template>
    </page-card>
  </div>
</template>

<script>
import PageCard from "@/components/cards/PageCard.vue";
import OutlineHeroIcon from "@/components/icons/OutlineHeroIcon.vue";
import { harvestUnits } from "@/components/composables/convertUnits";
import { CheckIcon, XIcon } from "@heroicons/vue/solid"; // Import the necessary Heroicons

export default {
  props: {
    orderId: {
      type: Number,
      required: true,
    },
  },
  emits: ["updatePremiumData"],
  components: {
    PageCard,
    OutlineHeroIcon,
    CheckIcon,
    XIcon,
  },
  data() {
    return {
      // Statuses
      statuses: {
        Complete: "text-green-700 bg-green-50 ring-green-600/20",
        "In progress": "text-gray-600 bg-gray-50 ring-gray-500/10",
        Archived: "text-yellow-800 bg-yellow-50 ring-yellow-600/20",
      },
    };
  },
  computed: {
    dataIsLoaded() {
      if (
        this.orderId &&
        this.$store.getters.getGrowerCropOrderById(this.orderId) &&
        this.$store.getters.getGrowerAllocationsByOrderId(this.orderId) &&
        this.$store.getters.getGrowerDeliveriesByOrderId(this.orderId)
      ) {
        return true;
      } else {
        return false;
      }
    },
    currentOrder() {
      if (!this.dataIsLoaded) {
        return null;
      }
      // use this.orderId to fetch the order
      return this.$store.getters.getGrowerCropOrderById(this.orderId);
    },
    currentOffer() {
      if (!this.dataIsLoaded) {
        return null;
      }
      // use this.orderId to fetch the offer
      return this.$store.getters.getGrowerMarketOfferById(
        this.currentOrder.offer,
      );
    },
    allocations() {
      if (!this.dataIsLoaded) {
        return null;
      }
      let allos = this.$store.getters.getGrowerAllocationsByOrderId(
        this.orderId,
      );
      // attach the crop records to each allocation
      return allos.map((allo) => {
        return {
          ...allo,
          crop_records: this.$store.getters.getRecordsByCrop(allo.crop),
        };
      });
    },
    validatedPremiums() {
      if (!this.dataIsLoaded || !this.currentOffer?.premiums) {
        return [];
      }
      let validatedPremiums = this.currentOffer.premiums.map((premium) => {
        return {
          ...premium,
          value: this.calculateFixedPremium(premium),
          allocations: this.allocations.map((allocation) =>
            this.validateAllocationAgainstPremium(premium, allocation),
          ),
        };
      });
      // I want to iterate through the validated premiums. For each premium, I want to iterate through the allocations. For each allocation, I want to count the number of records that meet the requirements of the premium. I want to add a property to the premium called allocations_met that is the count of the number of allocations that meet the requirements of the premium.
      validatedPremiums = validatedPremiums.map((premium) => {
        return {
          ...premium,
          allocations_met: premium.allocations.filter(
            (allocation) => allocation.premium_requirements_met,
          ).length,
        };
      });
      return validatedPremiums;
    },
    premiumData() {
      if (!this.dataIsLoaded) {
        return {};
      }

      const validatedPremiums = this.validatedPremiums || []; // Make sure validatedPremiums is always an array
      return {
        // when calculating the total value, only include the value of premiums that are fixed
        totalValue: validatedPremiums
          .reduce(
            (acc, premium) =>
              acc + (premium.type === "fixed" ? premium.value : 0),
            0,
          )
          .toFixed(2),
        // sum of value where allocations_met === allocations.length
        completedValue: validatedPremiums
          .reduce(
            (acc, premium) =>
              acc +
              (premium.allocations_met === premium.allocations.length
                ? premium.value
                : 0),
            0,
          )
          .toFixed(2),
        // count of premiums in validatedPremiums
        totalPremiums: validatedPremiums.length,
        // count of premiums where allocations_met === allocations.length
        completedPremiums: validatedPremiums.filter(
          (premium) => premium.allocations_met === premium.allocations.length,
        ).length,
        // non-fixed premiums - the number of premiums without a fixed value
        nonFixedPremiums: validatedPremiums.filter(
          (premium) => premium.type !== "fixed",
        ).length,
      };
    },
    unitOptions() {
      let unitOptions = [{ label: "Acres", value: "acres" }];
      let cropUnit = harvestUnits(this.cropType);
      if (cropUnit) {
        unitOptions.push(cropUnit);
      }
      return unitOptions;
    },
  },
  methods: {
    saveRecord(premiumIndex, recordIndex) {
      const newRecord = { ...this.workingRecord };
      if (recordIndex === null) {
        this.currentOffer.premiums[premiumIndex].validation_records.push(
          newRecord,
        );
      } else {
        this.currentOffer.premiums[premiumIndex].validation_records.splice(
          recordIndex,
          1,
          newRecord,
        );
      }
      this.closePremiumModal();
    },
    navigateToOrderPremiums(allocationId, pIndex) {
      this.$router.push({
        name: "grower-portal-offer-order-premiums",
        params: { orderId: this.orderId },
        query: { allocation: allocationId, premiumIndex: pIndex },
      });
    },
    defaultHarvestUnits() {
      if (this.total_or_unit === "total") {
        this.harvest_units = "";
      }
      if (this.total_or_unit === "unit" && this.harvest_units === "") {
        this.harvest_units = this.contract?.quantity_units;
      }
    },
    calculateFixedPremium(premium) {
      if (premium.type != "fixed") {
        return "N/A";
      }
      if (premium.total_or_unit === "total") {
        return premium.base_premium;
      }
      if (premium.total_or_unit === "unit") {
        return premium.base_premium * this.currentOrder.quantity;
      }
      return premium.base_premium;
    },
    validateAllocationAgainstPremium(premium, allocation) {
      // clone the premium.validation_records into allocation.validation_records
      // pull the records for the allocation crop
      // for each validation record in allocation.validation_records
      // if validation_record.payload.numberRequired === "One" and the allocation crop records contain a record that matches the validation record type add requirements_met: true to the allocation.validation_records record
      // if validation_record.payload.numberRequired === "Multiple" and the allocation crop records contain greater than one record that matches the validation record type add requirements_met: true to the allocation.validation_records record
      // else add requirements_met: false to the allocation.validation_records record
      // return the allocation
      if (!this.dataIsLoaded) {
        return {};
      }
      let allo = { ...allocation };
      allo.validation_records = premium.validation_records.map((record) => {
        let requirements_met = false;
        let matchingRecords = allo.crop_records?.filter(
          (cropRecord) => cropRecord.category == record.category,
        );
        if (record.payload.numberRequired === "One") {
          if (matchingRecords && matchingRecords.length > 0) {
            requirements_met = true;
          }
        }
        if (record.payload.numberRequired === "Multiple") {
          if (matchingRecords && matchingRecords.length > 1) {
            requirements_met = true;
          }
        }
        return { ...record, requirements_met };
      });
      // if all premium_requirements_met are true, add a requirements_met: true to the allocation
      allo.premium_requirements_met = allo.validation_records.every(
        (record) => record.requirements_met,
      );
      allo.number_of_requirements_met = allo.validation_records.filter(
        (record) => record.requirements_met,
      ).length;
      allo.crop_data = this.$store.getters.getAnyCropById(allo.crop);
      allo.field_data = this.$store.getters.getFieldByCropId(allo.crop);
      return allo;
    },
  },
  watch: {
    premiumData: {
      handler: function (newVal) {
        this.$emit("updatePremiumData", newVal);
      },
      deep: true,
      immediate: true,
    },
  },
};
</script>

<style scoped>
/* Add any additional styles if needed */
</style>
