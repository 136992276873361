<template>
  <form
    @submit.prevent="submitProduceOrderForm"
    :id="formId"
    class="grid grid-cols-1 gap-x-4 gap-y-8 sm:col-span-6 sm:grid-cols-6"
  >
    <select-dropdown
      v-if="!orderId"
      :wideItem="true"
      inputTitle="Buyer"
      :required="true"
      :selectOptions="buyers"
      v-model="currentOrder.customer"
    />
    <text-input
      v-else
      :wideItem="true"
      inputTitle="Buyer"
      :required="true"
      :display-only="true"
      :display-only-value="selectedBuyer?.buyer_details.name"
    />
    <div class="flex flex-col justify-end sm:col-span-3">
      <p v-if="!selectedBuyer">No customer selected.</p>
      <!-- <div v-else-if="orderId">
        <p>
          Data Sharing:
          {{ selectedBuyer.seller_confirmed === true ? "Active" : "Inactive" }}
        </p>
        <router-link
          class="text-blue-600 underline visited:text-purple-600 hover:text-blue-800"
          :to="{
            name: 'edit-grower-portal-customer',
            params: { customerId: selectedBuyer.id },
          }"
          >Manage</router-link
        >
      </div> -->
    </div>
    <number-input
      :wideItem="true"
      inputTitle="Quantity"
      :required="true"
      v-model="currentOrder.quantity"
    />
    <select-dropdown
      :wideItem="true"
      inputTitle="Units"
      :required="true"
      :selectOptions="unitsOptions"
      v-model="currentOrder.units"
    />
    <div class="sm:col-span-6">
      <text-area inputTitle="Terms" v-model="currentOrder.terms" />
    </div>
  </form>
</template>

<script>
import TextInput from "@/components/form/TextInput.vue";
import NumberInput from "@/components/form/NumberInput.vue";
import TextArea from "@/components/form/TextArea.vue";
import SelectDropdown from "@/components/form/SelectDropdown.vue";

export default {
  props: {
    orderId: {
      type: Number,
      default: null,
    },
    // needed to allow a submit button outside the form tags to submit the form
    formId: {
      type: String,
      required: true,
    },
    allocations: {
      // array of crop IDs allocated to this order
      type: Array,
      default: new Array(),
    },
  },
  components: {
    NumberInput,
    SelectDropdown,
    TextArea,
    TextInput,
  },
  emits: ["formSaved"],
  data() {
    const unitsOptions = ["Tons", "Acres", "Bushels"];
    return {
      currentOrder: {},
      unitsOptions,
      formFixedFilters: { self: false },
      formFixedSorts: ["name"],
    };
  },
  computed: {
    buyers() {
      // crop customers
      let customers = this.$store.getters.getGrowerCropCustomers({});
      // iterate over the customers array and return an array of objects with label and value keys
      // { label: customer.buyer_details.name, value: customer.id }
      return customers.map((customer) => {
        return {
          label: customer.buyer_details.name,
          value: customer.id,
        };
      });
      // console.log("buyers", customers);
      // return customers;
    },
    selectedBuyer() {
      // console.log("selectedBuyer", this.currentOrder);
      return this.$store.getters.getGrowerCropCustomerById(
        this.currentOrder?.customer,
      );
    },
  },
  methods: {
    async submitProduceOrderForm() {
      if (this.orderId) {
        this.updateOrder();
        if (!this.$store.getters.getApiError) {
          this.$router.push({ name: "grower-portal-produce-orders" });
        }
      } else {
        await this.createOrder();
      }
    },
    // Form Methods
    readOrder() {
      this.currentOrder = this.$store.getters.getGrowerCropOrderById(
        this.orderId,
      );
    },
    async createOrder() {
      let order = await this.$store.dispatch(
        "createGrowerCropOrder",
        this.currentOrder,
      );
      this.$emit("formSaved", order.id);
      if (!this.$store.getters.getApiError) {
        this.$router.push({ name: "grower-portal-produce-orders" });
      }
    },
    updateOrder() {
      this.$store.dispatch("updateGrowerCropOrder", this.currentOrder);
      this.$emit("formSaved", this.currentOrder.id);
    },
    clearForm() {
      this.currentOrder = {
        listing: null, // int
        quantity: null, // int
        estimated_price: null, // int
        customer: null, // id
      };
    },
  },
  watch: {
    orderId: {
      // handler(value) or handler(newValue, oldValue)
      handler(value) {
        // console.log("the fieldId param changed to ", value, " from ", oldValue)
        if (value) {
          this.readOrder();
        } else {
          this.clearForm();
        }
      },
      // this will cause the handler to be run immediately on component creation
      immediate: true,
    },
  },
};
</script>
