<template>
  <div
    class="mt-2 text-left text-lg font-medium uppercase tracking-wider text-gray-500 sm:col-span-6"
  >
    Core
  </div>
  <number-input
    :required="true"
    :wideItem="true"
    inputTitle="Crop Yield (bushels/acre)"
    inputHtmlType="text"
    v-model="currentInputs.crop_yield"
    @blur="emitUpdateScenarioInputs"
    :display-only="readOnly"
    :display-only-value="currentInputs.crop_yield"
  />
  <number-input
    :required="true"
    :wideItem="true"
    inputTitle="Farm Size (acres)"
    inputHtmlType="text"
    v-model="currentInputs.farm_size"
    @blur="emitUpdateScenarioInputs"
    :display-only="readOnly"
    :display-only-value="currentInputs.farm_size"
  />
  <div
    class="mt-2 text-left text-lg font-medium uppercase tracking-wider text-gray-500 sm:col-span-6"
  >
    Energy
  </div>
  <number-input
    :wideItem="true"
    inputTitle="Diesel (gallons/acre)"
    inputHtmlType="text"
    v-model="currentInputs.diesel"
    :placeholder="scenario?.inputs?.diesel?.toFixed(2)"
    @blur="emitUpdateScenarioInputs"
    :display-only="readOnly"
    :display-only-value="currentInputs.diesel"
  />
  <number-input
    :wideItem="true"
    inputTitle="Electricity (kWh/acre)"
    inputHtmlType="text"
    v-model="currentInputs.electricity"
    :placeholder="scenario?.inputs?.electricity?.toFixed(2)"
    @blur="emitUpdateScenarioInputs"
    :display-only="readOnly"
    :display-only-value="currentInputs.electricity"
  />
  <number-input
    :wideItem="true"
    inputTitle="Gasoline (gallons/acre)"
    inputHtmlType="text"
    v-model="currentInputs.gasoline"
    :placeholder="scenario?.inputs?.gasoline?.toFixed(2)"
    @blur="emitUpdateScenarioInputs"
    :display-only="readOnly"
    :display-only-value="currentInputs.gasoline"
  />
  <number-input
    :wideItem="true"
    inputTitle="Liquefied Petroleum Gas (gallons/acre)"
    inputHtmlType="text"
    v-model="currentInputs.liquefied_petroleum_gas"
    :placeholder="scenario?.inputs?.liquefied_petroleum_gas?.toFixed(2)"
    @blur="emitUpdateScenarioInputs"
    :display-only="readOnly"
    :display-only-value="currentInputs.liquefied_petroleum_gas"
  />
  <number-input
    :wideItem="true"
    inputTitle="Natural Gas (ft3/acre)"
    inputHtmlType="text"
    v-model="currentInputs.natural_gas"
    :placeholder="scenario?.inputs?.natural_gas?.toFixed(2)"
    @blur="emitUpdateScenarioInputs"
    :display-only="readOnly"
    :display-only-value="currentInputs.natural_gas"
  />
  <div
    class="mt-2 text-left text-lg font-medium uppercase tracking-wider text-gray-500 sm:col-span-6"
  >
    Nitrogen Fertilizer
  </div>
  <number-input
    :wideItem="true"
    inputTitle="Ammonia (lbs N/acre)"
    inputHtmlType="text"
    v-model="currentInputs.ammonia"
    :placeholder="scenario?.inputs?.ammonia?.toFixed(2)"
    @blur="emitUpdateScenarioInputs"
    :display-only="readOnly"
    :display-only-value="currentInputs.ammonia"
  />
  <number-input
    :wideItem="true"
    inputTitle="Ammonium Nitrate (lbs N/acre)"
    inputHtmlType="text"
    v-model="currentInputs.ammonium_nitrate"
    :placeholder="scenario?.inputs?.ammonium_nitrate?.toFixed(2)"
    @blur="emitUpdateScenarioInputs"
    :display-only="readOnly"
    :display-only-value="currentInputs.ammonium_nitrate"
  />
  <number-input
    :wideItem="true"
    inputTitle="Urea (lbs N/acre)"
    inputHtmlType="text"
    v-model="currentInputs.urea"
    :placeholder="scenario?.inputs?.urea?.toFixed(2)"
    @blur="emitUpdateScenarioInputs"
    :display-only="readOnly"
    :display-only-value="currentInputs.urea"
  />
  <div
    class="mt-2 text-left text-lg font-medium uppercase tracking-wider text-gray-500 sm:col-span-6"
  >
    Phosphorus Fertilizer
  </div>
  <number-input
    :wideItem="true"
    inputTitle="Phosphate (lbs P2O5/acre)"
    inputHtmlType="text"
    v-model="currentInputs.phosphate"
    :placeholder="scenario?.inputs?.phosphate?.toFixed(2)"
    @blur="emitUpdateScenarioInputs"
    :display-only="readOnly"
    :display-only-value="currentInputs.phosphate"
  />
  <div
    class="mt-2 text-left text-lg font-medium uppercase tracking-wider text-gray-500 sm:col-span-6"
  >
    Potash Fertilizer
  </div>
  <!-- Content goes here -->
  <number-input
    :wideItem="true"
    inputTitle="Potash Fertilizer (lbs K2O/acre)"
    inputHtmlType="text"
    v-model="currentInputs.potash_fertilizer"
    :placeholder="scenario?.inputs?.potash_fertilizer?.toFixed(2)"
    @blur="emitUpdateScenarioInputs"
    :display-only="readOnly"
    :display-only-value="currentInputs.potash_fertilizer"
  />
  <div
    class="mt-2 text-left text-lg font-medium uppercase tracking-wider text-gray-500 sm:col-span-6"
  >
    Other
  </div>
  <number-input
    :wideItem="true"
    inputTitle="Herbicide (g/acre)"
    inputHtmlType="text"
    v-model="currentInputs.herbicide"
    :placeholder="scenario?.inputs?.herbicide?.toFixed(2)"
    @blur="emitUpdateScenarioInputs"
    :display-only="readOnly"
    :display-only-value="currentInputs.herbicide"
  />
  <number-input
    :wideItem="true"
    inputTitle="Insecticide (g/acre)"
    inputHtmlType="text"
    v-model="currentInputs.insecticide"
    :placeholder="scenario?.inputs?.insecticide?.toFixed(2)"
    @blur="emitUpdateScenarioInputs"
    :display-only="readOnly"
    :display-only-value="currentInputs.insecticide"
  />
  <number-input
    :wideItem="true"
    inputTitle="Lime (lbs/acre)"
    inputHtmlType="text"
    v-model="currentInputs.lime"
    :placeholder="scenario?.inputs?.lime?.toFixed(2)"
    @blur="emitUpdateScenarioInputs"
    :display-only="readOnly"
    :display-only-value="currentInputs.lime"
  />

  <select-search
    :wideItem="true"
    inputTitle="Apply Soil Amendment"
    :selectOptions="[
      {
        label: 'Yes',
        value: 'Yes',
      },
      { label: 'No', value: 'No' },
    ]"
    v-model="currentInputs.apply_soil_amendment"
    :placeholder="scenario?.inputs?.apply_soil_amendment"
    @on-blur="emitUpdateScenarioInputs"
    :display-only="readOnly"
    :display-only-value="currentInputs.apply_soil_amendment"
  />
</template>

<script>
import NumberInput from "@/components/form/NumberInput.vue";
// import SelectDropdown from "@/components/form/SelectDropdown.vue";
import SelectSearch from "@/components/form/SelectSearch.vue";

export default {
  emits: ["updateScenarioInputs"],
  props: {
    scenarioInitial: {
      type: Object,
      default: null,
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    NumberInput,
    // SelectDropdown,
    SelectSearch,
  },
  data() {
    return {
      currentInputs: this.scenarioInitial?.inputs
        ? this.scenarioInitial.inputs
        : {
            farm_size: null,
            crop_yield: null,
            diesel: null,
            gasoline: null,
            natural_gas: null,
            liquefied_petroleum_gas: null,
            electricity: null,
            ammonia: null,
            urea: null,
            ammonium_nitrate: null,
            phosphate: null,
            potash_fertilizer: null,
            lime: null,
            herbicide: null,
            insecticide: null,
            apply_soil_amendment: "Yes",
          },
    };
  },
  computed: {
    scenario() {
      return this.scenarioInitial;
    },
  },
  methods: {
    updateScenarioInputs(e) {
      this.currentInputs = e;
      this.$emit("updateScenarioInputs", this.currentInputs);
    },
    resetScenarioInputs() {
      this.currentInputs = {
        farm_size: null,
        crop_yield: null,
        diesel: null,
        gasoline: null,
        natural_gas: null,
        liquefied_petroleum_gas: null,
        electricity: null,
        ammonia: null,
        urea: null,
        ammonium_nitrate: null,
        phosphate: null,
        potash_fertilizer: null,
        lime: null,
        herbicide: null,
        insecticide: null,
        apply_soil_amendment: "Yes",
      };
    },
    emitUpdateScenarioInputs() {
      // console.log("emittting UpdatedScenarioInputs");
      this.$emit("updateScenarioInputs", this.currentInputs);
    },
  },
  watch: {
    // currentInputs: {
    //   // handler(value) or handler(newValue, oldValue)
    //   handler(value) {
    //     this.$emit("updateScenarioInputs", value);
    //   },
    //   // this will cause the handler to be run immediately on component creation
    //   immediate: true,
    //   deep: true,
    // },
    scenarioInitial: {
      // handler(value) or handler(newValue, oldValue)
      handler(value) {
        this.currentInputs = value.inputs;
      },
      // this will cause the handler to be run immediately on component creation
      immediate: true,
      deep: true,
    },
  },
};
</script>
