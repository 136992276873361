<template>
  <!-- <div
    class="mt-2 text-left text-lg font-medium uppercase tracking-wider text-gray-500 sm:col-span-6"
  >
    Core
  </div> -->
  <number-input
    :required="true"
    :wideItem="true"
    inputTitle="Crop Yield (bushels/acre)"
    inputHtmlType="text"
    v-model="currentInputs.crop_yield"
    @blur="emitUpdateScenarioInputs"
    :display-only="readOnly"
    :display-only-value="currentInputs.crop_yield"
  />
  <number-input
    :required="true"
    :wideItem="true"
    inputTitle="Farm Size (acres)"
    inputHtmlType="text"
    v-model="currentInputs.farm_size"
    @blur="emitUpdateScenarioInputs"
    :display-only="readOnly"
    :display-only-value="currentInputs.farm_size"
  />
</template>
<script>
import NumberInput from "@/components/form/NumberInput";
export default {
  components: { NumberInput },
  props: {
    scenarioInitial: {
      type: Object,
      required: true,
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["updateScenarioInputs"],
  data() {
    return {
      currentInputs: this.scenarioInitial?.inputs
        ? this.scenarioInitial.inputs
        : {
            farm_size: null,
            crop_yield: null,
          },
    };
  },
  computed: {
    scenario() {
      return this.scenarioInitial;
    },
  },
  methods: {
    emitUpdateScenarioInputs() {
      this.$emit("updateScenarioInputs", this.currentInputs);
    },
  },
  watch: {
    scenarioInitial: {
      // handler(value) or handler(newValue, oldValue)
      handler(value) {
        this.currentInputs = value.inputs;
      },
      // this will cause the handler to be run immediately on component creation
      immediate: true,
      deep: true,
    },
  },
};
</script>
