<template>
  <page-card
    headingTitle="External Org Access"
    headingDescription="These organizations have the ability to log into your account"
    v-if="userIsAdmin"
  >
    <template #cardDetails>
      <div class="sm:col-span-6">
        <table-card>
          <template #header>
            <table-head
              :columns="[
                { name: 'name', label: 'Org', filtered: false },
                {
                  name: 'full_access',
                  label: 'Authorized to Make Changes',
                  filtered: false,
                },
              ]"
              :collection="externalOrgs"
              @update:derived-collection="derivedExternalOrgs = $event"
            >
              <template #toolbarButtons>
                <div class="flex">
                  <table-toolbar-button
                    buttonText="Manage"
                    :usePlusIcon="true"
                    @buttonClick="goToNewExternalOrgPage"
                  />
                </div>
              </template>
            </table-head>
          </template>
          <tbody>
            <tr v-for="user in derivedExternalOrgs" :key="user.id">
              <table-data td-label="User">
                <div class="flex">
                  <Avatar :username="user.name" :src="user.image" />
                  {{ user.name }}
                </div>
              </table-data>
              <table-data td-label="Authorized to Make Changes">
                {{ user.full_access ? "Yes" : "No" }}
              </table-data>
            </tr>
          </tbody>
        </table-card>
      </div>
    </template>
  </page-card>
</template>

<script>
import PageCard from "@/components/cards/PageCard.vue";
// import BaseButton from "@/components/buttons/BaseButton.vue";
// import VariableStringTypeInput from "@/components/form/VariableStringTypeInput.vue";
// import EmailInput from "@/components/form/EmailInput.vue";
// import PhoneInput2 from "@/components/form/PhoneInput2.vue";
// import FileSelect from "@/components/form/FileSelect.vue";

import TableCard from "@/components/table/TableCard.vue";
import TableHead from "@/components/table/TableHead.vue";
import TableData from "@/components/table/TableData.vue";
import TableToolbarButton from "@/components/table/TableToolbarButton.vue";

import Avatar from "@/components/identity/UserAvatar.vue";
import { useToast } from "vue-toastification";

// import SelectSearch from "@/components/form/SelectSearch.vue";

export default {
  components: {
    PageCard,
    // BaseButton,
    // VariableStringTypeInput,
    // EmailInput,
    // PhoneInput2,
    TableCard,
    TableHead,
    TableData,
    TableToolbarButton,
    Avatar,
    // FileSelect,
    // SelectSearch,
  },
  props: {
    routeUserNew: {
      type: String,
      required: true,
    },
    routeExternalOrgNew: {
      type: String,
      required: true,
    },
    routeUserEdit: {
      type: String,
      required: true,
    },
  },
  data() {
    // console.log("stateList", stateList);
    return {
      derivedUsers: [],
      derivedExternalOrgs: [],
      derivedTokens: [],
      toast: useToast(),
      currentOrg: null,
    };
  },
  computed: {
    externalOrgs() {
      return this.$store.state.externalOrgs.externalOrgs;
    },
    userIsAdmin() {
      return this.$store.getters.getUserAdminStatus;
    },
  },
  methods: {
    goToNewExternalOrgPage() {
      this.$router.push({ name: this.routeExternalOrgNew });
      // Form Methods
    },

    readOrgById(orgId) {
      this.currentOrg = this.$store.getters.getOrgById(orgId);
    },
    readCurrentOrg() {
      this.currentOrg = this.$store.getters.getCurrentOrg;
      if (this.currentOrg?.address) {
        this.currentOrg.addressObj = JSON.parse(this.currentOrg.address);
      } else {
        this.currentOrg.addressObj = {
          house_number: "",
          road: "",
          city: "",
          county: "",
          state: "",
          zip: "",
        };
      }
    },
  },

  watch: {
    orgId: {
      // handler(value) or handler(newValue, oldValue)
      handler(value) {
        // console.log("the fieldId param changed to ", value, " from ", oldValue)
        if (value) {
          this.readOrgById(value);
        } else {
          this.readCurrentOrg();
        }
      },
      // this will cause the handler to be run immediately on component creation
      immediate: true,
    },
  },
};
</script>

<style></style>
