import client from "@/api/parcel/api.js";
import _ from "lodash";

export default {
  state() {
    return {
      messages: [], //collection of messages, threadId inserted: [thread:1234, content:"Test",...]
      users: [],
    };
  },
  mutations: {
    SET_MESSAGES(state, { messages, users }) {
      state.messages = messages;
      state.users = users;
    },
    ADD_MESSAGE(state, messageObject) {
      state.messages.push(messageObject);
    },
    RESET_MESSAGES(state) {
      state.messages = [];
      state.users = [];
    },
  },
  actions: {
    async getMessages({ commit }) {
      const { data, errors } = await client.threads.messages.read();
      if (errors) {
        console.log(errors);
      } else {
        commit("SET_MESSAGES", data);
      }
    },
    // payload needs to be object with {threadId: threadIdValue, message: {content: "string"}}
    async createMessage({ commit, dispatch }, { threadId, message }) {
      //    console.log(threadId, "message: ", message);
      message = { ...message, thread: parseInt(threadId) };

      const { data, errors } = await client.threads.messages.create(
        threadId,
        message,
      );
      if (errors) {
        console.log(errors);
        dispatch("catchParcelApiError", errors);
      } else {
        message = { ...data, thread: parseInt(threadId) };
        commit("ADD_MESSAGE", message);
        //this.dispatch("getMessages", threadId);  //Don't hit API unnecessarily
      }
    },
  },
  getters: {
    getDerivedMessages:
      (state) =>
      ({
        filter = {},
        match = "",
        sort = [],
        pageSize = null,
        pageNum = 0,
      }) => {
        let expandedMessages = state.messages;
        let filteredMessages = _.isEmpty(filter)
          ? expandedMessages
          : _.filter(expandedMessages, filter);
        let matchedMessages = _.isEmpty(match)
          ? filteredMessages
          : _.filter(filteredMessages, (item) =>
              _.some(item, (val) =>
                _.includes(_.lowerCase(val), _.lowerCase(match)),
              ),
            );
        let sortColumns = Object.keys(sort);
        let sortOrders = Object.values(sort);
        //console.log("sort in index",sort,sortColumns, sortOrders);
        let sortedMessages = _.isEmpty(sort)
          ? matchedMessages
          : _.orderBy(matchedMessages, sortColumns, sortOrders);
        let paginatedMessages =
          _.isNumber(pageSize) && _.isNumber(pageNum)
            ? _.slice(
                sortedMessages,
                pageSize * pageNum, // skip
                pageSize * pageNum + pageSize, // limit
              )
            : sortedMessages;
        return paginatedMessages;
      },
  },
};
