<template>
  <table-card>
    <template #header>
      <table-head
        :columns="[
          { name: 'contract_name', label: 'Contract' },
          { name: 'org_name', label: 'Assignee' },
          { name: 'crop', label: 'Crop' },
          { name: 'quantity', label: 'Quantity Assigned', filtered: false },
          { name: 'actions', label: '', filtered: false },
        ]"
        :collection="offers"
        @update:derived-collection="derivedOffers = $event"
      >
        <template #toolbarButtons>
          <div class="flex">
            <table-toolbar-button
              buttonText="New"
              :usePlusIcon="true"
              @buttonClick="navigateNewOffer"
            />
          </div>
        </template>
      </table-head>
    </template>
    <tbody>
      <tr v-for="offer in derivedOffers" :key="offer.id">
        <table-data td-label="Contract"> {{ offer.contract_name }} </table-data>
        <table-data td-label="Assignee">
          {{ offer.org_name }}
        </table-data>
        <table-data td-label="Crop">
          {{ offer.crop }}
        </table-data>
        <table-data td-label="Quantity Assigned">
          {{ offer.quantity }} {{ offer.quantity_units }}
        </table-data>
        <table-data class="text-center">
          <router-link
            :to="{
              name: 'buyer-assignment-single',
              params: { assignmentId: offer.id },
            }"
            class="pl-3 font-medium text-violet-500 hover:text-twilight-900 sm:pr-6"
          >
            View
          </router-link>
        </table-data>
      </tr>
    </tbody>
  </table-card>
</template>

<script>
import TableToolbarButton from "@/components/table/TableToolbarButton.vue";
import TableHead from "@/components/table/TableHead.vue";
import TableCard from "@/components/table/TableCard.vue";
import TableData from "@/components/table/TableData.vue";

export default {
  components: {
    TableToolbarButton,
    TableHead,
    TableCard,
    TableData,
  },
  props: {
    contractId: {
      type: [Number, String],
      default: null,
    },
  },
  data() {
    return {
      derivedOffers: [],
    };
  },
  computed: {
    offers() {
      let offers = this.$store.getters.getControllerMarketOffers({});
      if (this.contractId) {
        offers = offers.filter(
          (offer) => offer.buyer_contract == parseInt(this.contractId),
        );
      }
      for (let i = 0; i < offers.length; i++) {
        offers[i].org_name = this.getAffiliateOrgNameByAffiliateOrgId(
          offers[i].org,
        );
      }
      return offers;
    },
  },
  methods: {
    navigateNewOffer() {
      this.$router.push({
        name: "buyer-assignment-new",
        query: { contract: this.contractId },
      });
    },
    getAffiliateOrgNameByAffiliateOrgId(affiliateOrgId) {
      return this.$store.getters.getControllerMarketAffiliateNameById(
        affiliateOrgId,
      );
    },
  },
};
</script>

<style></style>
