import client from "@/api/parcel/api.js";

export default {
  state() {
    return { affiliateOrgs: [] };
  },
  mutations: {
    SET_AFFILIATE_ORGS(state, data) {
      state.affiliateOrgs = data;
    },
    RESET_AFFILIATE_ORGS(state) {
      state.affiliateOrgs = [];
    },
    ADD_AFFILIATE_ORG(state, affiliateOrg) {
      state.affiliateOrgs.push(affiliateOrg);
    },
    REMOVE_AFFILIATE_ORG(state, affiliateOrg) {
      state.affiliateOrgs = state.affiliateOrgs.filter(
        (a) => a.id !== affiliateOrg,
      );
    },
  },
  actions: {
    async getAffiliateOrgs({ commit, dispatch }) {
      const { data, errors } = await client.orgs.readAffiliateOrgs();
      if (errors) {
        dispatch("catchParcelApiError", errors);
      } else {
        commit("SET_AFFILIATE_ORGS", data.affiliate_orgs);
      }
    },
    async createAffiliateOrg({ commit, dispatch }, affiliateOrg) {
      const { data, errors } = await client.orgs.createAffiliateOrg(
        null,
        affiliateOrg,
      );
      if (errors) {
        dispatch("catchParcelApiError", errors);
      } else {
        commit("ADD_AFFILIATE_ORG", data);
        return data;
      }
    },
    async deleteAffiliateOrg({ commit, dispatch }, affiliateOrg) {
      const { data, errors } = await client.orgs.deleteAffiliateOrg(
        affiliateOrg.id,
      );
      if (errors) {
        dispatch("catchParcelApiError", errors);
      } else {
        commit("REMOVE_AFFILIATE_ORG", data);
        return data;
      }
    },
  },
  getters: {
    getAffiliateOrgById: (state) => (orgId) => {
      return state.affiliateOrgs.find((org) => org.id === orgId);
    },
  },
};
