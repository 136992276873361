<template>
  <delete-modal
    :open="modalIsActive"
    modalTitle="Delete Inquiry"
    modalDescription="Are you sure you want to permanently delete this inquiry?"
    @clickCancel="cancelDelete"
    @clickConfirmDelete="deleteCropInquiry"
  />
  <page-card :headingTitle="'Inquiry: ' + $route.params.inquiryId">
    <template #buttonArea>
      <submit-button
        buttonText="Save"
        :marginLeft="false"
        :formId="editCropInquiryFormId"
      />
      <base-button
        buttonText="Delete"
        :redButton="true"
        @buttonClick="intializeDeleteModal"
      />
    </template>
    <template #cardDetails>
      <crop-inquiry-form
        :orderId="parseInt($route.params.inquiryId)"
        :formId="editCropInquiryFormId"
        @formSaved="saveAttachments"
      />
    </template>
    <template #componentSection>
      <!-- Tabs Below Grid -->
      <TabGroup as="div">
        <div class="border-b border-gray-200">
          <TabList class="-mb-px flex space-x-8">
            <Tab as="template" v-slot="{ selected }">
              <button
                :class="[
                  selected
                    ? 'border-indigo-600 text-indigo-600'
                    : 'border-transparent text-gray-700 hover:border-gray-300 hover:text-gray-800',
                  'whitespace-nowrap border-b-2 py-6 text-sm font-medium',
                ]"
              >
                Premiums
              </button>
            </Tab>
            <Tab as="template" v-slot="{ selected }" v-if="currentOffer">
              <button
                :class="[
                  selected
                    ? 'border-indigo-600 text-indigo-600'
                    : 'border-transparent text-gray-700 hover:border-gray-300 hover:text-gray-800',
                  'whitespace-nowrap border-b-2 py-6 text-sm font-medium',
                ]"
              >
                Offer Details
              </button>
            </Tab>
            <Tab as="template" v-slot="{ selected }">
              <button
                :class="[
                  selected
                    ? 'border-indigo-600 text-indigo-600'
                    : 'border-transparent text-gray-700 hover:border-gray-300 hover:text-gray-800',
                  'whitespace-nowrap border-b-2 py-6 text-sm font-medium',
                ]"
              >
                Attachments
              </button>
            </Tab>
            <Tab as="template" v-slot="{ selected }">
              <button
                :class="[
                  selected
                    ? 'border-indigo-600 text-indigo-600'
                    : 'border-transparent text-gray-700 hover:border-gray-300 hover:text-gray-800',
                  'whitespace-nowrap border-b-2 py-6 text-sm font-medium',
                ]"
              >
                Chat
              </button>
            </Tab>
          </TabList>
        </div>
        <TabPanels as="template">
          <TabPanel
            as="dl"
            class="pt-10"
            v-if="currentOrder && currentOrder.offer"
          >
            <crop-premium-display
              v-if="currentOrder"
              :premiums="currentOrder?.premiums"
            />
          </TabPanel>
          <TabPanel
            as="dl"
            class="pt-10"
            v-else-if="currentOrder && currentOrder.listing"
          >
            <div
              class="mt-2 text-left text-sm font-medium tracking-wider text-gray-500 sm:col-span-6"
            >
              <checkbox-input
                inputTitle="Add New Premium"
                v-model="showPremiumForm"
              />
              <div v-if="showPremiumForm" class="mt-2">
                <page-card :heading-title="'New Premium'" :inserted="true">
                  <template #buttonArea>
                    <base-button
                      :buttonText="'Submit'"
                      :marginLeft="false"
                      @buttonClick="createPremium()"
                    />
                  </template>
                  <template #cardDetails>
                    <order-premium-form
                      :orderId="inquiryId"
                      ref="newOrderPremiumForm"
                    />
                  </template>
                </page-card>
              </div>
            </div>
            <page-card
              v-for="(premium, index) in currentOrder.premiums"
              :key="premium.id"
              :heading-title="'Premium ' + (index + 1)"
              :inserted="true"
            >
              <template #buttonArea>
                <base-button
                  buttonText="Submit"
                  :marginLeft="false"
                  @buttonClick="updatePremium(index)"
                />
                <base-button
                  buttonText="Delete"
                  :redButton="true"
                  :marginLeft="true"
                  @buttonClick="deletePremium(index)"
                />
              </template>
              <template #cardDetails>
                <div
                  class="grid grid-cols-1 gap-x-4 gap-y-8 sm:col-span-6 sm:grid-cols-6"
                >
                  <order-premium-form
                    :initial-premium="premium"
                    :orderId="inquiryId"
                    :ref="'orderPremiumForm' + index.toString()"
                  />
                </div>
              </template>
            </page-card>
          </TabPanel>
          <TabPanel v-else>
            <div
              class="mt-2 text-left text-lg font-medium uppercase tracking-wider text-gray-500 sm:col-span-6"
            >
              No Premiums Available
            </div>
          </TabPanel>
          <TabPanel as="dl" class="pt-10" v-if="currentOffer">
            <offer-details :offerId="currentOffer.id" />
          </TabPanel>
          <TabPanel as="dl" class="pt-10" :unmount="false">
            <add-attachments-table
              :parentId="currentOrder?.id"
              :attachments="currentOrder?.attachments"
              addStoreAction="uploadBuyerOrderAttachment"
              removeStoreAction="removeBuyerOrderAttachment"
              ref="attachmentsTable"
            />
          </TabPanel>
          <TabPanel as="dl" class="pt-10">
            <chat-single-view
              v-if="currentOrder"
              :thread-id="currentOrder?.details?.threadId"
            />
          </TabPanel>
        </TabPanels>
      </TabGroup>
    </template>
  </page-card>
</template>

<script>
import { Tab, TabGroup, TabList, TabPanel, TabPanels } from "@headlessui/vue";
import CropInquiryForm from "@/layouts/buyer-grower/crop-inquiries/CropInquiryForm.vue";
import CropPremiumDisplay from "@/layouts/buyer-grower/crop-orders/CropPremiumDisplay.vue";
import OrderPremiumForm from "@/layouts/buyer-portal/bp-affiliate/orders/OrderPremiumForm.vue";
import PageCard from "@/components/cards/PageCard.vue";
import BaseButton from "@/components/buttons/BaseButton.vue";
import SubmitButton from "@/components/buttons/SubmitButton.vue";
import DeleteModal from "@/components/modals/DeleteModal.vue";
import AddAttachmentsTable from "@/layouts/common/attachments/AddAttachmentsTable.vue";
import ChatSingleView from "@/layouts/common/ChatSingleView.vue";
import CheckboxInput from "@/components/form/CheckboxInput.vue";
import OfferDetails from "@/layouts/buyer-portal/bp-affiliate/offers/OfferDetails.vue";

export default {
  components: {
    Tab,
    TabGroup,
    TabList,
    TabPanel,
    TabPanels,
    PageCard,
    BaseButton,
    SubmitButton,
    CropInquiryForm,
    CropPremiumDisplay,
    OrderPremiumForm,
    DeleteModal,
    AddAttachmentsTable,
    ChatSingleView,
    CheckboxInput,
    OfferDetails,
  },
  data() {
    return {
      showPremiumForm: false,
      modalIsActive: false,
      editCropInquiryFormId: "editCropInquiry",
    };
  },
  computed: {
    inquiryId() {
      return parseInt(this.$route.params.inquiryId);
    },
    currentOrder() {
      if (this.$store.getters.getBuyerOrderById(this.inquiryId)) {
        return this.$store.getters.getBuyerOrderById(this.inquiryId);
      } else {
        return null;
      }
    },
    currentOffer() {
      if (this.currentOrder && this.currentOrder.offer) {
        return this.$store.getters.getBuyerMarketOfferById(
          this.currentOrder.offer,
        );
      } else {
        return null;
      }
    },
  },
  methods: {
    // Modal Methods
    createPremium() {
      this.$refs.newOrderPremiumForm.createPremium();
    },
    updatePremium(index) {
      // console.log("Refs: ", this.$refs);
      let refString = "orderPremiumForm" + index.toString();
      // console.log("Ref String: ", refString);
      this.$refs[refString][0].updatePremium();
    },
    deletePremium(index) {
      let refString = "orderPremiumForm" + index.toString();
      this.$refs[refString][0].deletePremium();
    },
    saveAttachments() {
      // console.log("saveAttachments", this.$refs, this.$refs.attachmentsTable);
      this.$refs.attachmentsTable.saveAttachments();
    },
    intializeDeleteModal() {
      this.modalIsActive = true;
    },
    cancelDelete() {
      this.modalIsActive = false;
    },
    async deleteCropInquiry() {
      this.modalIsActive = false;
      // console.log("DELETE");
      await this.$store.dispatch("deleteBuyerCropOrder", {
        id: this.$route.params.inquiryId,
      });
      if (!this.$store.getters.getApiError) {
        this.$router.push({ name: "buyer-inquiries" });
      }
    },
  },
};
</script>
