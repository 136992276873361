<template>
  <div>
    <label class="block text-sm font-medium text-gray-700">
      {{ inputTitle }}
      <span v-if="required" class="text-red-600">*</span>
      <span v-if="helpText">
        <ToolTip :helpText="helpText" :helpTitle="helpTitle" />
      </span>
    </label>
    <fieldset aria-label="Pricing selectOptions" class="mt-4">
      <RadioGroup
        v-model="inputValue"
        class="relative -space-y-px rounded-md bg-white"
      >
        <RadioGroupOption
          as="template"
          v-for="(option, optionIdx) in selectOptions"
          :key="option.value"
          :value="option.value"
          v-slot="{ active, checked }"
        >
          <div
            :class="[
              optionIdx === 0 ? 'rounded-tl-md rounded-tr-md' : '',
              optionIdx === selectOptions.length - 1
                ? 'rounded-bl-md rounded-br-md'
                : '',
              checked
                ? 'z-10 border-indigo-200 bg-indigo-50'
                : 'border-gray-200',
              'relative flex cursor-pointer flex-col border p-4 focus:outline-none md:grid md:grid-cols-3 md:pl-4 md:pr-6',
            ]"
          >
            <span class="flex items-center text-sm">
              <span
                :class="[
                  checked
                    ? 'border-transparent bg-indigo-600'
                    : 'border-gray-300 bg-white',
                  active ? 'ring-2 ring-indigo-600 ring-offset-2' : '',
                  'flex h-4 w-4 items-center justify-center rounded-full border',
                ]"
                aria-hidden="true"
              >
                <span class="h-1.5 w-1.5 rounded-full bg-white" />
              </span>
              <span
                :class="[
                  checked ? 'text-indigo-900' : 'text-gray-900',
                  'ml-3 flex items-center font-medium',
                ]"
              >
                <span class="mr-2" v-if="showIcons">
                  <template v-if="option.completed">
                    <CheckIcon class="h-5 w-5 text-green-500" />
                  </template>
                  <template v-else>
                    <XIcon class="h-5 w-5 text-red-500" />
                  </template>
                </span>
                {{ option.primary }}
              </span>
            </span>
            <span class="ml-6 pl-1 text-sm md:ml-0 md:pl-0 md:text-center">
              <span
                :class="[
                  checked ? 'text-indigo-900' : 'text-gray-900',
                  'font-medium',
                ]"
              >
                {{ option.secondary }}
              </span>
            </span>
            <span
              :class="[
                checked ? 'text-indigo-700' : 'text-gray-500',
                'ml-6 pl-1 text-sm md:ml-0 md:pl-0 md:text-right',
              ]"
            >
              {{ option.description }}
            </span>
          </div>
        </RadioGroupOption>
      </RadioGroup>
    </fieldset>
  </div>
</template>

<script>
import { RadioGroup, RadioGroupOption } from "@headlessui/vue";
import ToolTip from "@/components/modals/ToolTip.vue";
import { CheckIcon, XIcon } from "@heroicons/vue/solid"; // Import the necessary Heroicons

export default {
  components: {
    RadioGroup,
    RadioGroupOption,
    ToolTip,
    CheckIcon,
    XIcon,
  },
  // selectOptions: [
  //   { value: '1', primary: 'Basic', secondary: '$9', description: 'per month', completed: true },
  //   { value: '2', primary: 'Pro', secondary: '$19', description: 'per month', completed: false },
  // ],
  props: {
    inputTitle: { type: String, default: "" },
    selectOptions: { type: Array, required: true }, // Ensure selectOptions includes completed field
    showIcons: { type: Boolean, default: false },
    modelValue: { type: [String, Number, Boolean], default: null },
    helpText: { type: String, default: null },
    helpTitle: { type: String, default: null },
    required: { type: Boolean, default: false },
  },
  emits: ["update:modelValue"],
  data() {
    return {
      inputValue: this.modelValue,
    };
  },
  watch: {
    inputValue(newValue) {
      this.$emit("update:modelValue", newValue);
    },
    modelValue(newValue) {
      this.inputValue = newValue;
    },
  },
};
</script>

<style scoped>
/* You can add scoped styles here if needed */
</style>
